import { createNamespacedHelpers } from 'vuex';
import locationService from '@/services/locationService';
import radiusService from '@/services/radiusService';
import firewallService from '@/services/firewallService';
import operationsService from '@/services/operationsService';
import redirectsService from '@/services/redirectsService';
import helpers from '@/helpers';

const { mapGetters: themeMapGetters } = createNamespacedHelpers('theme');

// todo: отрефакторить и избавиться от него после того, как стилизация избавится от бутстрапа

// этот миксин содержит в себе легаси из старого компонента src/components/Header.vue
// здесь содержится то, что не удалось распилить при рефакторинге и создании нового Header.vue
// оставлен для совместимости и примешивается только 1 раз на уровне App.vue
// (примешивать его к Header.vue смысла нет, здесь нет специфической логики для хедера, но специфическая логика для всего приложения)

// здесь содержится:
// 1. логика каких - то запросов
// 2. логика кастомных цветов для локаций

// В дальшейшем - отрефактроить и примешивать его только там, где он нужен / удалить его совсем

const oldHeaderLegacyCodeMixin = {
  computed: {
    ...themeMapGetters(['isDarkTheme']),

    // во время разработки тут в реальные данные подмешивались нужные для разрабокти,
    // тепреь просто прокидываются реальные данные о локациях
    locationsWithCustomCss() {
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsItems));
      // return locations;
      return this.$store.state.locationsItems;
    },
    customCSSVarsForUserLocation() {
      if (localStorage.getItem('userData')) {
        const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
        // console.log(userBaseLocation)
        if (this.locationsWithCustomCss[userBaseLocation]) {
          if (this.locationsWithCustomCss[userBaseLocation].custom_styles_enable) {
            if (this.locationsWithCustomCss[userBaseLocation].custom_styles) {
              return this.createCustomStyleAsText(this.locationsWithCustomCss[userBaseLocation].custom_styles);
            }
            return '';
          }
          return '';
        }
        return '';
      }
      return '';
    }
  },
  methods: {
    createCustomStyleAsText(stylesObject) {
      let css = ':root {';
      for (const style in stylesObject) {
        if (stylesObject[style] !== '') {
          css += ` --${style}: ${stylesObject[style]};`;
        }
      }
      css = css.substring(0, css.length - 1); // вырезаем последний символ ';'
      css += '}';
      return css;
    }
  },
  created() {
    // если список локаций почему-то пуст, то подгружаем, чтобы показать картинку в шапке
    if (Object.keys(this.$store.state.locationsItems).length === 0) {
      // console.log(this.$store.state.locationsItems);
      locationService.getLocations(this);
    }
    if (localStorage.getItem('bossUserData')) {
      this.$store.commit('subordinateLoginSetTrue');
    }

    if (!this.$store.state.radiusList) {
      radiusService.getRadius(this);
    }
    if (!this.$store.state.firewallsList) {
      firewallService.getFirewalls(this);
    }
    if (!this.$store.state.operationsList || !this.$store.state.operationsList.length) {
      operationsService.getPedingOperations(this);
    }
    if (!this.$store.state.redirectsList || !this.$store.state.redirectsList.length) {
      redirectsService.getRedirectsRule(this);
    }
  },
  mounted() {
    // Как это все работает (кастомные цвета для локации):
    // 1.
    // При компиляции scss файлов туда засовываются css переменные по умолчанию, заполненные переменными scss
    // из файла /static/scss/defaultsCustomCSSVarsForLocation.scss
    // На этом этапе они ни на что не влияют, поскоольку на этом этапе в css файлах стилей
    // эти css переменные не используются
    //
    // 2. В компоненте Header есть computed customCSSVarsForUserLocation. Оно смотрит на базовую локацию юзера и на список локаций.
    // Если у локации, с id как у базовой локации юзера включен флаг custom_styles_enable то она возвращает элементы из объекта
    // custom_styles приведенные к виду CSS переменных (вначале добавляется --имясвойства, все обрачивается в :root {})
    //
    // 3. На хуке mounted компонента Header происходит проверка есть ли что в customCSSVarsForUserLocation. Если что-то есть
    // то происходит следующее. В конец элемента header добавляется элемент style, содержащий в себе данные из пункта 2
    // (computed customCSSVarsForUserLocation). Эnо частично или полностью перезаписывает css  перменные из
    // /static/scss/defaultsCustomCSSVarsForLocation.scss. (пункт 1)
    // 4. А далее, в конец все того-же header, происходит вставка линки на css файл "/static/css/customStylesForLocationRewriteCSS.css"
    // Вставка этого самого файла и меняет цвета (в нем выборочно переопределяются некоторые цвета css,
    // поскольку он - последний css вставляемый в готовый dom он может все перекрасить)
    // Внутри него как раз и используются значения css переменных из пункта 1 или 3
    // 5. Также есть функция helpers.clearCustomLocationCss, которая очищает весь вставленный
    // ранее кастомныый css, отрабатывает на хуке mounted компонента Header если в
    // this.customCSSVarsForUserLocation ничего нет (например вышли из логина как подчиненный,
    // в локации у которого есть кастомные цвета, а в локации текущего пользователя нет)
    // (Если для локации нет кастомного CSS, а в DOM он есть, то удаляем его)
    // ------
    // Если для локации нет кастомного CSS, а в DOM он есть, то удаляем его
    // (например заходили под подчиненным из другой локации и вышли)
    if (!this.customCSSVarsForUserLocation) {
      helpers.clearCustomLocationCss();
    }
    // если для локации есть кастомный css  то добавляем его в DOM
    if (this.customCSSVarsForUserLocation && this.customCSSVarsForUserLocation !== '') {
      // если уже есть какой-то css то удаляем перед тем как вставить
      helpers.clearCustomLocationCss();
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'customCssVarsForUserLocation';
      style.appendChild(document.createTextNode(this.customCSSVarsForUserLocation));
      head.appendChild(style);
      // а потом добавляем сами стили для перезаписи основных блоков (таким образом не аффектим стили для локаций нет кастомных стилей)
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '/static/css/customStylesForLocationRewriteCSS.css';
      link.media = 'all';
      link.id = 'customStylesForLocationRewriteCSS';
      head.appendChild(link);
    }
    // если тема темная, удаляем все кастомные сss
    if (this.isDarkTheme) {
      helpers.clearCustomLocationCss();
    }
  },
  destroyed() {
    helpers.clearCustomLocationCss();
  }
};

export default oldHeaderLegacyCodeMixin;
