<template>
  <div class="row wlans-page">
  <!-- <div class="col-lg-12 d-flex justify-content-end mb-h">
    <div class="pl-1">
      <WButton info
        v-if="!isDisable"
        @click="openWlanWizard"
      >
        {{ $t('wlans.addWLAN') }}
      </WButton>
    </div>
  </div> -->
    <div class="col-lg-12">
      <div class="filter-block  ">
        <div class="w-100">
          <div class="filters  ">
            <div class="row ">
            <div class="col-lg-3 pl-0 pr-1 pb-h">
              <div class="input-group input-group-sm filters__input">
                <span class="input-group-addon">
                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                  <i class="fa fa-search" v-else></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('general.search')"
                  @keyup.13="searchInputHandler(searchValue)"
                  class="form-control"
                  v-model="searchValue"
                  @input="searchInputHandler(searchValue)"
                />
                <span class="clear-input text-muted">
                  <i class="fa fa-remove" v-if="searchValue && !searchSpinner"
                     @click="searchInputHandler('')"></i>
                </span>
              </div>
            </div>

            <div class="col-lg-3 pl-0 pr-1 pb-h" v-if="areLocationsVisible">
              <div
                class="input-group-for-location-select-in-templates--filters ">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                <SelectComponentV3ForUseInLocationSelectors

                  v-if="showLocationFilter"
                  no-wrap-selected-option
                  :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  class="select-location-in-templates--filters"
                  enable-max-option-width
                  small-size
                  :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                  v-model="locationInFiltersData"
                  track-by="name"
                  option-id-name="id"
                  :no-options-found="$t('general.noResultForSearch')"
                  :async-function="requestLocations.bind(this)"
                  :placeholder="$t('portalStats.selectLocation')"
                  @select="selectLocationInFilters"
                >
                  <template #dropdown-start>
                    <div class="ml-h mt-1">
                      <Switch-component
                        v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                        @input="refreshWlansListQuery"
                        :label="$t('general.withChild')"
                        v-model="filters.with_childs"
                        class=""
                      />
                    </div>
                  </template>
                </SelectComponentV3ForUseInLocationSelectors>
              </div>
            </div>

            <div class="col-lg-3 pl-0  pr-1 pb-h">
              <div class="input-group input-group-sm filters__location ">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-tag" :class="{ 'text-primary': filters.tag !== 'All tags' }"></i>
                </span>
                <select
                  class="form-control form-control-sm filters__field"
                  v-model="filters.tag"
                  @change="refreshWlansListQuery"
                >
                  <option :selected="true" :value="'All tags'">
                    {{ $t('general.allTags') }}
                  </option>
                  <option :selected="true" v-if="wlansTagsCurrentList.length" :value="''">
                    {{ $t('general.noTag') }}
                  </option>
                  <option v-for="tag in wlansTagsCurrentList" :value="tag" :key="tag">
                    {{ tag }}
                  </option>
                </select>
              </div>
            </div>


            <div class="filters__multiple mr-2">
              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-info"
                       :class="{ active: filters.securityFilter == 'open' }">
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'open')"/>
                  {{ $t('wlans.securityOpen') }}
                </label>
                <label class="btn btn btn-outline-info"
                       :class="{ active: filters.securityFilter == 'wpapersonal' }">
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpapersonal')"/>
                  WPA Personal
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpaenterprise' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpaenterprise')"/>
                  WPA Enterprise
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpa2personal' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpa2personal')"/>
                  WPA2 Personal
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpa2enterprise' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpa2enterprise')"/>
                  WPA2 Enterprise
                </label>

                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpa3personal' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpa3personal')"/>
                  WPA3 Personal
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpa3enterprise' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpa3enterprise')"/>
                  WPA3 Enterprise
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpa23personal' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpa23personal')"/>
                  WPA2/3 Personal
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.securityFilter == 'wpa23enterprise' }"
                >
                  <input type="radio" name="securityFilter"
                         @click="setFilter('securityFilter', 'wpa23enterprise')"/>
                  WPA2/3 Enterprise
                </label>

              </div>
            </div>

              <div class="filters__multiple">
              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-info"
                       :class="{ active: filters.dataTrafficManagementType == 'localSwitching' }">
                  <input type="radio" name="dataTrafficManagementType"
                         @click="setFilter('dataTrafficManagementType', 'localSwitching')"/>
                  {{ $t('wlans.localSwitching') }}
                </label>
                <label class="btn btn btn-outline-info"
                       :class="{ active: filters.dataTrafficManagementType == 'centralSwitching' }">
                  <input type="radio" name="dataTrafficManagementType"
                         @click="setFilter('dataTrafficManagementType', 'centralSwitching')"/>
                  {{ $t('wlans.centralSwitching') }}
                </label>
                <label
                  class="btn btn btn-outline-info"
                  :class="{ active: filters.dataTrafficManagementType == 'tunneling' }"
                >
                  <input type="radio" name="dataTrafficManagementType"
                         @click="setFilter('dataTrafficManagementType', 'tunneling')"/>
                   {{ $t('wlans.tunnelingShort') }}
                </label>
              </div>
            </div>
          </div>
          </div>
          <div v-if="isFiltersActive" class="row ">
          <div v-if="isFiltersActive" class=" pl-0 mb-1">
            <WButton secondary outline sm
              @click="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
        </div>
        <!-- <div class="pl-1">
          <WButton success
            v-if="!isDisable"
            @click="openWlanWizard"
          >
            {{ $t('wlans.addWLAN') }}
          </WButton>
        </div> -->
      </div>
    </div>


    <div class="col-lg-12">
      <div
        class="wlans"
        :class="{ 'edit-visible': editVisible || selectedWlansId.length, 'multiedit-visible': selectedWlansId.length }"
      >

        <div class="wlans-list animated fadeIn mt-1">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-wlans" v-if="isWlansLoading"></div>-->
                  <div v-if="isWlansLoading || isWlansOperations"><Loader-spinner /></div>
                  <div v-if="isLoadingAll && (showGoBackToWlansStatisticButton || showGoBackToClientsButton)"><Loader-spinner /></div>
                  <div class="card-block">
                    <WlansTable
                      :items="wlansList"
                      :totalItems="totalResult ? totalResult : 0"
                      :perPage="limit"
                      :selectedWlansIds="selectedWlansId"
                      :sortState="sortParams"
                      :isDataLoading="false"
                      :isDisabled="isLoadingAll || isWlansOperations"
                      :isOneColMode="selectedWlansId.length > 0"
                      :isSelectionRowsModeEnabled="!Boolean(isDisable)"
                      @on-cell-click="handleCellClick"
                      @on-selected-rows-change="handleSelectWlanRow"
                      @on-sort-change="handleSortChange"
                      @on-page-change="handlePageChange"
                      @on-per-page-change="handlePerPageChange"
                    >
                      <template v-slot:table-menu>
                        <div class="control-panel">
                          <div class="control-panel__container left">
                            <WButton info
                              v-if="!isDisable"
                              @click="openCreateWLANModal"
                            >
                              {{ $t('general.add') }}
                            </WButton>
                            <WButton info
                              v-if="!isDisable"
                              @click="openDeletingDialogforWlans"
                              :disabled="selectedWlansId.length <= 0"
                            >
                              {{ $t('general.delete') }}
                            </WButton>
                          </div>
                          <div class="control-panel__container right" v-if="!isWLCProduct">
                            <WButton info
                              v-if="!isDisable"
                              @click="openWlanWizard"
                            >
                              {{ $t('wlans.addWLANWizard') }}
                            </WButton>
                            <WButton
                              v-if="showGoBackToWlansStatisticButton"
                              @click="goBackToWlanStatistic"
                            >
                              {{ $t('wlans.goBackToWlanStatistic') }}
                            </WButton>
                            <WButton
                              v-if="showGoBackToClientsButton"
                              @click="goBackToClients"
                            >
                              {{ $t('wlans.goBackToClients') }}
                            </WButton>
                          </div>
                        </div>
                      </template>
                    </WlansTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wlans-edit" v-if="selectedWlansId.length > 0">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-block" v-if="!hideCommonEdit">
                  <div class="h4 m-0">
                    {{$t('general.multiEdit')}}
                  </div>
                  <small class="text-muted">{{$t('general.multiEditMode')}}</small>
                  <WButton primary outline fullWidth
                    customClass="mt-2"
                    v-if="areLocationsVisible"
                    @click="isChangeLocationOpen = true"
                  >
                    {{ $t('general.changeLocations') }}
                  </WButton>
                  <WButton success outline fullWidth
                    customClass="mt-2"
                    @click="openAddTagsDialog"
                  >
                    {{ $t('tags.addTags') }}
                  </WButton>
                  <WButton danger outline fullWidth
                    customClass="mt-2"
                    @click="openCommonDeletingTagsDialog"
                  >
                    {{ $t('tags.deleteAllTags') }}
                  </WButton>
                  <WButton danger outline fullWidth
                    customClass="mt-1"
                    @click="openDeletingDialogforWlans"
                  >
                    {{ $t('wlans.deleteMulti') }}
                  </WButton>
                  <WButton secondary outline fullWidth
                    customClass="mt-2"
                    @click="cancelCommonEdit"
                  >
                    {{ $t('general.cancel') }}
                  </WButton>
                </div>
                <div class="card-block" v-if="addTagsModal">
                  <div class="h4 mb-2">
                    {{ $t('aps.editTags') }}
                  </div>
                  <div class="modal-tags">
                    <p class="h5">{{ $t('tags.currentTags') }}:</p>
                    <span
                      v-for="(tag, index) in newTags"
                      class="tag tag--item mr-q"
                      @click.prevent="removeTagMultiple"
                      :data-index="index"
                    >
                      <i class="fa fa-tag"></i>
                      <i class="fa fa-remove"></i>
                      {{ tag }}
                    </span>
                    <div class="input-group mt-2">
                      <input
                        type="text"
                        name="tags"
                        class="form-control input-tag"
                        :placeholder="$t('wlans.addTag')"
                        :disabled="isDisable"
                        v-validate="'max:15'"
                        data-vv-validate-on="input"
                        v-model="newTag"
                        :class="{ input: true, 'is-danger': errors.has('tags') }"
                      />
                      <span class="input-group-btn">
                        <WButton success
                          customClass="mt-2"
                          @click="addTagMultiple"
                          :disabled="(this.newTag == '')"
                        >
                          {{ $t('general.add') }}
                        </WButton>
                      </span>
                    </div>
                    <span v-show="errors.has('tags')" class="help is-danger is-danger__block">
                      {{ errors.first('tags') }}
                    </span>
                    <div class="tags__availabel mt-1">
                      <p class="h6">{{ $t('tags.availlableTags') }}:</p>
                      <span
                        v-for="tag in wlansMultipleTagsList"
                        class="tag tag--add mr-q"
                        @click="addExistTagMultiple(tag)"
                      >
                        <i class="fa fa-tag"></i>
                        <i class="fa fa-plus"></i>
                        {{ tag }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton secondary outline
                        @click="addTagsModal = false"
                      >
                        <span v-if="isDisable">{{ $t('aps.close') }}</span>
                        <span v-else>{{ $t('aps.back') }}</span>
                      </WButton>
                    </div>
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="assignTags"
                        :disabled="!newTags.length"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('aps.update') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        <modal  close-btn @cancel="closeWLANModal" xlarge v-if="wlanModalShowing" title="Update or create WLAN " v-model="wlanModalShowing" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{isNew ? $t('wlans.wlanCreate') : $t('wlans.wlanEdit')}}</h4>
<!--      <pre>-->
<!--        {{this.updatedWlan}}-->
<!--      </pre>-->
                <div class="row">
            <div class="col-lg-12">
              <div class=" wlans-edit-w mb-0">
                <div class=" pb-0">
                  <div class="row">
                    <loader-spinner-small v-if="isCurrentCpeUpdating"></loader-spinner-small>
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info" :class="{'mb-1': !isNew}">
                          <div class="edit-header__name">
                            <span v-if="!isNew" class="h4">{{ wlanEditCurrent.ssid }}</span>
                            <span v-if="!isNew" class="ml-1 mb-0 wlan-status">
                              <span class="badge badge-success text-sm" v-if="wlanEditCurrent.status === 'Active'">
                                {{ $t('wlans.statusActive') }}
                              </span>
                              <span class="badge badge-danger text-sm" v-if="wlanEditCurrent.status === 'Not active'">
                                {{ $t('wlans.statusNotActive') }}
                              </span>
                              <span class="badge badge-warning text-sm" v-if="wlanEditCurrent.status === 'Pending'">
                                {{ $t('wlans.statusPending') }}
                              </span>
                              <span class="badge badge-success text-sm" v-if="wlanEditCurrent.status === 'Empty'">
                                {{ $t('wlans.statusEmpty') }}
                              </span>
                            </span>
                          </div>
                          <div class="edit-header__tags" v-if="!isNew" >
                            <span
                              v-for="(tag, index) in updatedWlan.tags"
                              v-if="updatedWlan.tags && updatedWlan.tags.length"
                              :class="{ 'tag--disable': isDisable }"
                              @click.prevent="removeTag"
                              :data-index="index"
                              class="tag tag--item"
                            >
                              <i class="fa fa-tag"></i>
                              <i class="fa fa-remove"></i>
                              {{ tag }}
                            </span>
                            <span class="tag tag--default" v-if="!updatedWlan.tags || !updatedWlan.tags.length">
                              <i class="fa fa-tag"></i>
                              {{ $t('general.noTags') }}
                            </span>
                          </div>
                        </div>
                        <div class="edit-header__buttons edit-header-buttons-in-wlans">
                          <WButton success outline sm
                            v-if="!isDisable && !isNew"
                            @click="wlanTagsModal = true"
                            v-tooltip.top-center="{ content: `${$t('general.addTags')}` }"
                          >
                            <i class="fa fa-tag"></i>
                          </WButton>

<!--                          <button-->
<!--                            type="button"-->
<!--                            class="btn btn-outline-secondary btn-sm new-modern-style-btn-close-panel"-->
<!--                            @click="closeWLANModal"-->
<!--                          >-->
<!--                            <i class="fa fa-close"></i>-->
<!--                          </button>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                                  <div class="row" v-show="!isNew" >
                    <div class="col-lg-12">
                      <div class="row mb-1">
                        <div class="col-lg-6"  >
                          <small class="">ID:&nbsp;</small>
                          <small class="text-muted">{{ wlanEditCurrent.id }}</small>
                          <br />

                          <small class="">{{ $t('wlans.radiusAccountingInterval') }}:&nbsp;</small>
                          <small class="text-muted">
                            {{ wlanEditCurrent.radius_acct_interval }} {{ $t('general.sec') }}.
                          </small>
                          <br />
                          <small class="">{{ $t('general.cpes') }}:&nbsp;</small>
                          <small
                            class="text-muted mr-1"
                            v-if="
                              wlanEditCurrent.cpe_ids &&
                              wlanEditCurrent.cpe_ids.length &&
                              cpeObj(cpeId) &&
                              cpeObj(cpeId).name
                            "
                            v-for="cpeId in wlanEditCurrent.cpe_ids"
                          >
                            <img class="img img-responsive cpe-logo" src="/static/img/router_gray.svg" alt="" />
                            {{ cpeObj(cpeId).name }}
                          </small>
                          <small v-if="!wlanEditCurrent.cpe_ids || !wlanEditCurrent.cpe_ids.length" class="text-muted">
                            {{ $t('wlans.noCurrentCPEs') }}
                          </small>
                          <br />
                          <small class="" v-if="wlanEditCurrent.description">
                            {{ $t('general.description') }}:&nbsp;
                          </small>
                          <small class="text-muted">{{ wlanEditCurrent.description }}</small>
                          <br v-if="wlanEditCurrent.description" />
                          <br />
                          <div>

                          </div>
                          <small class="text-muted pt-1" v-if="isDisable">{{ $t('general.readOnlyMode') }}</small>
                          <small class="text-muted pt-1" v-else>{{ $t('general.editingMode') }}</small>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-end"  >
                          <div>
                            <wlan-q-r-code-generator   right-algin :wlan-object="wlanEditCurrent">
                              <template v-slot:caption></template>
                              =
                            </wlan-q-r-code-generator>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                <ul class="nav nav-tabs row m-0" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      role="tab"
                      :class="{ active: modalTab === 'General', 'tab-has-error': generalTabHasErrors  }"
                      @click="setModalTabTo('General')"
                    >
                      {{$t('wlans.tabGeneral')}}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      role="tab"
                      :class="{ active: modalTab === 'Security', 'tab-has-error': securityTabHasErrors }"
                      @click="setModalTabTo('Security')"
                    >
                      {{$t('wlans.tabSecurity')}}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      role="tab"
                      :class="{ active: modalTab === 'Advanced', 'tab-has-error': advancedTabHasErrors }"
                      @click="setModalTabTo('Advanced')"
                    >
                      {{$t('wlans.tabAdvanced')}}
                    </a>
                  </li>






                </ul>
                <!--                General modal tab-->
                <div class="mt-1" v-show="modalTab === 'General'">


                                    <div class="row">
                    <div class="col-lg-12">
                      <!--<div class="h5 mt-1 mb-1">-->
                      <!--General-->
                      <!--</div>-->
                      <div class="form-group">
                        <label for="ssid">SSID</label>
                        <input
                          type="text"
                          id="ssid"
                          name="ssid"
                          :placeholder="$t('wlans.ssidPlaceholder')"
                          class="form-control"
                          :class="{ input: true, 'is-danger': errors.has('ssid') }"
                          v-validate="'required|ssid'"
                          v-model="updatedWlan.ssid"
                          :disabled="isDisable"
                        />
                        <span v-show="errors.has('ssid')" class="help is-danger">{{ errors.first('ssid') }}</span>
                      </div>
                      <div class="form-group">
                        <label for="description">{{ $t('general.description') }}</label>
                        <input
                          type="text"
                          id="description"
                          v-validate="'max:200'"
                          :class="{ input: true, 'is-danger': errors.has('description') }"
                          name="description"
                          :placeholder="$t('wlans.descriptionPlaceholder')"
                          class="form-control"
                          v-model="updatedWlan.description"
                          :disabled="isDisable"
                        />
                        <span v-show="errors.has('description')" class="help is-danger">
                          {{ errors.first('description') }}
                        </span>
                      </div>

                      <div class="form-group" v-if="areLocationsVisible">
                        <label for="location">{{ $t('general.location') }}</label>
                        <select
                          v-model="updatedWlan.base_location"
                          name="location"
                          id="location"
                          :class="{ 'select-disabled': isDisable }"
                          :disabled="isDisable"
                          class="form-control"
                        >
                          <option v-for="location in locationsList" :key="location.id" :value="location.id">
                            {{ location.name }}
                          </option>
                        </select>
                      </div>

                      <div class="row">
                        <div class="form-group col-sm-6">
                          <Switch-component
                            v-model="updatedWlan.hidden"
                            :disabled="isDisable"
                            :label="$t('wlans.hidden')"
                            id="hidden"
                          />
                        </div>
                      </div>
                    </div>
                                    </div>


                </div>
                <!--                Security modal tab-->
                <div class="mt-1" v-show="modalTab === 'Security'">
                  <!--                      new security-->
                                            <div class="wlan-settings-block-for-modal mb-1 p-0">
                        <div
                          class="wlan-settings-block__header p-0"
                          @click.prevent="showSecurity"
                          :class="{ 'mb-2': isSecurityShow }"
                        >
                          <div v-if="false">
<!--                            <span class="h6 mr-1">{{ $t('wlans.security') }}</span>-->
                            <span v-if="updatedWlan.security.type === 'wpapersonal'" class="badge mr-q badge-info">
                              WPA Personal
                            </span>
                            <span v-if="updatedWlan.security.type === 'wpaenterprise'" class="badge mr-q badge-primary">
                              WPA Enterprise
                            </span>
                            <span v-if="updatedWlan.security.type === 'wpa2personal'" class="badge mr-q badge-info">
                              WPA2 Personal
                            </span>
                            <span v-if="updatedWlan.security.type === 'wpa3personal'" class="badge mr-q badge-info">
                              WPA3 Personal
                            </span>
                            <span v-if="updatedWlan.security.type === 'wpa3enterprise'" class="badge mr-q badge-info">
                              WPA3 Enterprise
                            </span>
                            <span v-if="updatedWlan.security.type === 'wpa23personal'" class="badge mr-q badge-info">
                              WPA2/3 Personal
                            </span>
                            <span v-if="updatedWlan.security.type === 'wpa23enterprise'" class="badge mr-q badge-info">
                              WPA2/3 Enterprise
                            </span>
                            <span
                              v-if="updatedWlan.security.type === 'wpa2enterprise'"
                              class="badge mr-q badge-primary"
                            >
                              WPA2 Enterprise
                            </span>
                            <span v-if="updatedWlan.security.type === 'open'" class="badge mr-q badge-default">
                              {{ $t('wlans.securityOpen') }}
                            </span>
                                                        <span v-if="wlanEditCurrent.filtermode === 'WhiteList'" class="badge mr-q badge-info">
                              {{ $t('wlans.whiteList') }}
                            </span>
                            <span v-if="wlanEditCurrent.filtermode === 'BlackList'" class="badge mr-q badge-dark">
                              {{ $t('wlans.blackList') }}
                            </span>
                            <span
                              v-if="wlanEditCurrent.guest_control.captive_redirect"
                              class="badge mr-q badge-warning"
                            >
                              {{ $t('wlans.redirect') }}
                            </span>
                            <span v-if="wlanEditCurrent.l2isolate" class="badge mr-q badge-warning">
                              {{ $t('wlans.L2isolation') }}
                            </span>
                            <span v-if="wlanEditCurrent.firewall.l2_chain" class="badge mr-q badge-warning">
                              {{ $t('wlans.firewall') }}
                            </span>
                          </div>
<!--                          <button-->
<!--                            class="btn btn-link float-right new-modern-style-btn-other-btn"-->
<!--                            v-if="!isSecurityShow"-->
<!--                          >-->
<!--                            <i class="icon-arrow-left"></i>-->
<!--                          </button>-->
<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn" v-if="isSecurityShow">-->
<!--                            <i class="icon-arrow-down"></i>-->
<!--                          </button>-->
                        </div>
                        <transition name="wsettings">
                          <div class="" v-if="true">
                    <div class="custom-tabs-wrapper-in-wlans">
                            <ul class="nav nav-tabs row m-0" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: securityTab === 'L2', 'tab-has-error': securityL2TabHasErrors }"
                @click="setSecurityTabTabTo('L2')"
              >
                {{$t('wlans.L2TabCaption')}}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: securityTab === 'L3', 'tab-has-error': securityL3TabHasErrors }"
                @click="setSecurityTabTabTo('L3')"
              >
                {{$t('wlans.L3TabCaption')}}                       <small :class="{'not-for-cisco': isCiscoModeEnabled }">

                      </small>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: securityTab === 'AAA', 'tab-has-error': securityAAATabHasErrors }"
                @click="setSecurityTabTabTo('AAA')"
              >
               {{$t('wlans.AAATabCaption')}}
              </a>
            </li>

          </ul>
                    </div>







                            <div class="mt-1 pl-1 pr-1" v-show="securityTab === 'L2'">

                                                          <div class="row" v-if="updatedWlan.security">
                              <transition name="infoMessageForWPA3" mode="out-in">
                                <div v-if="checkWPA3Type(updatedWlan.security.type)"
                                     class="form-group col-sm-12 mt-1">
                                  <Alert class="alert-info">
                                    <template #text>
                                      <span class="mr-h">{{ $t('wlans.wpa3IsNotSupportedOnAllCPE') }}</span>
                                    </template>
                                  </Alert>
                                </div>
                              </transition>
                              <div class="form-group col-sm-12">
<!--                                <label for="security">{{ $t('wlans.security') }}</label>-->
                                <label for="security">{{ $t('wlans.securitySettingsCaption') }}</label>
                                <select
                                  id="security"
                                  class="form-control"
                                  @change="setSecurityType(updatedWlan.security.type)"
                                  v-model="updatedWlan.security.type"
                                  :disabled="isDisable"
                                  :class="{ 'select-disabled': isDisable }"
                                >
                                  <option value="open" :selected="updatedWlan.security.type === 'open'">
                                    {{ $t('wlans.securityOpen') }}
                                  </option>
                                  <option value="wpapersonal" :selected="updatedWlan.security.type === 'wpapersonal'">
                                    WPA Personal
                                  </option>
                                  <option
                                    value="wpaenterprise"
                                    :selected="updatedWlan.security.type === 'wpaenterprise'"
                                  >
                                    WPA Enterprise
                                  </option>
                                  <option value="wpa2personal" :selected="updatedWlan.security.type === 'wpa2personal'">
                                    WPA2 Personal
                                  </option>
                                  <option
                                    value="wpa2enterprise"
                                    :selected="updatedWlan.security.type === 'wpa2enterprise'"
                                  >
                                    WPA2 Enterprise
                                  </option>
                                  <option value="wpa3personal" :selected="updatedWlan.security.type === 'wpa3personal'">
                                    WPA3 Personal
                                  </option>
                                  <option value="wpa3enterprise" :selected="updatedWlan.security.type === 'wpa3enterprise'">
                                    WPA3 Enterprise
                                  </option>
                                  <option value="wpa23personal" :selected="updatedWlan.security.type === 'wpa23personal'">
                                    WPA2/3 Personal
                                  </option>
                                  <option value="wpa23enterprise" :selected="updatedWlan.security.type === 'wpa23enterprise'">
                                    WPA2/3 Enterprise
                                  </option>
                                </select>
                              </div>
                            </div>

                            <!-- WPA2 Personal -->
                            <div
                              role="group"
                              class="form-group row"
                              v-if="updatedWlan.security && updatedWlan.security.type.indexOf('personal') !== -1"
                            >
                              <div class="col-12">
                                <label for="psk_password">{{ $t('general.password') }}</label>
                                <div role="group" class="input-group">
                                  <input
                                    ref="wlanPasswordInput"
                                    id="psk_password"
                                    :type="showWlanPass ? 'text' : 'password'"
                                    name="psk_password"
                                    autocomplete="new-password"
                                    :placeholder="$t('wlans.enterWlanPasswordPlaceholder')"
                                    class="form-control"
                                    v-validate="'required|min:8|max:63'"
                                    :class="{ input: true, 'is-danger': errors.has('psk_password') }"
                                    v-model="updatedWlan.security.data.psk"
                                    :disabled="isDisable"
                                  />
                                  <div class="input-group-addon hovered" @click="showWlanPass = !showWlanPass">
                                    <i class="fa fa-eye" v-if="showWlanPass"></i>
                                    <i class="fa fa-eye-slash" v-if="!showWlanPass"></i>
                                  </div>
                                  <Password-generator
                                    @passwordGenerated="passwordGeneratedForEditedWlan"
                                    class="ml-1"
                                  ></Password-generator>
                                </div>
                              </div>
                            </div>
                            <!-- End WPA2 Personal -->

                                                            <!-- PPSKSettingsSwitcher -->
                              <div
                                v-if="showPPSKSettingSwitcher">
                                <div class="row mt-1">
                                  <div class="form-group col-sm-6">
                                    <Switch-component
                                      @change="ppskSwitcherHandler"
                                      v-model="updatedWlan.ppsk"
                                      :disabled="isDisable"
                                      :label="$t('wlans.ppsk')"
                                      id="ppsk"
                                    />
                                  </div>
                                </div>

                              </div>

                              <!-- end PPSKSettingsSwitcher -->

                            <!-- WPA2 Enterprise -->
                            <div
                              class="form-group"
                              v-if="updatedWlan.security && updatedWlan.security.type.indexOf('enterprise') !== -1"
                              :class="{ 'multiselect-disabled-field': isDisable }"
                            >
                              <label for="radius">RADIUS</label>
                              <small><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRadiusCreate">{{$t('wlans.addRADIUS')}}</a></small>
                              <small v-if="updatedWlanRadiusAuthentication.length === 1"><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRadiusEdit(updatedWlanRadiusAuthentication[0].name)">{{$t('wlans.editRADIUS')}}</a></small>
                              <!-- <select id="radius" :class="{'input': true, 'is-danger': errors.has('radius'), 'select-disabled': isDisable }" :disabled="isDisable" v-validate="'required'" class="form-control"  name="radius" v-model="updatedWlan.security.data.radiusauthentication[0]">
                                                                <option v-for="(radius, index) in radiusItems" v-bind:value="index" >{{radiusItems[index].name}}</option>
                                                            </select>
                                                            <span v-show="errors.has('radius')" class="help is-danger">{{ errors.first('radius') }}</span> -->
                              <Multiselect
                                class="rule-multi"
                                v-model="updatedWlanRadiusAuthentication"
                                :multiple="true"
                                :options="radiusList || []"
                                :max="8"
                                id="radius"
                                @input="enableSave()"
                                trackBy="id"
                                label="name"
                                name="RADIUS Authentication"
                                v-validate="'required'"
                                :class="{
                                  'multiselect-disabled': isDisable,
                                  input: true,
                                  'is-danger': errors.has('RADIUS Authentication')
                                }"
                                :disabled="isDisable"
                                :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                                :selectLabel="$t('general.multiSelectPressToSelect')"
                                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                                :selectedLabel="$t('general.multiSelectSelected')"
                              ></Multiselect>
                              <span v-show="errors.has('RADIUS Authentication')" class="help is-danger mt-0 mb-1">
                                {{ errors.first('RADIUS Authentication') }}
                              </span>
                            </div>
<!--                            <span v-if="updatedWlan.security && updatedWlan.security.type.indexOf('enterprise') !== -1" class=" mt-1">{{ $t('wlans.radiusAccounting') }}</span>-->
<!--                            <div-->
<!--                              class="form-group ml-2"-->
<!--                              v-if="updatedWlan.security && updatedWlan.security.type.indexOf('enterprise') !== -1"-->
<!--                              :class="{ 'multiselect-disabled-field': isDisable }"-->
<!--                            >-->
<!--                              <transition name="wsettings">-->
<!--                                <div v-if="" class="mt-1">-->
<!--                                  <div class="form-group mb-1">-->
<!--                                    <Switch-component-->
<!--                                      v-model="updatedWlan.radius_acct_mirroring"-->
<!--                                      :disabled="isDisable"-->
<!--                                      :label="$t('wlans.mirroring')"-->
<!--                                      id="radius-acct-mirroring"-->
<!--                                    />-->
<!--                                    <info class="ml-q" :content="$t('wlans.mirroringHint')"/>-->
<!--                                  </div>-->

<!--                                  <div class="form-group"-->
<!--                                       :class="{ 'multiselect-disabled-field': isDisable }">-->
<!--                                    <label for="radius_acc">{{ $t('wlans.radiusAccounting')-->
<!--                                      }}</label><small> <a href="#" class="ml-q mb-1"-->
<!--                                                           @click.stop.prevent="goToRadiusCreate">{{$t('wlans.addRADIUS')}}</a></small>-->
<!--                                    <Multiselect-->
<!--                                      id="radius_acc"-->
<!--                                      class="rule-multi"-->
<!--                                      data-vv-name="radius_acc"-->
<!--                                      v-model="updatedWlanRadiusAccounting"-->
<!--                                      :multiple="true"-->
<!--                                      :options="radiusList || []"-->
<!--                                      :max="8"-->
<!--                                      trackBy="id"-->
<!--                                      label="name"-->
<!--                                      :disabled="isDisable"-->
<!--                                      @input="enableSave()"-->
<!--                                      :class="{-->
<!--                                  input: true,-->
<!--                                  'is-danger': errors.has('radius_acc'),-->
<!--                                  'multiselect-disabled': isDisable-->
<!--                                }"-->
<!--                                      :placeholder="$t('wlans.selectRADIUSPlaceholder')"-->
<!--                                      :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                                      :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                                      :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                                    ></Multiselect>-->
<!--                                  </div>-->
<!--                                  <div class="form-group mt-1" v-if="isBeelineBranding">-->
<!--                                    <label for="vlan">-->
<!--                                      {{ $t('wlans.rsnaSettingsV2') }}-->
<!--                                    </label>-->
<!--                                    <input-->
<!--                                      :data-vv-as="'Calling Station ID'"-->
<!--                                      type="text"-->
<!--                                      id="RSNA"-->
<!--                                      name="RSNA"-->
<!--                                      :class="{ input: true, 'is-danger': errors.has('RSNA') }"-->
<!--                                      v-validate="'max:16'"-->
<!--                                      :placeholder="$t('wlans.enterRSNAPlaceholder')"-->
<!--                                      class="form-control"-->
<!--                                      v-model="updatedWlan.beeline_accountng_type"-->
<!--                                    />-->
<!--                                    <small class="text-muted">-->
<!--                                      {{ $t('wlans.rsnaCapture') }}-->
<!--                                    </small>-->
<!--                                  </div>-->
<!--                                  <span v-show="errors.has('RSNA')" class="help is-danger">-->
<!--                              {{ errors.first('RSNA') }}-->
<!--                            </span>-->
<!--                                </div>-->
<!--                              </transition>-->

<!--                            </div>-->
<!--                            <span v-if="updatedWlan.security && updatedWlan.security.type.indexOf('enterprise') !== -1" class=" mt-1">{{ $t('wlans.wizardRADIUSAdditionalSettings') }}</span>-->

<!--                            <div-->
<!--                              class="form-group ml-2"-->
<!--                              v-if="updatedWlan.security && updatedWlan.security.type.indexOf('enterprise') !== -1"-->
<!--                              :class="{ 'multiselect-disabled-field': isDisable }"-->
<!--                            >-->

<!--                              <transition name="wsettings">-->
<!--                                <div v-if="" class="mt-1">-->
<!--                                  <div class="form-group">-->
<!--                                    <label for="nas_id">{{ $t('wlans.NASidentifier') }}</label>-->
<!--                                    <input-->
<!--                                      type="text"-->
<!--                                      id="nas_id"-->
<!--                                      name="nas_id"-->
<!--                                      :placeholder="$t('wlans.enterNASIdentifierPlaceholder')"-->
<!--                                      class="form-control"-->
<!--                                      v-model="updatedWlan.nas_id"-->
<!--                                      :disabled="isDisable"-->
<!--                                    />-->
<!--                                  </div>-->

<!--                                  <div class="form-group">-->
<!--                                    <label for="nas_port_id">{{ $t('wlans.NASportid') }}</label>-->
<!--                                    <input-->
<!--                                      type="text"-->
<!--                                      id="nas_port_id"-->
<!--                                      name="nas_port_id"-->
<!--                                      :placeholder="$t('wlans.enterNASPortIdPlaceholder')"-->
<!--                                      class="form-control"-->
<!--                                      v-model="updatedWlan.nas_port_id"-->
<!--                                      :disabled="isDisable"-->
<!--                                    />-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </transition>-->


<!--                            </div>-->

                            <div
                              class="form-group"
                              v-if="updatedWlan.security && updatedWlan.security.type.indexOf('enterprise') !== -1 && isHotspotSelectorShowingDependingOnTheBuild"
                            >
                              <label for="hotspot20_profile">{{ $t('wlans.hotspotProfileTitle') }}</label>
                              <select
                                v-model="updatedWlan.security.data.hotspot20_profile"
                                name="hotspot20_profile"
                                id="hotspot20_profile"
                                :class="{ 'select-disabled': isDisable }"
                                :disabled="isDisable"
                                class="form-control"
                              >
                                <option :value="''">
                                  {{ $t('wlans.hotspotProfileNone') }}
                                </option>
                                <option v-for="hotspot in hotspotsList" :value="hotspot.id" :key="hotspot.id">
                                  {{ hotspot.name }}
                                </option>
                              </select>
                            </div>

                            <!-- End WPA2 Enterprise -->




                            <div
                              class="form-group"
                              v-if="updatedWlan.security && updatedWlan.security.type != 'open'"
                              :class="{ 'multiselect-disabled-field': isDisable }"
                            >
                              <label for="suites">{{ $t('wlans.suites') }}</label>
                              <Multiselect
                                data-vv-name="suites"
                                id="suites"
                                class="mb-1"
                                v-model="updatedWlan.security.data.suites"
                                :multiple="true"
                                @input="enableSave()"
                                v-validate="'required'"
                                :options="(updatedWlan.security.type.indexOf('2') !== -1 || updatedWlan.security.type.indexOf('3'))  ? ['aes', 'tkip'] : ['tkip']"
                                :placeholder="$t('wlans.suitesPlaceholder')"
                                :disabled="isDisable"
                                :class="{
                                  input: true,
                                  'is-danger': errors.has('suites'),
                                  'multiselect-disabled': isDisable
                                }"
                                :selectLabel="$t('general.multiSelectPressToSelect')"
                                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                                :selectedLabel="$t('general.multiSelectSelected')"
                              ></Multiselect>
                              <span v-show="errors.has('suites')" class="help is-danger">
                                {{ errors.first('suites') }}
                              </span>
                              <span v-show="isSuitesValidation" class="text-danger">The suites field is required</span>
                            </div>
                            <div v-if="!showPPSKRADIUSSelector" class="form-group" :class="{ 'multiselect-disabled-field': isDisable }">
                              <label for="radius_mac">{{ $t('wlans.MACRADIUSAuthentication') }}</label>
                              <small>
                                <a href="#" class="ml-q mb-1"
                                  @click.stop.prevent="goToRadiusCreate"
                                >
                                  {{$t('wlans.addRADIUS')}}
                                </a>
                              </small>
                               <small v-if="updatedWlanRadiusMAC.length === 1"><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRadiusEdit(updatedWlanRadiusMAC[0].name)">{{$t('wlans.editRADIUS')}}</a></small>
                              <Multiselect
                                id="radius_mac"
                                class="rule-multi"
                                data-vv-name="radius_mac"
                                v-model="updatedWlanRadiusMAC"
                                :multiple="true"
                                :options="radiusList || []"
                                :max="8"
                                trackBy="id"
                                label="name"
                                :disabled="isDisable"
                                @input="enableSave()"
                                :class="{
                                  input: true,
                                  'is-danger': errors.has('radius_mac'),
                                  'multiselect-disabled': isDisable
                                }"
                                :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                                :selectLabel="$t('general.multiSelectPressToSelect')"
                                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                                :selectedLabel="$t('general.multiSelectSelected')"
                              ></Multiselect>
                            </div>

                              <!-- PPSKRADIUS Selector-->
                              <div
                              class="form-group"
                              v-if="showPPSKRADIUSSelector"
                              :class="{ 'multiselect-disabled-field': isDisable }"
                            >
                              <label for="radius">{{ $t('wlans.MACRADIUSAuthentication') }}</label><small><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRadiusCreate">{{$t('wlans.addRADIUS')}}</a></small>
                              <small v-if="updatedWlanRadiusAuthentication.length === 1"><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRadiusEdit(updatedWlanRadiusAuthentication[0].name)">{{$t('wlans.editRADIUS')}}</a></small>
                              <Multiselect
                                :data-vv-as="$t('wlans.MACRADIUSAuthentication')"
                                class="rule-multi"
                                v-model="updatedWlanRadiusAuthentication"
                                :multiple="true"
                                :options="radiusList || []"
                                :max="8"
                                id="radius_for_ppsk"
                                @input="enableSave()"
                                trackBy="id"
                                label="name"
                                name="RADIUS Authentication For PPSK"
                                v-validate="'required'"
                                :class="{
                                  'multiselect-disabled': isDisable,
                                  input: true,
                                  'is-danger': errors.has('RADIUS Authentication For PPSK')
                                }"
                                :disabled="isDisable"
                                :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                                :selectLabel="$t('general.multiSelectPressToSelect')"
                                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                                :selectedLabel="$t('general.multiSelectSelected')"
                              ></Multiselect>
                              <span v-show="errors.has('RADIUS Authentication For PPSK')" class="help is-danger mt-0 mb-1">
                                {{ errors.first('RADIUS Authentication For PPSK') }}
                              </span>
                            </div>


                            </div>








                            <div class=" mt-1 ml-1 pl-1 pr-1" v-show="securityTab === 'L3'">
                              <div v-if="isCiscoModeEnabled">                       <small class="text-danger p-0" v-if="isCiscoModeEnabled">
                        * {{ $t('wlans.notAvailableForCisco') }}
                      </small></div>
                                                      <transition name="wsettings" mode="out-in">
                          <div class="" >
                            <div class="row mt-2">
                              <div class="form-group col-sm-6">
                                <Switch-component
                                  v-model="updatedWlan.l2isolate"
                                  :disabled="isDisable"
                                  :label="$t('wlans.L2isolation')"
                                  id="l2isolate"
                                />
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="captive_redirect">{{ $t('wlans.redirect') }}</label> <small><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRedirectCreate">{{$t('wlans.addRedirectRule')}}</a></small>
                              <select
                                v-model="updatedWlan.guest_control.captive_redirect"
                                name="captive_redirect"
                                id="captive_redirect"
                                :class="{ 'select-disabled': isDisable }"
                                :disabled="isDisable"
                                class="form-control"
                              >
                                <option :value="''">
                                  {{ $t('wlans.none') }}
                                </option>
                                <option v-for="redirect in redirectsList" :value="redirect.id" :key="redirect.id">
                                  {{ redirectName(redirect.id) }}
                                </option>
                              </select>
                            </div>



                            <div class="form-group">
                              <label for="l2_chain">{{ $t('wlans.firewall') }}</label>
                              <select
                                v-model="updatedWlan.firewall.l2_chain"
                                name="l2_chain"
                                id="l2_chain"
                                :class="{ 'select-disabled': isDisable }"
                                :disabled="isDisable"
                                class="form-control"
                              >
                                <option :value="''">
                                  {{ $t('wlans.none') }}
                                </option>
                                <option v-for="firewall in firewallsList" :value="firewall.id" :key="firewall.id">
                                  {{ firewallName(firewall.id) }}
                                </option>
                              </select>
                            </div>

                            <label for="security">{{ $t('wlans.filterMode') }}</label>
                            <div class="form-group mb-0">
                              <select
                                id="filtermode"
                                class="form-control"
                                v-model="updatedWlan.filtermode"
                                :disabled="isDisable"
                                :class="{ 'select-disabled': isDisable }"
                              >
                                <option value="None" :selected="updatedWlan.filtermode === 'None'">
                                  {{ $t('wlans.none') }}
                                </option>
                                <option value="WhiteList" :selected="updatedWlan.filtermode === 'WhiteList'">
                                  {{ $t('wlans.whiteList') }}
                                </option>
                                <option value="BlackList" :selected="updatedWlan.filtermode === 'BlackList'">
                                  {{ $t('wlans.blackList') }}
                                </option>
                              </select>
                            </div>
                            <div class="wlan-filtermode">
                              <p v-if="updatedWlan.filtermode === 'None'">
                                <small class="text-primary">{{ $t('wlans.none') }}:</small>
                                <small class="text-muted">{{ $t('wlans.filterNoneModeCaption') }}</small>
                              </p>
                              <p v-if="updatedWlan.filtermode === 'WhiteList'">
                                <small class="text-primary">{{ $t('wlans.filterWhitelistMode') }}:</small>
                                <small class="text-muted">{{ $t('wlans.filterWhitelistModeCaption') }}</small>
                              </p>
                              <p v-if="updatedWlan.filtermode === 'BlackList'">
                                <small class="text-primary">{{ $t('wlans.filterBlacklistMode') }}:</small>
                                <small class="text-muted">
                                  {{ $t('wlans.filterBlacklistModeCaption') }}
                                </small>
                              </p>
                            </div>

                            <div class="form-group mb-0" v-if="updatedWlan.filtermode != 'None'">
                              <div class="input-group">
                                <input
                                  type="text"
                                  @keyup.13="addMacAddress"
                                  @input="enableSaveChanges = true"
                                  name="mac"
                                  class="form-control"
                                  placeholder="00:00:00:00:00:00"
                                  :disabled="isDisable"
                                  v-validate="'mac'"
                                  data-vv-validate-on="input"
                                  v-model="newMacAddress"
                                  :class="{ input: true, 'is-danger': errors.has('mac') }"
                                />
                                <span class="input-group-btn">
                                  <WButton success
                                    @click="addMacAddress"
                                    :disabled="(this.newMacAddress == '')"
                                  >
                                    {{ $t('general.add') }}
                                  </WButton>
                                </span>
                              </div>
                            </div>

                            <div class="ml-h mb-q" v-if="updatedWlan.filtermode !== 'None'">
                              <small v-if="updatedWlan.filtermode === 'WhiteList'">
                                <strong>
                                  {{ $t('wlans.currentWhitelistItems') }}: {{ filterList.whitelist.length }}
                                </strong>
                              </small>
                              <small v-if="updatedWlan.filtermode === 'BlackList'">
                                <strong>
                                  {{ $t('wlans.currentBlacklistItems') }}: {{ filterList.blacklist.length }}
                                </strong>
                              </small>
                            </div>
                            <ul class="items_list p-0 ml-h">
                              <li
                                v-for="(mac, index) in filterList.whitelist"
                                v-if="updatedWlan.filtermode === 'WhiteList'"
                              >
                                <small
                                  data-list="whitelist"
                                  :data-index="index"
                                  :data-mac="mac"
                                  :class="{ 'item-for-delete': !isDisable }"
                                  @click="removeMac"
                                  class="text-uppercase"
                                >
                                  {{ mac }}
                                  <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                                </small>
                              </li>
                              <li
                                v-for="(mac, index) in filterList.blacklist"
                                v-if="updatedWlan.filtermode === 'BlackList'"
                              >
                                <small
                                  data-list="blacklist"
                                  :data-index="index"
                                  :data-mac="mac"
                                  :class="{ 'item-for-delete': !isDisable }"
                                  @click="removeMac"
                                  class="text-uppercase"
                                >
                                  {{ mac }}
                                  <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                                </small>
                              </li>
                            </ul>
                          </div>
                        </transition>







                            </div>




                            <div class="mt-1 ml-1 pl-1 pr-1" v-show="securityTab === 'AAA'">
<!--                                                          <span v-if="" class=" mt-1">{{ $t('wlans.radiusAccounting') }}</span>-->
                            <div
                              class="form-group "
                              v-if=""
                              :class="{ 'multiselect-disabled-field': isDisable }"
                            >
                              <transition name="wsettings">
                                <div v-if="" class="mt-1">
                                  <div class="form-group mb-1">
                                    <Switch-component
                                      v-model="updatedWlan.radius_acct_mirroring"
                                      :disabled="isDisable"
                                      :label="$t('wlans.mirroring')"
                                      id="radius-acct-mirroring"
                                    />
                                    <info class="ml-q" :content="$t('wlans.mirroringHint')"/>
                                  </div>

                                  <div class="form-group"
                                       :class="{ 'multiselect-disabled-field': isDisable }">
                                    <label for="radius_acc">{{ $t('wlans.radiusAccountingForWLANModalAAA')
                                      }}</label><small> <a href="#" class="ml-q mb-1"
                                                           @click.stop.prevent="goToRadiusCreate">{{$t('wlans.addRADIUS')}}</a></small>
                                    <small v-if="updatedWlanRadiusAccounting.length === 1"><a href="#" class="ml-q mb-1"  @click.stop.prevent="goToRadiusEdit(updatedWlanRadiusAccounting[0].name)">{{$t('wlans.editRADIUS')}}</a></small>
                                    <Multiselect
                                      id="radius_acc"
                                      class="rule-multi"
                                      data-vv-name="radius_acc"
                                      v-model="updatedWlanRadiusAccounting"
                                      :multiple="true"
                                      :options="radiusList || []"
                                      :max="8"
                                      trackBy="id"
                                      label="name"
                                      :disabled="isDisable"
                                      @input="enableSave()"
                                      :class="{
                                  input: true,
                                  'is-danger': errors.has('radius_acc'),
                                  'multiselect-disabled': isDisable
                                }"
                                      :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                                      :selectLabel="$t('general.multiSelectPressToSelect')"
                                      :deselectLabel="$t('general.multiSelectDeselectLabel')"
                                      :selectedLabel="$t('general.multiSelectSelected')"
                                    ></Multiselect>
                                  </div>

                                  <div class="form-group" v-if="showAAAOverrideSwitcher">
                                    <!-- AAAOverride switcher -->
                                    <div>
                                      <div>
                                        <Switch-component
                                          v-model="updatedWlan.aaa_override"
                                          :disabled="isDisable"
                                          :label="$t('wlans.AAAOverride')"
                                          id="AAAOverride"
                                        />
                                      </div>
                                    </div>
                                    <!-- end AAAOverride switcher -->
                                  </div>

                                  <div class="form-group mt-1" v-if="isBeelineBranding || isExperimentalModeEnabled">
                                    <label for="vlan">
                                      {{ $t('wlans.rsnaSettingsV2') }}
                                    </label>
                                    <input
                                      :data-vv-as="'Calling Station ID'"
                                      type="text"
                                      id="RSNA"
                                      name="RSNA"
                                      :class="{ input: true, 'is-danger': errors.has('RSNA') }"
                                      v-validate="'max:16'"
                                      :placeholder="$t('wlans.enterRSNAPlaceholder')"
                                      class="form-control"
                                      v-model="updatedWlan.beeline_accountng_type"
                                    />
                                    <small class="text-muted">
                                      {{ $t('wlans.rsnaCapture') }}
                                    </small>
                                  </div>
                                  <span v-show="errors.has('RSNA')" class="help is-danger">
                              {{ errors.first('RSNA') }}
                            </span>
                                </div>
                              </transition>

                            </div>
<!--                            <span v-if="" class=" mt-1">{{ $t('wlans.wizardRADIUSAdditionalSettings') }}</span>-->

                            <div
                              class="form-group"
                              :class="{ 'multiselect-disabled-field': isDisable }"
                            >

                              <transition name="wsettings">
                                <div v-if="" class="mt-1">
                                  <div class="form-group">
                                    <label for="nas_id">{{ $t('wlans.NASidentifier') }}</label>
                                    <input
                                      type="text"
                                      id="nas_id"
                                      name="nas_id"
                                      :placeholder="$t('wlans.enterNASIdentifierPlaceholder')"
                                      class="form-control"
                                      v-model="updatedWlan.nas_id"
                                      :disabled="isDisable"
                                    />
                                  </div>

                                  <div class="form-group">
                                    <label for="nas_port_id">{{ $t('wlans.NASportid') }}</label>
                                    <input
                                      type="text"
                                      id="nas_port_id"
                                      name="nas_port_id"
                                      :placeholder="$t('wlans.enterNASPortIdPlaceholder')"
                                      class="form-control"
                                      v-model="updatedWlan.nas_port_id"
                                      :disabled="isDisable"
                                    />
                                  </div>
                                </div>
                              </transition>


                            </div>
                            </div>


                          </div>
                        </transition>
                      </div>

<!--                      new security end-->







                </div>
                <!--                Advanced modal tab-->
                <div class="mt-1" v-show="modalTab === 'Advanced'">
                  <ul class="nav nav-tabs row m-0" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        role="tab"
                        :class="{ active: advancedTab === 'DataTrafficManagement', 'tab-has-error': advancedDataTrafficManagementTabHasErrors }"
                        @click="setAdvancedTabTo('DataTrafficManagement')"
                      >
                        {{$t('wlans.dataTrafficManagement')}}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        role="tab"
                        :class="{ active: advancedTab === 'DHCPOption82', 'tab-has-error': advancedDHCPOption82TabHasErrors }"
                        @click="setAdvancedTabTo('DHCPOption82')"
                      >
                        {{$t('wlans.dhcpOption82')}}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        role="tab"
                        :class="{ active: advancedTab === 'SpeedControl', 'tab-has-error': advancedSpeedControlTabHasErrors}"
                        @click="setAdvancedTabTo('SpeedControl')"
                      >
                        {{$t('wlans.speedControl')}}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        role="tab"
                        :class="{ active: advancedTab === 'MobilitySettings', 'tab-has-error': advancedMobilitySettingsHasErrors}"
                        @click="setAdvancedTabTo('MobilitySettings')"
                      >
                        {{$t('wlans.mobility')}}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        role="tab"
                        :class="{ active: advancedTab === 'WMM', 'tab-has-error': advancedWMMHasErrors}"
                        @click="setAdvancedTabTo('WMM')"
                      >
                        {{$t('wlans.WMM')}}
                      </a>
                    </li>
                  </ul>


                  <div class="mt-1 pl-1 pr-1" v-show="advancedTab === 'DataTrafficManagement'">
                    <div class="d-flex flex-row">
                      <div :class="{'w-50': showDataTrafficManagementTabRightColumn,
                      'w-100': !showDataTrafficManagementTabRightColumn}">
                        <transition name="wsettings">
                        <div :class="{'mr-3': showDataTrafficManagementTabRightColumn}">
                          <div>
                            <!--                          local Switching-->
                            <Switch-component
                              v-model="dataTrafficManagementBlocksShow.localSwitching"
                              :disabled="isDisable || dataTrafficManagementBlocksShow.localSwitching === true"
                              :label="$t('wlans.localSwitching')"
                              @change="dataTrafficManagementSwitchersHandler"
                              id="localSwitching-switcher"
                            />
                          </div>
                          <transition name="wsettings">
                          <div class="ml-2" v-if="dataTrafficManagementBlocksShow.localSwitching">
                            <div class="row mt-h">
                              <div class="form-group col-sm-6 mb-0" v-if="isNATBlockShowingDependingOnTheBuild">
                                <Switch-component
                                  v-model="updatedWlan.nat"
                                  :disabled="isDisable"
                                  @input="$validator.reset()"
                                  :label="'NAT'"
                                  id="nat"
                                  @change="checkNat"
                                />
                              </div>
                            </div>
                            <div
                              v-if="updatedWlan.nat === true && updatedWlan.hasOwnProperty('nat_network') && isNATBlockShowingDependingOnTheBuild">
                              <span class="text-muted">{{ $t('wlans.natNetworkInfoMsg') }}</span>
                              <div class="form-group mt-1" v-show="updatedWlan.nat === true">
                                <label for="nat_network_ipaddr">
                                  {{ $t('wlans.natNetworkIpaddr') }}
                                </label>
                                <input
                                  type="text"
                                  :key="'nat_network_ipaddr'"
                                  id="nat_network_ipaddr"
                                  autocomplete="off"
                                  name="nat_network_ipaddr"
                                  v-validate="'ipv4ForNatNetwork'"
                                  data-vv-validate-on="change"
                                  :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"
                                  :placeholder="$t('wlans.natNetworkIpaddrPlaceholder')"
                                  class="form-control"
                                  v-model="updatedWlan.nat_network.ipaddr"
                                  :disabled="isDisable"
                                />
                                <span v-show="errors.has('nat_network_ipaddr')"
                                      class="help is-danger">
                                  {{ errors.first('nat_network_ipaddr') }}
                                </span>
                              </div>
                              <div class="form-group mt-1 mb-2" v-show="updatedWlan.nat === true">
                                <label for="nat_network_netmask">
                                  {{ $t('wlans.natNetworkNetmask') }}
                                </label>
                                <input
                                  type="text"
                                  :key="'nat_network_netmask'"
                                  id="nat_network_netmask"
                                  autocomplete="off"
                                  name="nat_network_netmask"
                                  v-validate="'maskForNatNetwork'"
                                  data-vv-validate-on="change"
                                  :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"
                                  :placeholder="$t('wlans.natNetworkNetmaskPlaceholder')"
                                  class="form-control"
                                  v-model="updatedWlan.nat_network.netmask"
                                  :disabled="isDisable"
                                />
                                <span v-show="errors.has('nat_network_netmask')"
                                      class="help is-danger">
                                  {{ errors.first('nat_network_netmask') }}
                                </span>
                              </div>
                            </div>

                            <div v-if="updatedWlan.nat && isNATBlockShowingDependingOnTheBuild">
                              <div class="row mt-2">
                                <div class="form-group col-sm-6 mb-0">
                                  <Switch-component
                                    v-model="updatedWlan.firewall.nat_access"
                                    :disabled="isDisable"
                                    :label="$t('wlans.SSHWebAccess')"
                                    @input="$validator.reset()"
                                    id="nat-access"
                                  />
                                </div>
                              </div>
                            </div>


                            <div class="form-group mt-1">
                              <label for="vlan">
                                VLAN
                                <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>
                              </label>
                              <input
                                type="text"
                                id="vlan"
                                name="vlan"
                                :class="{ input: true, 'is-danger': errors.has('vlan') }"
                                v-validate="vlanFieldValidtaionRules"
                                data-vv-validate-on="none"
                                @input="vlanForLocalSwitchingInputHandler"
                                :placeholder="$t('wlans.enterVLANPlaceHolder')"
                                class="form-control"
                                v-model="updatedWlan.vlan"
                                :disabled="isDisable || isNatActive"
                              />
                              <span v-show="errors.has('vlan')" class="help is-danger">
                                  {{ errors.first('vlan') }}
                                </span>
                            </div>

                            <div class="form-group" v-if="showDynamicVLANInputField">
                              <!-- DynamicVLAN field -->
                              <WInput md :label="$t('wlans.DynamicVLAN')"
                                      @input="enableSave()"
                                      value=""
                                      :disabled="isDisable || isNatActive"
                                      id="DynamicVLAN"
                                      v-validate="'required|dynamicVLANFieldValidator'"
                                      name="DynamicVLAN"
                                      :data-vv-as="$t('wlans.DynamicVLAN')"
                                      v-model="dynamicVlansString"
                                      :errorText="errors.first('DynamicVLAN')"
                                      :placeholder="$t('wlans.DynamicVLANPlaceholder')">
                              </WInput>
                              <!-- end DynamicVLAN field -->
                            </div>
                          </div>
                          </transition>

                          <div>
                            <!--                          central Switching-->
                            <Switch-component
                              v-model="dataTrafficManagementBlocksShow.centralSwitching"
                              :disabled="isDisable || dataTrafficManagementBlocksShow.centralSwitching === true"
                              :label="$t('wlans.centralSwitching')"
                               @change="dataTrafficManagementSwitchersHandler"
                              id="centralSwitching-switcher"
                            />
                          </div>
                          <transition name="wsettings">
                          <div class="ml-1" v-if="dataTrafficManagementBlocksShow.centralSwitching">
                            <div v-if="!updatedWlan.nat">
<!--                              <div class="row " v-if="">-->
<!--                                <div class="form-group col-sm-6 mb-0">-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.tunneling"-->
<!--                                    :disabled="isDisable || isNatActive"-->
<!--                                    :label="$t('wlans.centralized')"-->
<!--                                    @change="changeTunnelingSelector"-->
<!--                                    @input="$validator.reset()"-->
<!--                                    id="tunneling"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
                              <!--                              Proto fields for EoGRE -->
<!--                              <div-->
<!--                                class="form-group mt-1 mb-1"-->
<!--                                v-if="updatedWlan.tunneling  && updatedWlan.hasOwnProperty('proto')"-->
<!--                              >-->
<!--                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>-->
<!--                                <select-->
<!--                                   :disabled="true"-->
<!--                                  @change="changeProtoInSelector"-->
<!--                                  class="form-control select-disabled"-->
<!--                                  name="proto"-->
<!--                                  v-model="updatedWlan.proto"-->
<!--                                  id="proto"-->
<!--                                  :class="{-->
<!--                                    input: true,-->
<!--                                    'select-disabled': !updatedWlan.tunneling-->
<!--                                  }"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;-->
<!--                                  <option :value="proto.value" :key="proto.value"-->
<!--                                          v-for="proto in typesOfProto">-->
<!--                                    {{ proto.name }}-->
<!--                                  </option>-->
<!--                                </select>-->
<!--                              </div>-->

                              <div
                                class="form-group mb-2 mt-h"
                                v-if="
                                  hostsList[0] &&
                                  interfacesWithDetailsFromAllHostsAsArray.length &&
                                  updatedWlan.tunneling &&
                                  updatedWlan.proto !== 'gretap' && showInterfaceSelectorInCentralSwitchingSettingsDependingOnBuild
                                "
                              >
                                <label for="default_tunnel" class="">{{ $t('wlans.interface')
                                  }}</label>

                                <Multiselect
                                  label="nameWithType"
                                  trackBy="name"
                                  v-model="updatedWlanDefaultTunnel"
                                  :multiple="false"
                                  :options="interfacesWithDetailsFromAllHostsAsArray ||  []"
                                  :closeOnSelect="true"
                                  :hideSelected="true"
                                  v-validate="'required|multiSelectWithInterfaceNotEmpty'"
                                  :data-vv-as="$t('wlans.interface')"
                                  id="default_tunnel"
                                  data-vv-name="Interface"
                                  :placeholder="$t('wlans.selectSingleInterface')"
                                  :selectLabel="$t('aps.wlansSelectorTip')"
                                  :disabled="!updatedWlan.tunneling || isDisable"
                                  @input="selectWlanDefaultTunnel"
                                  :class="{ 'multiselect-disabled': !updatedWlan.tunneling || isDisable}"
                                  :allowEmpty="true"
                                >
                                  <template v-slot:noOptions>
                                    {{ $t('general.noDataToDisplay') }}
                                  </template>
                                  <!--                                  <template v-slot:singleLabel>-->
                                  <!--                                    {{labelForSelectedInTunnelMiltiselect()}}-->
                                  <!--                                  </template>-->
                                  <template v-slot:noResult>
                                    {{ $t('general.noResultForSearch') }}
                                  </template>
                                </Multiselect>
                                <a v-if="!(!updatedWlan.tunneling || isDisable)" href="#"
                                   class="ml-q "
                                   @click.prevent="clearDefaultTunnel">
                                  <span>{{ $t('general.clear') }}</span>
                                </a>

                                <span v-show="errors.has('Interface')" class="help is-danger">
                                  {{ errors.first('Interface') }}
                                </span>
                                <small v-show="updatedWlan.tunneling" class="help is-danger">
                                  {{ $t('wlans.bridge') }}
                                </small>
                              </div>

                              <div
                                v-else-if="(!interfacesWithDetailsFromAllHostsAsArray || !interfacesWithDetailsFromAllHostsAsArray.length) && isRootUser && showInterfaceSelectorInCentralSwitchingSettingsDependingOnBuild">
                                {{ $t('wlans.noInterfaces') }}
                              </div>

                              <div class="form-group mt-1">
                                <label for="vlan">
                                  VLAN
                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>
                                </label>
                                <input
                                  type="text"
                                  id="vlan"
                                  name="vlan"
                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"
                                  v-validate="vlanFieldValidtaionRules"
                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"
                                  class="form-control"
                                  v-model="updatedWlan.vlan"
                                  :disabled="isDisable || isNatActive"
                                />
                                <span v-show="errors.has('vlan')" class="help is-danger">
                                  {{ errors.first('vlan') }}
                                </span>
                              </div>

                            <div class="form-group" v-if="showDynamicVLANInputField">
                              <!-- DynamicVLAN field -->
                              <WInput md :label="$t('wlans.DynamicVLAN')"
                                      @input="enableSave()"
                                      value=""
                                      :disabled="isDisable || isNatActive"
                                      id="DynamicVLAN"
                                      v-validate="'required|dynamicVLANFieldValidator'"
                                      name="DynamicVLAN"
                                      :data-vv-as="$t('wlans.DynamicVLAN')"
                                      v-model="dynamicVlansString"
                                      :errorText="errors.first('DynamicVLAN')"
                                      :placeholder="$t('wlans.DynamicVLANPlaceholder')">
                              </WInput>
                              <!-- end DynamicVLAN field -->
                            </div>

                            </div>


                          </div>
                          </transition>

                          <div>
                            <!--                          tunneling-->
                            <Switch-component
                              v-if="isTunnelingBlockShowingDependingOnTheBuild"
                              v-model="dataTrafficManagementBlocksShow.tunneling"
                              :disabled="isDisable || dataTrafficManagementBlocksShow.tunneling === true"
                              :label="$t('wlans.tunnelingShort')"
                               @change="dataTrafficManagementSwitchersHandler"
                              id="tunneling-switcher"
                            />
                          </div>
                          <transition name="wsettings">
                          <div class="ml-1" v-if="dataTrafficManagementBlocksShow.tunneling && isTunnelingBlockShowingDependingOnTheBuild">
                            <div v-if="!updatedWlan.nat">
<!--                              <div class="row " v-if="">-->
<!--                                <div class="form-group col-sm-6 mb-0">-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.tunneling"-->
<!--                                    :disabled="isDisable || isNatActive"-->
<!--                                    :label="$t('wlans.centralized')"-->
<!--                                    @change="changeTunnelingSelector"-->
<!--                                    @input="$validator.reset()"-->
<!--                                    id="tunneling"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
                              <!--                              Proto fields for EoGRE -->
<!--                              <div-->
<!--                                class="form-group mt-1 mb-1"-->
<!--                                v-if="updatedWlan.tunneling  && updatedWlan.hasOwnProperty('proto')"-->
<!--                              >-->
<!--                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>-->
<!--                                <select-->
<!--                                  :disabled="true"-->
<!--                                  @change="changeProtoInSelector"-->
<!--                                  class="form-control select-disabled"-->
<!--                                  name="proto"-->
<!--                                  v-model="updatedWlan.proto"-->
<!--                                  id="proto"-->
<!--                                  :class="{-->
<!--                                    input: true,-->
<!--                                    'select-disabled': !updatedWlan.tunneling-->
<!--                                  }"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;-->
<!--                                  <option :value="proto.value" :key="proto.value"-->
<!--                                          v-for="proto in typesOfProto">-->
<!--                                    {{ proto.name }}-->
<!--                                  </option>-->
<!--                                </select>-->
<!--                              </div>-->

<!--                              для будущего выбора протоколов при туннелировании-->
                              <div
                                class="form-group mt-1 mb-1"
                                v-if="updatedWlan.tunneling  && updatedWlan.hasOwnProperty('proto')"
                              >
                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>
                                <select
                                  :disabled="isDisable"
                                  @change="changeProtoInSelectorInTunnelingBlock"
                                  class="form-control"
                                  name="proto"
                                  v-model="updatedWlan.proto"
                                  id="proto"
                                  :class="{
                                    input: true,
                                    'select-disabled': !updatedWlan.tunneling || isDisable
                                  }"
                                >
                                  <!--                                  <option value="" disabled selected></option>-->
                                  <option :value="proto.value" :key="proto.value"
                                          v-for="proto in typesOfProtoForTunnelingBlock">
                                    {{ proto.name }}
                                  </option>
                                </select>
                              </div>
                              <div
                                class="form-group mt-h"
                                v-if="updatedWlan.hasOwnProperty('peer_address') && updatedWlan.proto === 'gretap' && updatedWlan.tunneling"
                              >
                                <label for="peerAddress">
                                  {{ $t('wlans.peerAddress') }}
                                </label>
                                <input
                                  key="peerAddress"
                                  type="text"
                                  :data-vv-as="$t('wlans.peerAddress')"
                                  id="peerAddress"
                                  name="peerAddress"
                                  :class="{ input: true, 'is-danger': errors.has('peerAddress') }"
                                  v-validate="'required|addressForEoGREServer'"
                                  :placeholder="$t('wlans.peerAddressPlaceholder')"
                                  data-vv-validate-on="change"
                                  class="form-control"
                                  v-model="updatedWlan.peer_address"
                                  :disabled="isDisable || isNatActive"
                                />
                                <span v-show="errors.has('peerAddress')" class="help is-danger">
                                  {{ errors.first('peerAddress') }}
                                </span>
                              </div>


<!--                              <div class="form-group mt-1">-->
<!--                                <label for="vlan">-->
<!--                                  VLAN-->
<!--                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  id="vlan"-->
<!--                                  name="vlan"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                                  v-validate="'numeric|between:0,4094'"-->
<!--                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.vlan"-->
<!--                                  :disabled="isDisable || isNatActive"-->
<!--                                />-->
<!--                                <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                                  {{ errors.first('vlan') }}-->
<!--                                </span>-->
<!--                              </div>-->

<!--                              <div class="form-group mt-1">-->
<!--                                <label for="vlan">-->
<!--                                  VLAN-->
<!--                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  id="vlan"-->
<!--                                  name="vlan"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                                  v-validate="'required|numeric|between:0,4094'"-->
<!--                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.vlan"-->
<!--                                  :disabled="isDisable || isNatActive"-->
<!--                                />-->
<!--                                <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                                  {{ errors.first('vlan') }}-->
<!--                                </span>-->
<!--                              </div>-->

                              <div class="form-group mt-1">
                                <label for="vlan">
                                  VLAN
                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>
                                </label>
                                <input
                                  type="text"
                                  id="vlan"
                                  name="vlan"
                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"
                                  v-validate="vlanFieldValidtaionRules"
                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"
                                  class="form-control"
                                  v-model="updatedWlan.vlan"
                                  :disabled="isDisable"
                                />
                                <span v-show="errors.has('vlan')" class="help is-danger">
                                  {{ errors.first('vlan') }}
                                </span>
                              </div>

                            </div>


                          </div>
                          </transition>

<!--                          <div>-->
<!--                            &lt;!&ndash;                          dhcp option 82&ndash;&gt;-->
<!--                            <Switch-component-->
<!--                              v-model="dhcpOption82BlockShow"-->
<!--                              :disabled="isDisable"-->
<!--                              :label="$t('wlans.dhcpOption82')"-->
<!--                              @change="dhcpOption82SwitcherHandler"-->
<!--                              id="tunneling-switcher"-->
<!--                            />-->
<!--                          </div>-->


<!--                          <transition name="wsettings">-->
<!--                            <div v-if="dhcpOption82BlockShow">-->
<!--                              option 82 settings block-->

<!--                            </div>-->
<!--                          </transition>-->


                        </div>
                        </transition>
                      </div>

                      <div v-if="showDataTrafficManagementTabRightColumn" class="w-50">
                        <!-- ProxyARP switcher -->
                          <div
                            v-if="showProxyARPSwitcher">
                              <div>
                                <Switch-component
                                  v-model="updatedWlan.proxy_arp"
                                  :disabled="isDisable"
                                  :label="$t('wlans.proxyARP')"
                                  id="proxyARP"
                                />
                                <info class="ml-h" :content="$t('wlans.proxyARPHint')"/>
                              </div>
                          </div>
                          <!-- end ProxyARP switcher -->
                      </div>


                    </div>





                  </div>


                  <div class="mt-1 pl-1 pr-1" v-show="advancedTab === 'DHCPOption82'">

                                            <transition name="wsettings">
                          <div @click.stop="" v-if="true">
                            <!--                          dhcp option 82-->
                            <Switch-component
                              v-model="dhcpOption82Enabled"
                              :disabled="isDisable"
                              :label="$t('wlans.dhcpOption82Enable')"
                              @input="dhcpOption82EnableSwitcherHandler"
                              id="option82-switcher"
                            />
<!--                            <info class="ml-h" :content="$t('wlans.dhcpOption82InfoHint')"/>-->
                            <div>
                              <!--                              <div class="ml-1 mb-h h6">{{$t('general.format')}}:</div>-->
                              <!--                            dhcp option 82  Rid Type-->
                              <Option82RidTypeSelector
                                @selectOption82RidType="selectOption82RidTypeHandler"
                                @selectOption82CidType="selectOption82CidTypeHandler"
                                :updated-wlan="updatedWlan"
                                :is-disable="isDisable || !dhcpOption82Enabled">
                              </Option82RidTypeSelector>
                            </div>
                          </div>
                        </transition>


                  </div>

                  <div class="mt-1 pl-1 pr-1" v-show="advancedTab === 'SpeedControl'">

                                            <transition name="wsettings">
<!--                          <div v-if="isSpeedControlShow" class="mt-1">-->
                              <div v-if="true" class="mt-1">
                            <div class="form-group">
                              <label for="speed_download">
                                 {{ $t('wlans.speedDownload') }} ({{ $t('wlans.speedDownloadKbs')}})
                              </label>
                              <input
                                type="text"
                                id="speed_download"
                                :data-vv-as="$t('wlans.speedDownload')"
                                v-validate="'between: 8,10000000'"
                                name="speed_download"
                                :placeholder="$t('wlans.speedDownloadPlaceholder')"
                                class="form-control"
                                v-model.number="updatedWlan.speed_download.value"
                                :class="{ input: true, 'is-danger': errors.has('speed_download') }"
                                :disabled="isDisable"
                              />
                              <span v-show="errors.has('speed_download')" class="help is-danger">
                                  {{ errors.first('speed_download') }}
                              </span>
                            </div>

                            <div class="form-group">
                              <label for="speed_upload">
                                {{ $t('wlans.speedUpload') }} ({{ $t('wlans.speedUploadKbs') }})
                              </label>
                              <input
                                type="text"
                                :class="{ input: true, 'is-danger': errors.has('speed_upload') }"
                                :data-vv-as="$t('wlans.speedUpload')"
                                v-validate="'between: 8,10000000'"
                                id="speed_upload"
                                name="speed_upload"
                                :placeholder="$t('wlans.speedUploadPlaceholder')"
                                class="form-control"
                                v-model.number="updatedWlan.speed_upload.value"
                                :disabled="isDisable"
                              />
                              <span v-show="errors.has('speed_upload')" class="help is-danger">
                                  {{ errors.first('speed_upload') }}
                              </span>
                            </div>
                          </div>
                        </transition>


                  </div>

                  <div class="mt-1 pl-1 pr-1" v-show="advancedTab === 'MobilitySettings'">
                                            <transition name="wsettings">
<!--                          <div class="" v-if="isMobilityShow">-->
                            <div class="" v-if="true">
                            <div class="row mt-1">
                              <div class="form-group col-sm-12" :class="{ 'mb-h': updatedWlan.roam80211r === true }">
                                <Switch-component
                                  v-tooltip.auto="{ content: `${$t('wlans.notForOpen')}` }"
                                  v-model="updatedWlan.roam80211r"
                                  :disabled="isDisable || updatedWlan.security.type == 'open'"
                                  :label="$t('wlans.fastTransition')"
                                  id="roam80211r"
                                  @input="roam80211rSwitcherChange"
                                />
                              </div>
                            </div>
                            <!--                            -->
                            <transition name="wsettings">
                              <div
                                class="row subswitchers-warpper-in-mobility-part"
                                v-if="updatedWlan.hasOwnProperty('roam80211r') && updatedWlan.roam80211r === true"
                              >
                                <!--                              ft_over_ds ft over ds-->
                                <div
                                  class="form-group col-sm-12 mb-h ml-0 pl-0"
                                  v-if="updatedWlan.hasOwnProperty('ft_over_ds')"
                                >
                                  <Switch-component
                                    v-model="updatedWlan.ft_over_ds"
                                    :disabled="isDisable || updatedWlan.security.type == 'open'"
                                    :label="$t('wlans.overTheDS')"
                                    id="ft_over_ds"
                                  />
                                </div>
                                <!--                                nas_generate nas generate-->
                                <div
                                  class="form-group col-sm-12 mb-h ml-0 pl-0"
                                  v-if="isGenerateNASIDSettingShowingDependingOnTheBuild && updatedWlan.hasOwnProperty('nas_generate')"
                                >
                                  <Switch-component
                                    v-model="updatedWlan.nas_generate"
                                    :disabled="isDisable || updatedWlan.security.type == 'open'"
                                    :label="$t('wlans.nas_generateCaption')"
                                    id="nas_generate"
                                  />
                                  <info class="ml-q" :content="$t('wlans.nas_generateInfo')" />
                                </div>
                                <!-- localPMKGeneration-->
                                <div
                                  v-if="isLocalPMKGenerationShowingDependingOnTheBuild"
                                  class="form-group col-sm-12 mb-h ml-0 pl-0"
                                >
                                  <Switch-component
                                     v-model="updatedWlan.ft_psk_generate_local"
                                    v-tooltip.auto="{ content: updatedWlan.security.type !== 'wpa2personal' ? `${$t('wlans.localPMKGenerationOnlyForWP2PSK')}` : undefined }"
                                    :disabled="isDisable || updatedWlan.security.type !== 'wpa2personal'"
                                    :label="$t('wlans.localPMKGeneration')"
                                    id="localPMKGeneration"
                                  />
                                </div>
                                <!--   experimentalFTImprovements-->
                                <div
                                  v-if="isExperimentalFTImprovementsAndChildrenShowingDependingOnTheBuild"
                                  class="form-group col-sm-12 mb-h ml-0 pl-0"
                                >
                                  <Switch-component
                                     v-model="experimentalFTImprovements"
                                    :disabled="isDisable"
                                    :label="$t('wlans.experimentalFTImprovements')"
                                    id="experimentalFTImprovements"
                                     @input="experimentalFTImprovementsChangeHandler"
                                  />
                                </div>
                                <transition name="wsettings">
                                  <div v-if="isExperimentalFTImprovementsAndChildrenShowingDependingOnTheBuild && isExperimentalFTImprovementsBlockShowing"
                                       class="row subswitchers-warpper-in-mobility-part mb-1">
                                    <!--   RRBTunneling-->
                                    <div
                                      class="form-group col-sm-12 mb-h ml-0 pl-0"
                                    >
                                      <Switch-component
                                        v-model="updatedWlan.rrb_tunnelling"
                                        :disabled="isDisable"
                                        :label="$t('wlans.RRBTunneling')"
                                        id="RRBTunneling"
                                        @input="rrbTunnelingHandler"
                                      />
                                    </div>
                                    <div
                                      class="form-group col-sm-12 mb-h ml-0 pl-0"
                                    >
                                      <!--   RRBSeqcheck-->
                                      <Switch-component
                                         v-model="updatedWlan.rrb_use_seq"
                                        :disabled="isDisable"
                                        :label="$t('wlans.RRBSeqcheck')"
                                        id="RRBSeqcheck"
                                         @input="rrbSeqcheckHandler"

                                      />
                                    </div>
                                    <!--
                                      directRRBExchange пока не реализовано на бэке. Поэтому функционал скрыт
                                      подробнее https://gitlab.wimark.com/frontend/wimark-web-client/-/merge_requests/516#f5d8f27100cf2c4efa25e83595747a046e3ed2d9
                                    -->
                                    <div
                                      v-if="false"
                                      class="form-group col-sm-12 mb-h ml-0 pl-0"
                                    >
                                      <!--   directRRBExchange-->
                                      <Switch-component
                                        v-model="directRRBExchange"
                                        :disabled="isDisable"
                                        :label="$t('wlans.directRRBExchange')"
                                        id="directRRBExchange"
                                        @input="directRRBExchangeHandler"
                                      />
                                      <info class="ml-h" :content="$t('wlans.directRRBExchangeHint')"/>
                                    </div>
                                    <transition name="wsettings">
                                      <div class="w-50 cpe-multiselect-warpper-in-mobility-part"
                                           v-if="isAPForRRBExchangeShowing">
                                        <!--   directRRBExchange APs -->
                                        <Multiselect
                                          :deselectLabel="$t('general.multiSelectDeselectLabel')"
                                          :selectedLabel="$t('general.multiSelectSelected')"
                                          label="name"
                                          trackBy="id"
                                          :max="20"
                                          :loading="loadingDataForApsForDirectRRBExchange"
                                          :options="apsForDirectRRBExchangeOptions || []"
                                          v-model="updatedWlanApsForDirectRRBExchange"
                                          :multiple="true"
                                          :hideSelected="false"
                                          :closeOnSelect="false"
                                          :clearOnSelect="false"
                                          id="APForRRBExchange"
                                          :data-vv-as="$t('wlans.selectApsForDirectRRBExchange')"
                                          name="APForRRBExchange"
                                          :placeholder="$t('wlans.selectApsForDirectRRBExchange')"
                                          :selectLabel="$t('wlans.selectApsForDirectRRBExchange')"
                                          v-validate="'required'"
                                          :disabled="isDisable"
                                          :class="{ 'multiselect-disabled': isDisable, 'is-danger': errors.has('APForRRBExchange')}"
                                          :allowEmpty="true"
                                          @search-change="searchCpeWhenSearchChangeHandler"
                                          @open="searchCpeWhenOpenSelectorListHandler"
                                        >
                                          <template v-slot:noOptions>
                                            {{ $t('general.noOptionsForSearch') }}
                                          </template>
                                          <template v-slot:noResult>
                                            {{ $t('general.noResultForSearch') }}
                                          </template>
                                          <template v-slot:maxElements>
                                            {{ $t('wlans.selectApsForDirectRRBExchangeHint') }}
                                          </template>
                                        </Multiselect>
                                        <span v-show="errors.has('APForRRBExchange')"
                                              class="help is-danger mt-0 mb-1">
                                {{ errors.first('APForRRBExchange') }}
                              </span>
                                        <small>{{$t('wlans.selectApsForDirectRRBExchangeHint')}}</small>
                                      </div>
                                    </transition>
                                  </div>
                                </transition>
                              </div>
                            </transition>

                            <div class="row">
                              <div
                                class="form-group col-sm-12"
                                v-if="updatedWlan.security.type.indexOf('enterprise') == -1"
                              >
                                <Switch-component
                                  v-model="updatedWlan.pmkcaching"
                                  v-tooltip.auto="{ content: `${$t('wlans.onlyForWPAEnterprise')}` }"
                                  :disabled="isDisable || updatedWlan.security.type.indexOf('enterprise') == -1"
                                  :label="$t('wlans.PMKcaching')"
                                  id="pmkcaching_wpae"
                                />
                              </div>
                              <div class="form-group col-sm-12" v-else>
                                <Switch-component
                                  v-model="updatedWlan.pmkcaching"
                                  :disabled="isDisable || updatedWlan.security.type.indexOf('enterprise') == -1"
                                  :label="$t('wlans.PMKcaching')"
                                  id="pmkcaching"
                                />
                              </div>
                            </div>

                            <!--                            ieee80211k-->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('ieee80211k')">
                              <div class="form-group col-sm-12" :class="{ 'mb-h': updatedWlan.ieee80211k === true }">
                                <Switch-component
                                  v-model="updatedWlan.ieee80211k"
                                  :disabled="isDisable"
                                  :label="$t('wlans.ieee80211k')"
                                  id="ieee80211k"
                                  @input="ieee80211kSwitcherChange"
                                />
                              </div>
                            </div>
                            <transition name="wsettings">
                              <div
                                class="row subswitchers-warpper-in-mobility-part"
                                v-if="updatedWlan.hasOwnProperty('ieee80211k') && updatedWlan.ieee80211k === true"
                              >
                                <!--                              rrm neighbor report-->
                                <div
                                  class="form-group col-sm-12 mb-h ml-0 pl-0"
                                  v-if="updatedWlan.hasOwnProperty('rrm_neighbor_report')"
                                >
                                  <Switch-component
                                    v-model="updatedWlan.rrm_neighbor_report"
                                    :disabled="isDisable"
                                    :label="$t('wlans.rrm_neighbor_report')"
                                    id="rrm_neighbor_report"
                                  />
                                </div>
                                <div
                                  class="form-group col-sm-12 ml-0 pl-0"
                                  v-if="updatedWlan.hasOwnProperty('rrm_beacon_report')"
                                >
                                  <!--                                rrm beacon report-->
                                  <Switch-component
                                    v-model="updatedWlan.rrm_beacon_report"
                                    :disabled="isDisable"
                                    :label="$t('wlans.rrm_beacon_report')"
                                    id="rrm_beacon_report"
                                  />
                                </div>
                              </div>
                            </transition>

                            <!--                            ieee80211v-->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('ieee80211v')">
                              <div class="form-group col-sm-12" :class="{ 'mb-h': updatedWlan.ieee80211v === true }">
                                <Switch-component
                                  v-model="updatedWlan.ieee80211v"
                                  :disabled="isDisable"
                                  :label="$t('wlans.ieee80211v')"
                                  id="ieee80211v"
                                />
                              </div>
                            </div>
                            <transition name="wsettings">
                              <div
                                class="row subswitchers-warpper-in-mobility-part"
                                v-if="updatedWlan.hasOwnProperty('ieee80211v') && updatedWlan.ieee80211v === true"
                              >
                                <!--                              wnm sleep mode-->
                                <div
                                  class="form-group col-sm-12 ml-0 pl-0 mb-h"
                                  v-if="updatedWlan.hasOwnProperty('wnm_sleep_mode')"
                                >
                                  <Switch-component
                                    v-model="updatedWlan.wnm_sleep_mode"
                                    :disabled="isDisable"
                                    :label="$t('wlans.wnm_sleep_mode')"
                                    id="rrm_neighbor_report"
                                  />
                                  <info class="info-tooltip-in-wlans-wmm" :content="$t('wlans.wnm_sleep_modeInfo')" />
                                </div>
                                <div
                                  class="form-group col-sm-12 ml-0 pl-0"
                                  v-if="updatedWlan.hasOwnProperty('bss_transition')"
                                >
                                  <!--                                bss transition-->
                                  <Switch-component
                                    v-model="updatedWlan.bss_transition"
                                    :disabled="isDisable"
                                    :label="$t('wlans.bss_transition')"
                                    id="bss_transition"
                                  />
                                </div>
                              </div>
                            </transition>
                            <!--                            RSSI threshold rssi_threshold-->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('rssi_threshold')">
                              <div class="form-group col-sm-12">
                                <!--                                <Switch-component-->
                                <!--                                  v-model="updatedWlan.rssi_threshold"-->
                                <!--                                  :disabled="isDisable"-->
                                <!--                                  label="rssi_threshold"-->
                                <!--                                  id="rssi_threshold"-->
                                <!--                                />-->
                                <label for="rssi_threshold">
                                  {{ $t('wlans.rssiThreshold') }}
                                  <info class="" :content="$t('wlans.rssiThresholdHintForInfo')" />
                                  <small class="ml-q">({{ $t('wlans.rssiThresholdHintRange') }})</small>
                                </label>
                                <input
                                  type="number"
                                  id="rssi_threshold"
                                  name="rssi_threshold"
                                  :placeholder="$t('wlans.rssiThresholdPlaceholder')"
                                  class="form-control"
                                  v-validate="'required|integer|rssiThresholdValueForWlan'"
                                  v-model.number="updatedWlan.rssi_threshold"
                                  :disabled="isDisable"
                                  :data-vv-as="$t('wlans.rssiThreshold')"
                                />
                                <span v-show="errors.has('rssi_threshold')" class="help is-danger">
                                  {{ errors.first('rssi_threshold') }}
                                </span>
                              </div>
                            </div>

                            <!--                            Signal caption-->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_stay')">
                              <div class="form-group col-sm-12 mb-q">
                                <span>{{ $t('wlans.signalBlockCaption') }}:</span>
                              </div>
                            </div>

                            <!--                               Signal Stay  signal_stay -->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_stay')">
                              <div class="form-group col-sm-12">
                                <label for="signal_stay">
                                  {{ $t('wlans.signalStay') }}
                                  <info class="" :content="$t('wlans.signalStayForInfo')" />
                                  <small class="ml-q">({{ $t('wlans.signalStayHint') }})</small>
                                </label>
                                <input
                                  type="number"
                                  id="signal_stay"
                                  name="signal_stay"
                                  :placeholder="$t('wlans.signalStayPlaceholder')"
                                  class="form-control"
                                  v-validate="'required|integer|signalStayValueForWlan'"
                                  v-model.number="updatedWlan.signal_stay"
                                  :disabled="isDisable"
                                  :data-vv-as="$t('wlans.signalStay')"
                                />
                                <span v-show="errors.has('signal_stay')" class="help is-danger">
                                  {{ errors.first('signal_stay') }}
                                </span>
                              </div>
                            </div>

                            <!--                               Signal Strikes signal_strikes -->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_strikes')">
                              <div class="form-group col-sm-12">
                                <label for="signal_strikes">
                                  {{ $t('wlans.signalStrikes') }}
                                  <info class="" :content="$t('wlans.signalStrikesForInfo')" />
                                  <small class="ml-q">({{ $t('wlans.signalStrikesHint') }})</small>
                                </label>
                                <input
                                  type="number"
                                  id="signal_strikes"
                                  name="signal_strikes"
                                  :placeholder="$t('wlans.signalStrikesPlaceholder')"
                                  class="form-control"
                                  v-validate="'integer|signalStrikesValueForWlan'"
                                  v-model.number="updatedWlan.signal_strikes"
                                  :disabled="isDisable"
                                  :data-vv-as="$t('wlans.signalStrikes')"
                                />
                                <span v-show="errors.has('signal_strikes')" class="help is-danger">
                                  {{ errors.first('signal_strikes') }}
                                </span>
                              </div>
                            </div>

                            <!--                               signal Poll Time signal_poll_time -->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_strikes')">
                              <div class="form-group col-sm-12">
                                <label for="signal_poll_time">
                                  {{ $t('wlans.signalPollTime') }}
                                  <!--                                  <info class=""-->
                                  <!--                                        :content="$t('wlans.signalPollTimeForInfo')"/>-->
                                  <small class="ml-q">({{ $t('wlans.signalPollTimeHint') }})</small>
                                </label>
                                <input
                                  type="number"
                                  id="signal_poll_time"
                                  name="signal_poll_time"
                                  :placeholder="$t('wlans.signalPollTimePlaceholder')"
                                  class="form-control"
                                  v-validate="'integer|signalPollValueForWlan'"
                                  v-model.number="updatedWlan.signal_poll_time"
                                  :disabled="isDisable"
                                  :data-vv-as="$t('wlans.signalPollTime')"
                                />
                                <span v-show="errors.has('signal_poll_time')" class="help is-danger">
                                  {{ errors.first('signal_poll_time') }}
                                </span>
                              </div>
                            </div>

                            <!--                               signal Drop Reason signal_drop_reason -->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_drop_reason')">
                              <div class="form-group col-sm-12">
                                <label for="signal_drop_reason">
                                  {{ $t('wlans.signalDropReason') }}
                                  <info
                                    @clicked="showDropReasonModal"
                                    class=""
                                    :content="$t('wlans.signalDropReasonForInfo')"
                                  />
                                  <small class="ml-q">
                                    ({{ $t('wlans.signalDropReasonHint') }}.
                                    <span
                                      style="text-decoration: underline;"
                                      @click="showDropReasonModal"
                                      role="button"
                                    >
                                      {{ $t('wlans.signalDropReasonMoreInfo') }})
                                    </span>
                                  </small>
                                </label>
                                <input
                                  type="number"
                                  id="signal_drop_reason"
                                  name="signal_drop_reason"
                                  :placeholder="$t('wlans.signalDropReasonPlaceholder')"
                                  class="form-control"
                                  v-validate="'integer|signalDropReasonForWlan'"
                                  v-model.number="updatedWlan.signal_drop_reason"
                                  :disabled="isDisable"
                                  :data-vv-as="$t('wlans.signalDropReason')"
                                />
                                <span v-show="errors.has('signal_drop_reason')" class="help is-danger">
                                  {{ errors.first('signal_drop_reason') }}
                                </span>
                              </div>
                            </div>

                            <!--                     Band Steering band_steering-->
                            <div class="row" v-if="updatedWlan.hasOwnProperty('band_steering')">
                              <div class="form-group col-sm-12 mt-h">
                                <Switch-component
                                  v-model="updatedWlan.band_steering"
                                  :disabled="isDisable"
                                  :label="$t('wlans.bandSteering')"
                                  id="band_steering"
                                />
                              </div>
                            </div>

<!--                            &lt;!&ndash; Load Balancing load_balancing &ndash;&gt;-->
<!--                            <div class="row">-->
<!--                              <div class="form-group col-sm-12 mt-h">-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.load_balancing"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :label="$t('wlans.loadBalancing')"-->
<!--                                  id="load_balancing"-->
<!--                                />-->
<!--                                <info class="ml-q" :content="$t('wlans.loadBalancingInfoMsg')" />-->
<!--                              </div>-->
<!--                            </div>-->
                            <!-- Load Balancing load_balancing -->
                            <div class="row" v-show="isShowLoadBalancingSwitcher">
                              <div class="form-group col-sm-12 mt-h">
                                <Switch-component
                                  v-model="updatedWlan.load_balancing"
                                  :disabled="isDisable"
                                  :label="$t('wlans.loadBalancing')"
                                  id="load_balancing"
                                />
                                <info class="ml-q" :content="$t('wlans.loadBalancingInfoMsg')" />
                              </div>
                            </div>
                          </div>
                        </transition>


                  </div>

                  <div class="mt-1 pl-1 pr-1" v-show="advancedTab === 'WMM'">

                                            <transition name="wsettings">
<!--                                                <div class="mb-1" v-if="isWMMShow">-->
                          <div class="mb-1" v-if="true">
                            <WMM
                              @wmm-validation-errors-changed="wmmValidationErrorsChangedHandler"
                              :updatedWlan="updatedWlan"
                              :isDisable="isDisable"
                              :wlanEditCurrent="wlanEditCurrent"
                              :isBackgroundShow="isBackgroundShow"
                              :isBestEffortShow="isBestEffortShow"
                              :isVideoShow="isVideoShow"
                              :isVoiceShow="isVoiceShow"
                            ></WMM>
<!--                            <div class="mb-1">-->
<!--                              <info-->
<!--                                @clicked="showWMMInfoDetails"-->
<!--                                class="info-tooltip-in-wlans-wmm"-->
<!--                                :content="$t('wmm.infoTooltip')"-->
<!--                              />-->
<!--                              <span class="text-muted">{{ $t('wmm.infoTooltip') }}</span>-->
<!--                            </div>-->
                          </div>
                        </transition>


                  </div>









<!--                                        <div class="wlan-settings-block-for-modal mb-1">-->
<!--                        <div-->
<!--                          class="wlan-settings-block__header"-->
<!--                          @click.prevent="showTunnel"-->
<!--                          :class="{ 'mb-2': isTunnelShow }"-->
<!--                        >-->
<!--                          <div>-->
<!--&lt;!&ndash;                            <span class="h6 mr-1" :class="{ 'not-for-cisco': isCiscoModeEnabled }">&ndash;&gt;-->
<!--&lt;!&ndash;                              {{ $t('wlans.networkEncapsulation') }}&ndash;&gt;-->
<!--&lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                            <span class="h6 mr-1" :class="{ 'not-for-cisco': isCiscoModeEnabled }">-->
<!--                              {{ $t('wlans.dataTrafficManagement') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              v-if="getWlanDataTrafficManagementTypeFromWlanObject(wlanEditCurrent) === 'localSwitching'"-->
<!--                              class="badge mr-q badge-success">-->
<!--                              {{ $t('wlans.localSwitching') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              v-if="getWlanDataTrafficManagementTypeFromWlanObject(wlanEditCurrent) === 'centralSwitching'"-->
<!--                              class="badge mr-q badge-success">-->
<!--                              {{ $t('wlans.centralSwitching') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              v-if="getWlanDataTrafficManagementTypeFromWlanObject(wlanEditCurrent) === 'tunneling'"-->
<!--                              class="badge mr-q badge-success">-->
<!--                              {{ $t('wlans.tunnelingShort') }}-->
<!--                            </span>-->
<!--                            <span v-if="wlanEditCurrent.nat" class="badge mr-q badge-success">-->
<!--                              NAT: {{ $t('general.on') }}-->
<!--                            </span>-->
<!--                            <span v-else class="badge mr-q badge-default">NAT: {{ $t('general.off') }}</span>-->
<!--&lt;!&ndash;                            <span v-if="wlanEditCurrent.tunneling && isRootUser" class="badge mr-q badge-success">&ndash;&gt;-->
<!--&lt;!&ndash;                              {{ $t('wlans.centralized') }}: {{ $t('general.on') }}&ndash;&gt;-->
<!--&lt;!&ndash;                            </span>&ndash;&gt;-->
<!--&lt;!&ndash;                            <span v-if="!wlanEditCurrent.tunneling && isRootUser" class="badge mr-q badge-default">&ndash;&gt;-->
<!--&lt;!&ndash;                              {{ $t('wlans.centralized') }}: {{ $t('general.off') }}&ndash;&gt;-->
<!--&lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                            <span v-if="wlanEditCurrent.vlan" class="badge mr-q badge-success">-->
<!--                              VLAN: {{ wlanEditCurrent.vlan }}-->
<!--                            </span>-->
<!--                            <span v-else-if="!wlanEditCurrent.vlan" class="badge mr-q badge-default">-->
<!--                              VLAN: {{ $t('wlans.vlanNative') }}-->
<!--                            </span>-->
<!--                          </div>-->

<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn" v-if="!isTunnelShow">-->
<!--                            <i class="icon-arrow-left"></i>-->
<!--                          </button>-->
<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn" v-if="isTunnelShow">-->
<!--                            <i class="icon-arrow-down"></i>-->
<!--                          </button>-->
<!--                        </div>-->
<!--&lt;!&ndash;                        <transition name="wsettings">&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="" v-if="isTunnelShow">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="row mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                              <div class="form-group col-sm-6 mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.nat"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                                  @input="$validator.reset()"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :label="'NAT'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="nat"&ndash;&gt;-->
<!--&lt;!&ndash;                                  @change="checkNat"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-if="updatedWlan.nat === true && updatedWlan.hasOwnProperty('nat_network')">&ndash;&gt;-->
<!--&lt;!&ndash;                              <span class="text-muted">{{ $t('wlans.natNetworkInfoMsg') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                              <div class="form-group mt-1" v-show="updatedWlan.nat === true">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="nat_network_ipaddr">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ $t('wlans.natNetworkIpaddr') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                  type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :key="'nat_network_ipaddr'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="nat_network_ipaddr"&ndash;&gt;-->
<!--&lt;!&ndash;                                  autocomplete="off"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="nat_network_ipaddr"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'ipv4ForNatNetwork'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  data-vv-validate-on="change"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.natNetworkIpaddrPlaceholder')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.nat_network.ipaddr"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <span v-show="errors.has('nat_network_ipaddr')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('nat_network_ipaddr') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              <div class="form-group mt-1 mb-2" v-show="updatedWlan.nat === true">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="nat_network_netmask">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ $t('wlans.natNetworkNetmask') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                  type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :key="'nat_network_netmask'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="nat_network_netmask"&ndash;&gt;-->
<!--&lt;!&ndash;                                  autocomplete="off"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="nat_network_netmask"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'maskForNatNetwork'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  data-vv-validate-on="change"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.natNetworkNetmaskPlaceholder')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.nat_network.netmask"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <span v-show="errors.has('nat_network_netmask')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('nat_network_netmask') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->

<!--&lt;!&ndash;                            <div v-if="!updatedWlan.nat">&ndash;&gt;-->
<!--&lt;!&ndash;                              <div class="row mt-2" v-if="isRootUser">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="form-group col-sm-6 mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                  <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="updatedWlan.tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :label="$t('wlans.centralized')"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @change="changeTunnelingSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @input="$validator.reset()"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                                  />&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              &lt;!&ndash;                              Proto fields for EoGRE &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                              <div&ndash;&gt;-->
<!--&lt;!&ndash;                                class="form-group mt-1 mb-1"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-if="updatedWlan.tunneling && isRootUser && updatedWlan.hasOwnProperty('proto')"&ndash;&gt;-->
<!--&lt;!&ndash;                              >&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <select&ndash;&gt;-->
<!--&lt;!&ndash;                                  @change="changeProtoInSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{&ndash;&gt;-->
<!--&lt;!&ndash;                                    input: true,&ndash;&gt;-->
<!--&lt;!&ndash;                                    'select-disabled': !updatedWlan.tunneling&ndash;&gt;-->
<!--&lt;!&ndash;                                  }"&ndash;&gt;-->
<!--&lt;!&ndash;                                >&ndash;&gt;-->
<!--&lt;!&ndash;                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                  <option :value="proto.value" :key="proto.value" v-for="proto in typesOfProto">&ndash;&gt;-->
<!--&lt;!&ndash;                                    {{ proto.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                                </select>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--&lt;!&ndash;                              <div&ndash;&gt;-->
<!--&lt;!&ndash;                                class="form-group mb-2"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-if="&ndash;&gt;-->
<!--&lt;!&ndash;                                  hostsList[0] &&&ndash;&gt;-->
<!--&lt;!&ndash;                                  interfacesWithDetailsFromAllHostsAsArray.length &&&ndash;&gt;-->
<!--&lt;!&ndash;                                  updatedWlan.tunneling &&&ndash;&gt;-->
<!--&lt;!&ndash;                                  isRootUser &&&ndash;&gt;-->
<!--&lt;!&ndash;                                  updatedWlan.proto !== 'gretap'&ndash;&gt;-->
<!--&lt;!&ndash;                                "&ndash;&gt;-->
<!--&lt;!&ndash;                              >&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="default_tunnel" class="">{{ $t('wlans.interface')&ndash;&gt;-->
<!--&lt;!&ndash;                                  }}</label>&ndash;&gt;-->

<!--&lt;!&ndash;&lt;!&ndash;                                <Multiselect&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :customLabel="customLabelForTunnelMiltiselect"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  v-model="updatedWlan.default_tunnel"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :multiple="false"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :options="interfacesWithDetailsFromAllHostsAsArray.map(interfaceWithdetails=>interfaceWithdetails.name) || []"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :closeOnSelect="true"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :hideSelected="true"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  v-validate="'required'"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                   :data-vv-as="$t('wlans.interface')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  id="default_tunnel"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  data-vv-name="Interface"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :placeholder="$t('wlans.selectSingleInterface')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :selectLabel="$t('aps.wlansSelectorTip')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :disabled="!updatedWlan.tunneling || isDisable"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :class="{ 'multiselect-disabled': !updatedWlan.tunneling || isDisable}"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :allowEmpty="true"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  <template v-slot:noOptions>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    {{ $t('general.noDataToDisplay') }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  </template>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  <template v-slot:singleLabel>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    {{labelForSelectedInTunnelMiltiselect()}}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  </template>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  <template v-slot:noResult>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    {{ $t('general.noResultForSearch') }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  </template>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                </Multiselect>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                <Multiselect&ndash;&gt;-->
<!--&lt;!&ndash;                                  label="nameWithType"&ndash;&gt;-->
<!--&lt;!&ndash;                                  trackBy="name"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlanDefaultTunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :multiple="false"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :options="interfacesWithDetailsFromAllHostsAsArray ||  []"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :closeOnSelect="true"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :hideSelected="true"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'required|multiSelectWithInterfaceNotEmpty'"&ndash;&gt;-->
<!--&lt;!&ndash;                                   :data-vv-as="$t('wlans.interface')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="default_tunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                                  data-vv-name="Interface"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.selectSingleInterface')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :selectLabel="$t('aps.wlansSelectorTip')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="!updatedWlan.tunneling || isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                                  @input="selectWlanDefaultTunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ 'multiselect-disabled': !updatedWlan.tunneling || isDisable}"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :allowEmpty="true"&ndash;&gt;-->
<!--&lt;!&ndash;                                >&ndash;&gt;-->
<!--&lt;!&ndash;                                  <template v-slot:noOptions>&ndash;&gt;-->
<!--&lt;!&ndash;                                    {{ $t('general.noDataToDisplay') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                  </template>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  <template v-slot:singleLabel>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    {{labelForSelectedInTunnelMiltiselect()}}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  </template>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                  <template v-slot:noResult>&ndash;&gt;-->
<!--&lt;!&ndash;                                    {{ $t('general.noResultForSearch') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                  </template>&ndash;&gt;-->
<!--&lt;!&ndash;                                </Multiselect>&ndash;&gt;-->
<!--&lt;!&ndash;                                <a v-if="!(!updatedWlan.tunneling || isDisable)" href="#"&ndash;&gt;-->
<!--&lt;!&ndash;                                   class="ml-q "&ndash;&gt;-->
<!--&lt;!&ndash;                                   @click.prevent="clearDefaultTunnel">&ndash;&gt;-->
<!--&lt;!&ndash;                                  <span>{{ $t('general.clear') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </a>&ndash;&gt;-->

<!--&lt;!&ndash;                                <span v-show="errors.has('Interface')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('Interface') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <small v-show="updatedWlan.tunneling" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ $t('wlans.bridge') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </small>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--&lt;!&ndash;                              <div v-else-if="(!interfacesWithDetailsFromAllHostsAsArray || !interfacesWithDetailsFromAllHostsAsArray.length) && isRootUser">&ndash;&gt;-->
<!--&lt;!&ndash;                                {{ $t('wlans.noInterfaces') }}&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--&lt;!&ndash;&lt;!&ndash;                              <div&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                class="form-group mb-2"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                v-if="&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  hostsList[0] &&&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  hostsList[0].interfaces &&&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  updatedWlan.tunneling &&&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  isRootUser &&&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  updatedWlan.proto !== 'gretap'&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                "&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                <label for="default_tunnel" class="">{{ $t('wlans.interface') }}</label>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                <select&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  class="form-control"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  name="hosts_interface"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :data-vv-as="$t('wlans.interface')"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  v-model="updatedWlan.default_tunnel"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  id="default_tunnel"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  data-vv-name="Interface"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  v-validate="'required'"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :disabled="!updatedWlan.tunneling"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  :class="{&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    input: true,&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    'is-danger': errors.has('Interface'),&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    'select-disabled': !updatedWlan.tunneling&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  }"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  <option value="" disabled selected>{{ $t('wlans.selectInterface') }}</option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  <option&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    v-for="hostInterface in hostsList[0].interfaces"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    :value="hostInterface"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    :key="hostInterface"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  >&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                    {{ hostInterface }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  </option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                </select>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                <span v-show="errors.has('Interface')" class="help is-danger">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  {{ errors.first('Interface') }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                </span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                <small v-show="updatedWlan.tunneling" class="help is-danger">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                  {{ $t('wlans.bridge') }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                </small>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              </div>&ndash;&gt;&ndash;&gt;-->


<!--&lt;!&ndash;&lt;!&ndash;                              <div v-else-if="(!hostsList[0] || !hostsList[0].interfaces) && isRootUser">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                {{ $t('wlans.noInterfaces') }}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                              &lt;!&ndash;                              peerAddress aka EoGRE server address&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                              <div&ndash;&gt;-->
<!--&lt;!&ndash;                                class="form-group mt-1"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-if="updatedWlan.hasOwnProperty('peer_address') && updatedWlan.proto === 'gretap' && updatedWlan.tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                              >&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="peerAddress">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ $t('wlans.peerAddress') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                  key="peerAddress"&ndash;&gt;-->
<!--&lt;!&ndash;                                  type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :data-vv-as="$t('wlans.peerAddress')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="peerAddress"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="peerAddress"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ input: true, 'is-danger': errors.has('peerAddress') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'required|addressForEoGREServer'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.peerAddressPlaceholder')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  data-vv-validate-on="change"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.peer_address"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <span v-show="errors.has('peerAddress')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('peerAddress') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--&lt;!&ndash;                              <div class="form-group mt-1">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="vlan">&ndash;&gt;-->
<!--&lt;!&ndash;                                  VLAN&ndash;&gt;-->
<!--&lt;!&ndash;                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                  type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'numeric|between:0,4094'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <span v-show="errors.has('vlan')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('vlan') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-if="updatedWlan.nat">&ndash;&gt;-->
<!--&lt;!&ndash;                              <div class="row mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="form-group col-sm-6 mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                  <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="updatedWlan.firewall.nat_access"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :disabled="isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :label="'SSH / Web access'"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @input="$validator.reset()"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="nat-access"&ndash;&gt;-->
<!--&lt;!&ndash;                                  />&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </transition>&ndash;&gt;-->

<!--&lt;!&ndash;                        <hr v-if="isTunnelShow">&ndash;&gt;-->
<!--&lt;!&ndash;                        <hr v-if="isTunnelShow">&ndash;&gt;-->
<!--&lt;!&ndash;                        <hr v-if="isTunnelShow">&ndash;&gt;-->
<!--                        <transition name="wsettings">-->
<!--                        <div v-if="isTunnelShow">-->
<!--                          <div>-->
<!--                            &lt;!&ndash;                          local Switching&ndash;&gt;-->
<!--                            <Switch-component-->
<!--                              v-model="dataTrafficManagementBlocksShow.localSwitching"-->
<!--                              :disabled="isDisable || dataTrafficManagementBlocksShow.localSwitching === true"-->
<!--                              :label="$t('wlans.localSwitching')"-->
<!--                              @change="dataTrafficManagementSwitchersHandler"-->
<!--                              id="localSwitching-switcher"-->
<!--                            />-->
<!--                          </div>-->
<!--                          <transition name="wsettings">-->
<!--                          <div class="ml-2" v-if="dataTrafficManagementBlocksShow.localSwitching">-->
<!--                            <div class="row mt-h">-->
<!--                              <div class="form-group col-sm-6 mb-0">-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.nat"-->
<!--                                  :disabled="isDisable"-->
<!--                                  @input="$validator.reset()"-->
<!--                                  :label="'NAT'"-->
<!--                                  id="nat"-->
<!--                                  @change="checkNat"-->
<!--                                />-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div-->
<!--                              v-if="updatedWlan.nat === true && updatedWlan.hasOwnProperty('nat_network')">-->
<!--                              <span class="text-muted">{{ $t('wlans.natNetworkInfoMsg') }}</span>-->
<!--                              <div class="form-group mt-1" v-show="updatedWlan.nat === true">-->
<!--                                <label for="nat_network_ipaddr">-->
<!--                                  {{ $t('wlans.natNetworkIpaddr') }}-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  :key="'nat_network_ipaddr'"-->
<!--                                  id="nat_network_ipaddr"-->
<!--                                  autocomplete="off"-->
<!--                                  name="nat_network_ipaddr"-->
<!--                                  v-validate="'ipv4ForNatNetwork'"-->
<!--                                  data-vv-validate-on="change"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"-->
<!--                                  :placeholder="$t('wlans.natNetworkIpaddrPlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.nat_network.ipaddr"-->
<!--                                  :disabled="isDisable"-->
<!--                                />-->
<!--                                <span v-show="errors.has('nat_network_ipaddr')"-->
<!--                                      class="help is-danger">-->
<!--                                  {{ errors.first('nat_network_ipaddr') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                              <div class="form-group mt-1 mb-2" v-show="updatedWlan.nat === true">-->
<!--                                <label for="nat_network_netmask">-->
<!--                                  {{ $t('wlans.natNetworkNetmask') }}-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  :key="'nat_network_netmask'"-->
<!--                                  id="nat_network_netmask"-->
<!--                                  autocomplete="off"-->
<!--                                  name="nat_network_netmask"-->
<!--                                  v-validate="'maskForNatNetwork'"-->
<!--                                  data-vv-validate-on="change"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"-->
<!--                                  :placeholder="$t('wlans.natNetworkNetmaskPlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.nat_network.netmask"-->
<!--                                  :disabled="isDisable"-->
<!--                                />-->
<!--                                <span v-show="errors.has('nat_network_netmask')"-->
<!--                                      class="help is-danger">-->
<!--                                  {{ errors.first('nat_network_netmask') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            <div v-if="updatedWlan.nat">-->
<!--                              <div class="row mt-2">-->
<!--                                <div class="form-group col-sm-6 mb-0">-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.firewall.nat_access"-->
<!--                                    :disabled="isDisable"-->
<!--                                    :label="$t('wlans.SSHWebAccess')"-->
<!--                                    @input="$validator.reset()"-->
<!--                                    id="nat-access"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->


<!--                            <div class="form-group mt-1">-->
<!--                              <label for="vlan">-->
<!--                                VLAN-->
<!--                                <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                              </label>-->
<!--                              <input-->
<!--                                type="text"-->
<!--                                id="vlan"-->
<!--                                name="vlan"-->
<!--                                :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                                v-validate="{numeric: true, between: [0, 4094]}"-->
<!--                                data-vv-validate-on="none"-->
<!--                                @input="vlanForLocalSwitchingInputHandler"-->
<!--                                :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                                class="form-control"-->
<!--                                v-model="updatedWlan.vlan"-->
<!--                                :disabled="isDisable || isNatActive"-->
<!--                              />-->
<!--                              <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                                  {{ errors.first('vlan') }}-->
<!--                                </span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          </transition>-->

<!--                          <div>-->
<!--                            &lt;!&ndash;                          central Switching&ndash;&gt;-->
<!--                            <Switch-component-->
<!--                              v-model="dataTrafficManagementBlocksShow.centralSwitching"-->
<!--                              :disabled="isDisable || dataTrafficManagementBlocksShow.centralSwitching === true"-->
<!--                              :label="$t('wlans.centralSwitching')"-->
<!--                               @change="dataTrafficManagementSwitchersHandler"-->
<!--                              id="centralSwitching-switcher"-->
<!--                            />-->
<!--                          </div>-->
<!--                          <transition name="wsettings">-->
<!--                          <div class="ml-1" v-if="dataTrafficManagementBlocksShow.centralSwitching">-->
<!--                            <div v-if="!updatedWlan.nat">-->
<!--&lt;!&ndash;                              <div class="row " v-if="">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="form-group col-sm-6 mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                  <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="updatedWlan.tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :label="$t('wlans.centralized')"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @change="changeTunnelingSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @input="$validator.reset()"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                                  />&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              Proto fields for EoGRE &ndash;&gt;-->
<!--&lt;!&ndash;                              <div&ndash;&gt;-->
<!--&lt;!&ndash;                                class="form-group mt-1 mb-1"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-if="updatedWlan.tunneling  && updatedWlan.hasOwnProperty('proto')"&ndash;&gt;-->
<!--&lt;!&ndash;                              >&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <select&ndash;&gt;-->
<!--&lt;!&ndash;                                   :disabled="true"&ndash;&gt;-->
<!--&lt;!&ndash;                                  @change="changeProtoInSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control select-disabled"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{&ndash;&gt;-->
<!--&lt;!&ndash;                                    input: true,&ndash;&gt;-->
<!--&lt;!&ndash;                                    'select-disabled': !updatedWlan.tunneling&ndash;&gt;-->
<!--&lt;!&ndash;                                  }"&ndash;&gt;-->
<!--&lt;!&ndash;                                >&ndash;&gt;-->
<!--&lt;!&ndash;                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                  <option :value="proto.value" :key="proto.value"&ndash;&gt;-->
<!--&lt;!&ndash;                                          v-for="proto in typesOfProto">&ndash;&gt;-->
<!--&lt;!&ndash;                                    {{ proto.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                                </select>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--                              <div-->
<!--                                class="form-group mb-2 mt-h"-->
<!--                                v-if="-->
<!--                                  hostsList[0] &&-->
<!--                                  interfacesWithDetailsFromAllHostsAsArray.length &&-->
<!--                                  updatedWlan.tunneling &&-->
<!--                                  updatedWlan.proto !== 'gretap'-->
<!--                                "-->
<!--                              >-->
<!--                                <label for="default_tunnel" class="">{{ $t('wlans.interface')-->
<!--                                  }}</label>-->

<!--                                <Multiselect-->
<!--                                  label="nameWithType"-->
<!--                                  trackBy="name"-->
<!--                                  v-model="updatedWlanDefaultTunnel"-->
<!--                                  :multiple="false"-->
<!--                                  :options="interfacesWithDetailsFromAllHostsAsArray ||  []"-->
<!--                                  :closeOnSelect="true"-->
<!--                                  :hideSelected="true"-->
<!--                                  v-validate="'required|multiSelectWithInterfaceNotEmpty'"-->
<!--                                  :data-vv-as="$t('wlans.interface')"-->
<!--                                  id="default_tunnel"-->
<!--                                  data-vv-name="Interface"-->
<!--                                  :placeholder="$t('wlans.selectSingleInterface')"-->
<!--                                  :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--                                  :disabled="!updatedWlan.tunneling || isDisable"-->
<!--                                  @input="selectWlanDefaultTunnel"-->
<!--                                  :class="{ 'multiselect-disabled': !updatedWlan.tunneling || isDisable}"-->
<!--                                  :allowEmpty="true"-->
<!--                                >-->
<!--                                  <template v-slot:noOptions>-->
<!--                                    {{ $t('general.noDataToDisplay') }}-->
<!--                                  </template>-->
<!--                                  &lt;!&ndash;                                  <template v-slot:singleLabel>&ndash;&gt;-->
<!--                                  &lt;!&ndash;                                    {{labelForSelectedInTunnelMiltiselect()}}&ndash;&gt;-->
<!--                                  &lt;!&ndash;                                  </template>&ndash;&gt;-->
<!--                                  <template v-slot:noResult>-->
<!--                                    {{ $t('general.noResultForSearch') }}-->
<!--                                  </template>-->
<!--                                </Multiselect>-->
<!--                                <a v-if="!(!updatedWlan.tunneling || isDisable)" href="#"-->
<!--                                   class="ml-q "-->
<!--                                   @click.prevent="clearDefaultTunnel">-->
<!--                                  <span>{{ $t('general.clear') }}</span>-->
<!--                                </a>-->

<!--                                <span v-show="errors.has('Interface')" class="help is-danger">-->
<!--                                  {{ errors.first('Interface') }}-->
<!--                                </span>-->
<!--                                <small v-show="updatedWlan.tunneling" class="help is-danger">-->
<!--                                  {{ $t('wlans.bridge') }}-->
<!--                                </small>-->
<!--                              </div>-->

<!--                              <div-->
<!--                                v-else-if="(!interfacesWithDetailsFromAllHostsAsArray || !interfacesWithDetailsFromAllHostsAsArray.length) && isRootUser">-->
<!--                                {{ $t('wlans.noInterfaces') }}-->
<!--                              </div>-->

<!--                              <div class="form-group mt-1">-->
<!--                                <label for="vlan">-->
<!--                                  VLAN-->
<!--                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  id="vlan"-->
<!--                                  name="vlan"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                                  v-validate="'numeric|between:0,4094'"-->
<!--                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.vlan"-->
<!--                                  :disabled="isDisable || isNatActive"-->
<!--                                />-->
<!--                                <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                                  {{ errors.first('vlan') }}-->
<!--                                </span>-->
<!--                              </div>-->

<!--                            </div>-->


<!--                          </div>-->
<!--                          </transition>-->

<!--                          <div>-->
<!--                            &lt;!&ndash;                          tunneling&ndash;&gt;-->
<!--                            <Switch-component-->
<!--                              v-model="dataTrafficManagementBlocksShow.tunneling"-->
<!--                              :disabled="isDisable || dataTrafficManagementBlocksShow.tunneling === true"-->
<!--                              :label="$t('wlans.tunnelingShort')"-->
<!--                               @change="dataTrafficManagementSwitchersHandler"-->
<!--                              id="tunneling-switcher"-->
<!--                            />-->
<!--                          </div>-->
<!--                          <transition name="wsettings">-->
<!--                          <div class="ml-1" v-if="dataTrafficManagementBlocksShow.tunneling">-->
<!--                            <div v-if="!updatedWlan.nat">-->
<!--&lt;!&ndash;                              <div class="row " v-if="">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="form-group col-sm-6 mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                  <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="updatedWlan.tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :label="$t('wlans.centralized')"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @change="changeTunnelingSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                                    @input="$validator.reset()"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                                  />&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              Proto fields for EoGRE &ndash;&gt;-->
<!--&lt;!&ndash;                              <div&ndash;&gt;-->
<!--&lt;!&ndash;                                class="form-group mt-1 mb-1"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-if="updatedWlan.tunneling  && updatedWlan.hasOwnProperty('proto')"&ndash;&gt;-->
<!--&lt;!&ndash;                              >&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <select&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="true"&ndash;&gt;-->
<!--&lt;!&ndash;                                  @change="changeProtoInSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control select-disabled"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="proto"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{&ndash;&gt;-->
<!--&lt;!&ndash;                                    input: true,&ndash;&gt;-->
<!--&lt;!&ndash;                                    'select-disabled': !updatedWlan.tunneling&ndash;&gt;-->
<!--&lt;!&ndash;                                  }"&ndash;&gt;-->
<!--&lt;!&ndash;                                >&ndash;&gt;-->
<!--&lt;!&ndash;                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                  <option :value="proto.value" :key="proto.value"&ndash;&gt;-->
<!--&lt;!&ndash;                                          v-for="proto in typesOfProto">&ndash;&gt;-->
<!--&lt;!&ndash;                                    {{ proto.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                                </select>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--&lt;!&ndash;                              для будущего выбора протоколов при туннелировании&ndash;&gt;-->
<!--                              <div-->
<!--                                class="form-group mt-1 mb-1"-->
<!--                                v-if="updatedWlan.tunneling  && updatedWlan.hasOwnProperty('proto')"-->
<!--                              >-->
<!--                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>-->
<!--                                <select-->
<!--                                  :disabled="isDisable"-->
<!--                                  @change="changeProtoInSelectorInTunnelingBlock"-->
<!--                                  class="form-control"-->
<!--                                  name="proto"-->
<!--                                  v-model="updatedWlan.proto"-->
<!--                                  id="proto"-->
<!--                                  :class="{-->
<!--                                    input: true,-->
<!--                                    'select-disabled': !updatedWlan.tunneling || isDisable-->
<!--                                  }"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;-->
<!--                                  <option :value="proto.value" :key="proto.value"-->
<!--                                          v-for="proto in typesOfProtoForTunnelingBlock">-->
<!--                                    {{ proto.name }}-->
<!--                                  </option>-->
<!--                                </select>-->
<!--                              </div>-->
<!--                              <div-->
<!--                                class="form-group mt-h"-->
<!--                                v-if="updatedWlan.hasOwnProperty('peer_address') && updatedWlan.proto === 'gretap' && updatedWlan.tunneling"-->
<!--                              >-->
<!--                                <label for="peerAddress">-->
<!--                                  {{ $t('wlans.peerAddress') }}-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  key="peerAddress"-->
<!--                                  type="text"-->
<!--                                  :data-vv-as="$t('wlans.peerAddress')"-->
<!--                                  id="peerAddress"-->
<!--                                  name="peerAddress"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('peerAddress') }"-->
<!--                                  v-validate="'required|addressForEoGREServer'"-->
<!--                                  :placeholder="$t('wlans.peerAddressPlaceholder')"-->
<!--                                  data-vv-validate-on="change"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.peer_address"-->
<!--                                  :disabled="isDisable || isNatActive"-->
<!--                                />-->
<!--                                <span v-show="errors.has('peerAddress')" class="help is-danger">-->
<!--                                  {{ errors.first('peerAddress') }}-->
<!--                                </span>-->
<!--                              </div>-->


<!--&lt;!&ndash;                              <div class="form-group mt-1">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="vlan">&ndash;&gt;-->
<!--&lt;!&ndash;                                  VLAN&ndash;&gt;-->
<!--&lt;!&ndash;                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                  type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'numeric|between:0,4094'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <span v-show="errors.has('vlan')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('vlan') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--&lt;!&ndash;                              <div class="form-group mt-1">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label for="vlan">&ndash;&gt;-->
<!--&lt;!&ndash;                                  VLAN&ndash;&gt;-->
<!--&lt;!&ndash;                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                  type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  name="vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-validate="'required|numeric|between:0,4094'"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.vlan"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable || isNatActive"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <span v-show="errors.has('vlan')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                                  {{ errors.first('vlan') }}&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->

<!--                              <div class="form-group mt-1">-->
<!--                                <label for="vlan">-->
<!--                                  VLAN-->
<!--                                  <span class="text-muted" v-if="!updatedWlan.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  id="vlan"-->
<!--                                  name="vlan"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                                  v-validate="'numeric|between:0,4094'"-->
<!--                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="updatedWlan.vlan"-->
<!--                                  :disabled="isDisable"-->
<!--                                />-->
<!--                                <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                                  {{ errors.first('vlan') }}-->
<!--                                </span>-->
<!--                              </div>-->

<!--                            </div>-->


<!--                          </div>-->
<!--                          </transition>-->

<!--&lt;!&ndash;                          <div>&ndash;&gt;-->
<!--&lt;!&ndash;                            &lt;!&ndash;                          dhcp option 82&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                            <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                              v-model="dhcpOption82BlockShow"&ndash;&gt;-->
<!--&lt;!&ndash;                              :disabled="isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                              :label="$t('wlans.dhcpOption82')"&ndash;&gt;-->
<!--&lt;!&ndash;                              @change="dhcpOption82SwitcherHandler"&ndash;&gt;-->
<!--&lt;!&ndash;                              id="tunneling-switcher"&ndash;&gt;-->
<!--&lt;!&ndash;                            />&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->


<!--&lt;!&ndash;                          <transition name="wsettings">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-if="dhcpOption82BlockShow">&ndash;&gt;-->
<!--&lt;!&ndash;                              option 82 settings block&ndash;&gt;-->

<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          </transition>&ndash;&gt;-->


<!--                        </div>-->
<!--                        </transition>-->
<!--                      </div>-->


<!--                      &lt;!&ndash;                      dhcp option 82 block&ndash;&gt;-->
<!--                      <div class="wlan-settings-block-for-modal mb-1">-->
<!--                        <div-->
<!--                          class="wlan-settings-block__header"-->
<!--                          @click.prevent="showDhcpOption82Block"-->
<!--                          :class="{ 'mb-2': dhcpOption82BlockShow }"-->
<!--                        >-->
<!--                          <div>-->
<!--                            <span class="h6 mr-1">-->
<!--                              {{ $t('wlans.dhcpOption82') }}-->
<!--                            </span>-->
<!--                            &lt;!&ndash;                            <span&ndash;&gt;-->
<!--                            &lt;!&ndash;                              v-if="getWlanDataTrafficManagementTypeFromWlanObject(wlanEditCurrent) === 'tunneling'"&ndash;&gt;-->
<!--                            &lt;!&ndash;                              class="badge mr-q badge-success">&ndash;&gt;-->
<!--                            &lt;!&ndash;                              {{ $t('wlans.tunnelingShort') }}&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                            <span v-if="Object.prototype.hasOwnProperty.call(wlanEditCurrent, 'option82state') &&-->
<!--                            wlanEditCurrent.option82state === option82States.ENABLE" class="badge mr-q badge-success">-->
<!--                              {{$t('wlans.dhcpOption82ForBadge')}}:&nbsp;{{ $t('general.on') }}-->
<!--                            </span>-->
<!--                            <span v-else class="badge mr-q badge-default">-->
<!--                              {{$t('wlans.dhcpOption82ForBadge')}}:&nbsp;{{ $t('general.off') }}-->
<!--                            </span>-->
<!--                            <span v-if="Object.prototype.hasOwnProperty.call(wlanEditCurrent, 'option82state') &&-->
<!--                            wlanEditCurrent.option82state === option82States.ENABLE &&-->
<!--                            Object.prototype.hasOwnProperty.call(wlanEditCurrent, 'option82rid_type') && wlanEditCurrent.option82rid_type !== ''"-->
<!--                                  class="badge mr-q badge-success">-->
<!--                              {{wlanEditCurrent.option82rid_type }}-->
<!--                            </span>-->
<!--                               <span v-if="Object.prototype.hasOwnProperty.call(wlanEditCurrent, 'option82state') &&-->
<!--                            wlanEditCurrent.option82state === option82States.ENABLE &&-->
<!--                            Object.prototype.hasOwnProperty.call(wlanEditCurrent, 'option82cid_type') && wlanEditCurrent.option82cid_type !== ''"-->
<!--                                  class="badge mr-q badge-success">-->
<!--                              {{wlanEditCurrent.option82cid_type }}-->
<!--                            </span>-->

<!--                          </div>-->

<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn"-->
<!--                                  v-if="!dhcpOption82BlockShow">-->
<!--                            <i class="icon-arrow-left"></i>-->
<!--                          </button>-->
<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn"-->
<!--                                  v-if="dhcpOption82BlockShow">-->
<!--                            <i class="icon-arrow-down"></i>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                        dhcp option 82 block inner&ndash;&gt;-->
<!--                        <transition name="wsettings">-->
<!--                          <div @click.stop="" v-if="dhcpOption82BlockShow">-->
<!--                            &lt;!&ndash;                          dhcp option 82&ndash;&gt;-->
<!--                            <Switch-component-->
<!--                              v-model="dhcpOption82Enabled"-->
<!--                              :disabled="isDisable"-->
<!--                              :label="$t('wlans.dhcpOption82Enable')"-->
<!--                              @input="dhcpOption82EnableSwitcherHandler"-->
<!--                              id="option82-switcher"-->
<!--                            />-->
<!--&lt;!&ndash;                            <info class="ml-h" :content="$t('wlans.dhcpOption82InfoHint')"/>&ndash;&gt;-->
<!--                            <div>-->
<!--                              &lt;!&ndash;                              <div class="ml-1 mb-h h6">{{$t('general.format')}}:</div>&ndash;&gt;-->
<!--                              &lt;!&ndash;                            dhcp option 82  Rid Type&ndash;&gt;-->
<!--                              <Option82RidTypeSelector-->
<!--                                @selectOption82RidType="selectOption82RidTypeHandler"-->
<!--                                :updated-wlan="updatedWlan"-->
<!--                                :is-disable="isDisable || !dhcpOption82Enabled">-->
<!--                              </Option82RidTypeSelector>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </transition>-->


<!--                        <transition name="wsettings">-->

<!--                        </transition>-->


<!--                      </div>-->




<!--                      <div class="wlan-settings-block-for-modal mb-1">-->
<!--                        <div-->
<!--                          class="wlan-settings-block__header"-->
<!--                          @click.prevent="showSpeedControl"-->
<!--                          :class="{ 'mb-2': isSpeedControlShow }"-->
<!--                        >-->
<!--                          <div>-->
<!--                            <span class="h6 mr-1">{{ $t('wlans.speedControl') }}</span>-->
<!--                            <span-->
<!--                              class="badge badge-success"-->
<!--                              v-if="updatedWlan.speed_download && updatedWlan.speed_download.value != 0"-->
<!--                            >-->
<!--                              {{ $t('wlans.download') }}: {{ updatedWlan.speed_download.value }} kbit-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge badge-success"-->
<!--                              v-if="updatedWlan.speed_upload && updatedWlan.speed_upload.value != 0"-->
<!--                            >-->
<!--                              {{ $t('wlans.upload') }}: {{ updatedWlan.speed_upload.value }} kbit-->
<!--                            </span>-->
<!--                          </div>-->

<!--                          <button-->
<!--                            class="btn btn-link float-right new-modern-style-btn-other-btn"-->
<!--                            v-if="!isSpeedControlShow"-->
<!--                          >-->
<!--                            <i class="icon-arrow-left"></i>-->
<!--                          </button>-->
<!--                          <button-->
<!--                            class="btn btn-link float-right new-modern-style-btn-other-btn"-->
<!--                            v-if="isSpeedControlShow"-->
<!--                          >-->
<!--                            <i class="icon-arrow-down"></i>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                        <transition name="wsettings">-->
<!--                          <div v-if="isSpeedControlShow" class="mt-1">-->
<!--                            <div class="form-group">-->
<!--                              <label for="speed_download">-->
<!--                                {{ $t('wlans.speedDownload') }} (0 - {{ $t('wlans.unlim') }})-->
<!--                              </label>-->
<!--                              <input-->
<!--                                type="number"-->
<!--                                id="speed_download"-->
<!--                                name="speed_download"-->
<!--                                :placeholder="$t('wlans.enterKbitPlaceholder')"-->
<!--                                class="form-control"-->
<!--                                v-model.number="updatedWlan.speed_download.value"-->
<!--                                :disabled="isDisable"-->
<!--                              />-->
<!--                            </div>-->

<!--                            <div class="form-group">-->
<!--                              <label for="speed_upload">-->
<!--                                {{ $t('wlans.speedUpload') }} (0 - {{ $t('wlans.unlim') }})-->
<!--                              </label>-->
<!--                              <input-->
<!--                                type="number"-->
<!--                                id="speed_upload"-->
<!--                                name="speed_upload"-->
<!--                                :placeholder="$t('wlans.enterKbitPlaceholder')"-->
<!--                                class="form-control"-->
<!--                                v-model.number="updatedWlan.speed_upload.value"-->
<!--                                :disabled="isDisable"-->
<!--                              />-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </transition>-->
<!--                      </div>-->



<!--                      <div class="wlan-settings-block-for-modal mb-1">-->
<!--                        <div-->
<!--                          class="wlan-settings-block__header"-->
<!--                          :class="{ 'mb-2': isMobilityShow }"-->
<!--                          @click.prevent="showMobility"-->
<!--                        >-->
<!--                          <div>-->
<!--                            <span class="h6 mr-1" :class="{ 'not-for-cisco': isCiscoModeEnabled }">-->
<!--                              {{ $t('wlans.mobility') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              v-if="wlanEditCurrent.roam80211r && updatedWlan.security.type != 'open'"-->
<!--                              class="badge mr-q badge-warning"-->
<!--                            >-->
<!--                              {{ $t('wlans.fastTransition') }}-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                            ft_over_ds ft over ds&ndash;&gt;-->
<!--                            <span-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.roam80211r === true &&-->
<!--                                updatedWlan.security.type != 'open' &&-->
<!--                                wlanEditCurrent.hasOwnProperty('ft_over_ds')-->
<!--                              "-->
<!--                              class="badge mr-q badge-warning"-->
<!--                            >-->
<!--                              {{ $t('wlans.overTheDS') }}:-->
<!--                              <span v-if="wlanEditCurrent.ft_over_ds === true">{{ $t('general.on') }}</span>-->
<!--                              <span v-if="wlanEditCurrent.ft_over_ds === false">{{ $t('general.off') }}</span>-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                            nas_generate nas generate&ndash;&gt;-->
<!--                            <span-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.roam80211r === true &&-->
<!--                                updatedWlan.security.type != 'open' &&-->
<!--                                wlanEditCurrent.hasOwnProperty('nas_generate')-->
<!--                              "-->
<!--                              class="badge mr-q badge-warning"-->
<!--                            >-->
<!--                              {{ $t('wlans.nas_generateCaption') }}:-->
<!--                              <span v-if="wlanEditCurrent.nas_generate === true">{{ $t('general.on') }}</span>-->
<!--                              <span v-if="wlanEditCurrent.nas_generate === false">{{ $t('general.off') }}</span>-->
<!--                            </span>-->

<!--                            <span-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.pmkcaching &&-->
<!--                                (updatedWlan.security.type === 'wpaenterprise' ||-->
<!--                                  updatedWlan.security.type === 'wpa2enterprise')-->
<!--                              "-->
<!--                              class="badge mr-q badge-info"-->
<!--                            >-->
<!--                              {{ $t('wlans.PMKcaching') }}-->
<!--                            </span>-->
<!--                            &lt;!&ndash;                            ieee80211k badge&ndash;&gt;-->
<!--                            <span v-if="isShowIeee80211kBadge" class="badge badge-success mr-0">-->
<!--                              {{ $t('wlans.ieee80211kForBadge') }}: {{ $t('wlans.enabledForBadge') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge badge-success mr-0"-->
<!--                              v-if="isShowIeee80211kBadge && isRrmNeighborReportEnabledForBadge"-->
<!--                            >-->
<!--                              ( {{ $t('wlans.rrm_neighbor_reportForBadge') }}: {{ $t('wlans.enabledForBadge') }},-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge badge-success mr-0"-->
<!--                              v-if="isShowIeee80211kBadge && !isRrmNeighborReportEnabledForBadge"-->
<!--                            >-->
<!--                              ( {{ $t('wlans.rrm_neighbor_reportForBadge') }}: {{ $t('wlans.disabledForBadge') }},-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge badge-success mr-0"-->
<!--                              v-if="isShowIeee80211kBadge && isRrmBeaconReportEnabledForBadge"-->
<!--                            >-->
<!--                              {{ $t('wlans.rrm_beacon_reportForBadge') }}: {{ $t('wlans.enabledForBadge') }} )-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge badge-success mr-0"-->
<!--                              v-if="isShowIeee80211kBadge && !isRrmBeaconReportEnabledForBadge"-->
<!--                            >-->
<!--                              {{ $t('wlans.rrm_beacon_reportForBadge') }}: {{ $t('wlans.disabledForBadge') }} )-->
<!--                            </span>-->
<!--                            <span v-if="isShowIeee80211kBadge" class="mr-q"></span>-->

<!--                            &lt;!&ndash;                            ieee80211v badge&ndash;&gt;-->
<!--                            <span-->
<!--                              v-if="isShowIeee80211vBadge"-->
<!--                              class="badge mr-0"-->
<!--                              :class="{ 'badge-info': isShowIeee80211kBadge, 'badge-success': !isShowIeee80211kBadge }"-->
<!--                            >-->
<!--                              {{ $t('wlans.ieee80211vForBadge') }}: {{ $t('wlans.enabledForBadge') }}-->

<!--                              &lt;!&ndash;                              <span>( </span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              <span v-if="isWnmSleepModeForBadge">{{ $t('wlans.wnm_sleep_modeForBadge') }}: {{ $t('wlans.enabledForBadge') }}, </span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              <span v-else>{{ $t('wlans.wnm_sleep_modeForBadge') }}: {{ $t('wlans.disabledForBadge') }}, </span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              <span v-if="isBssTransitionBadge">{{ $t('wlans.bss_transitioForBadge') }}: {{ $t('wlans.enabledForBadge') }}</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              <span v-else>{{ $t('wlans.bss_transitioForBadge') }}: {{ $t('wlans.disabledForBadge') }}</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                              <span> )</span>&ndash;&gt;-->
<!--                            </span>-->

<!--                            <span-->
<!--                              class="badge mr-0"-->
<!--                              :class="{ 'badge-info': isShowIeee80211kBadge, 'badge-success': !isShowIeee80211kBadge }"-->
<!--                              v-if="isShowIeee80211vBadge && isWnmSleepModeForBadge"-->
<!--                            >-->
<!--                              ( {{ $t('wlans.wnm_sleep_modeForBadge') }}: {{ $t('wlans.enabledForBadge') }},-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge mr-0"-->
<!--                              :class="{ 'badge-info': isShowIeee80211kBadge, 'badge-success': !isShowIeee80211kBadge }"-->
<!--                              v-if="isShowIeee80211vBadge && !isWnmSleepModeForBadge"-->
<!--                            >-->
<!--                              ( {{ $t('wlans.wnm_sleep_modeForBadge') }}: {{ $t('wlans.disabledForBadge') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge mr-0"-->
<!--                              :class="{ 'badge-info': isShowIeee80211kBadge, 'badge-success': !isShowIeee80211kBadge }"-->
<!--                              v-if="isShowIeee80211vBadge && isBssTransitionBadge"-->
<!--                            >-->
<!--                              {{ $t('wlans.bss_transitioForBadge') }}: {{ $t('wlans.enabledForBadge') }} )-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge mr-0"-->
<!--                              :class="{ 'badge-info': isShowIeee80211kBadge, 'badge-success': !isShowIeee80211kBadge }"-->
<!--                              v-if="isShowIeee80211vBadge && !isBssTransitionBadge"-->
<!--                            >-->
<!--                              {{ $t('wlans.bss_transitioForBadge') }}: {{ $t('wlans.disabledForBadge') }} )-->
<!--                            </span>-->
<!--                            <span v-if="isShowIeee80211vBadge" class="mr-q"></span>-->
<!--                            &lt;!&ndash;                          RSSI threshold   rssi_threshold badge&ndash;&gt;-->
<!--                            <span-->
<!--                              class="badge badge-info mr-q"-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.hasOwnProperty('rssi_threshold') && wlanEditCurrent.rssi_threshold !== 0-->
<!--                              "-->
<!--                            >-->
<!--                              {{ $t('wlans.rssiThreshold') }}: {{ wlanEditCurrent.rssi_threshold }} dbm-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                          signal stay   signal_stay  badge&ndash;&gt;-->
<!--                            <span-->
<!--                              class="badge badge-info mr-q"-->
<!--                              v-if="wlanEditCurrent.hasOwnProperty('signal_stay') && wlanEditCurrent.signal_stay !== 0"-->
<!--                            >-->
<!--                              {{ $t('wlans.signalStayForBadge') }}: {{ wlanEditCurrent.signal_stay }} dbm-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                          signal strikes   signal_strikes badge&ndash;&gt;-->
<!--                            <span-->
<!--                              class="badge badge-info mr-q"-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.hasOwnProperty('signal_stay') &&-->
<!--                                wlanEditCurrent.signal_stay !== 0 &&-->
<!--                                wlanEditCurrent.hasOwnProperty('signal_strikes')-->
<!--                              "-->
<!--                            >-->
<!--                              {{ $t('wlans.signalStrikesForBadge') }}: {{ wlanEditCurrent.signal_strikes }}-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                          signal poll time   signal_poll_time badge&ndash;&gt;-->
<!--                            <span-->
<!--                              class="badge badge-info mr-q"-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.hasOwnProperty('signal_stay') &&-->
<!--                                wlanEditCurrent.signal_stay !== 0 &&-->
<!--                                wlanEditCurrent.hasOwnProperty('signal_poll_time')-->
<!--                              "-->
<!--                            >-->
<!--                              {{ $t('wlans.signalPollTimeForBadge') }}: {{ wlanEditCurrent.signal_poll_time }}-->
<!--                              {{ $t('wlans.signalPollTimeForBadgeSec') }}-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                         signal drop reason  signal_drop_reason&ndash;&gt;-->
<!--                            <span-->
<!--                              class="badge badge-info mr-q"-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.hasOwnProperty('signal_stay') &&-->
<!--                                wlanEditCurrent.signal_stay !== 0 &&-->
<!--                                wlanEditCurrent.hasOwnProperty('signal_drop_reason')-->
<!--                              "-->
<!--                            >-->
<!--                              {{ $t('wlans.signalDropReasonForBadge') }}: {{ wlanEditCurrent.signal_drop_reason }}-->
<!--                            </span>-->

<!--                            &lt;!&ndash;                             Band Steering band_steering badge&ndash;&gt;-->
<!--                            <span-->
<!--                              class="badge badge-info mr-q"-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.hasOwnProperty('band_steering') &&-->
<!--                                wlanEditCurrent.band_steering === true-->
<!--                              "-->
<!--                            >-->
<!--                              {{ $t('wlans.bandSteeringForBadge') }}: {{ $t('wlans.enabledForBadge') }}-->
<!--                            </span>-->
<!--&lt;!&ndash;                            &lt;!&ndash; Load Balancing load_balancing badge &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                            <span&ndash;&gt;-->
<!--&lt;!&ndash;                              class="badge badge-success mr-q"&ndash;&gt;-->
<!--&lt;!&ndash;                              v-if="&ndash;&gt;-->
<!--&lt;!&ndash;                                wlanEditCurrent.hasOwnProperty('load_balancing') &&&ndash;&gt;-->
<!--&lt;!&ndash;                                wlanEditCurrent.load_balancing === true&ndash;&gt;-->
<!--&lt;!&ndash;                              "&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                              {{ $t('wlans.loadBalancing') }}: {{ $t('wlans.enabledForBadge') }}&ndash;&gt;-->
<!--&lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                            &lt;!&ndash; Load Balancing load_balancing badge &ndash;&gt;-->
<!--                            <span-->
<!--                              v-show="isShowLoadBalancingSwitcher"-->
<!--                              class="badge badge-success mr-q"-->
<!--                              v-if="-->
<!--                                wlanEditCurrent.hasOwnProperty('load_balancing') &&-->
<!--                                wlanEditCurrent.load_balancing === true-->
<!--                              "-->
<!--                            >-->
<!--                              {{ $t('wlans.loadBalancing') }}: {{ $t('wlans.enabledForBadge') }}-->
<!--                            </span>-->
<!--                          </div>-->

<!--                          <button-->
<!--                            class="btn btn-link float-right new-modern-style-btn-other-btn"-->
<!--                            v-if="!isMobilityShow"-->
<!--                          >-->
<!--                            <i class="icon-arrow-left"></i>-->
<!--                          </button>-->
<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn" v-if="isMobilityShow">-->
<!--                            <i class="icon-arrow-down"></i>-->
<!--                          </button>-->
<!--                        </div>-->

<!--                        <transition name="wsettings">-->
<!--                          <div class="" v-if="isMobilityShow">-->
<!--                            <div class="row mt-1">-->
<!--                              <div class="form-group col-sm-12" :class="{ 'mb-h': updatedWlan.roam80211r === true }">-->
<!--                                <Switch-component-->
<!--                                  v-tooltip.auto="{ content: `${$t('wlans.notForOpen')}` }"-->
<!--                                  v-model="updatedWlan.roam80211r"-->
<!--                                  :disabled="isDisable || updatedWlan.security.type == 'open'"-->
<!--                                  :label="$t('wlans.fastTransition')"-->
<!--                                  id="roam80211r"-->
<!--                                  @input="roam80211rSwitcherChange"-->
<!--                                />-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                            &ndash;&gt;-->
<!--                            <transition name="wsettings">-->
<!--                              <div-->
<!--                                class="row subswitchers-warpper-in-mobility-part"-->
<!--                                v-if="updatedWlan.hasOwnProperty('roam80211r') && updatedWlan.roam80211r === true"-->
<!--                              >-->
<!--                                &lt;!&ndash;                              ft_over_ds ft over ds&ndash;&gt;-->
<!--                                <div-->
<!--                                  class="form-group col-sm-12 mb-h ml-0 pl-0"-->
<!--                                  v-if="updatedWlan.hasOwnProperty('ft_over_ds')"-->
<!--                                >-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.ft_over_ds"-->
<!--                                    :disabled="isDisable || updatedWlan.security.type == 'open'"-->
<!--                                    :label="$t('wlans.overTheDS')"-->
<!--                                    id="ft_over_ds"-->
<!--                                  />-->
<!--                                </div>-->
<!--                                &lt;!&ndash;                                nas_generate nas generate&ndash;&gt;-->
<!--                                <div-->
<!--                                  class="form-group col-sm-12 mb-h ml-0 pl-0"-->
<!--                                  v-if="updatedWlan.hasOwnProperty('nas_generate')"-->
<!--                                >-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.nas_generate"-->
<!--                                    :disabled="isDisable || updatedWlan.security.type == 'open'"-->
<!--                                    :label="$t('wlans.nas_generateCaption')"-->
<!--                                    id="nas_generate"-->
<!--                                  />-->
<!--                                  <info class="ml-q" :content="$t('wlans.nas_generateInfo')" />-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </transition>-->

<!--                            <div class="row">-->
<!--                              <div-->
<!--                                class="form-group col-sm-12"-->
<!--                                v-if="updatedWlan.security.type.indexOf('enterprise') == -1"-->
<!--                              >-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.pmkcaching"-->
<!--                                  v-tooltip.auto="{ content: `${$t('wlans.onlyForWPAEnterprise')}` }"-->
<!--                                  :disabled="isDisable || updatedWlan.security.type.indexOf('enterprise') == -1"-->
<!--                                  :label="$t('wlans.PMKcaching')"-->
<!--                                  id="pmkcaching_wpae"-->
<!--                                />-->
<!--                              </div>-->
<!--                              <div class="form-group col-sm-12" v-else>-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.pmkcaching"-->
<!--                                  :disabled="isDisable || updatedWlan.security.type.indexOf('enterprise') == -1"-->
<!--                                  :label="$t('wlans.PMKcaching')"-->
<!--                                  id="pmkcaching"-->
<!--                                />-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                            ieee80211k&ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('ieee80211k')">-->
<!--                              <div class="form-group col-sm-12" :class="{ 'mb-h': updatedWlan.ieee80211k === true }">-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.ieee80211k"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :label="$t('wlans.ieee80211k')"-->
<!--                                  id="ieee80211k"-->
<!--                                  @input="ieee80211kSwitcherChange"-->
<!--                                />-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <transition name="wsettings">-->
<!--                              <div-->
<!--                                class="row subswitchers-warpper-in-mobility-part"-->
<!--                                v-if="updatedWlan.hasOwnProperty('ieee80211k') && updatedWlan.ieee80211k === true"-->
<!--                              >-->
<!--                                &lt;!&ndash;                              rrm neighbor report&ndash;&gt;-->
<!--                                <div-->
<!--                                  class="form-group col-sm-12 mb-h ml-0 pl-0"-->
<!--                                  v-if="updatedWlan.hasOwnProperty('rrm_neighbor_report')"-->
<!--                                >-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.rrm_neighbor_report"-->
<!--                                    :disabled="isDisable"-->
<!--                                    :label="$t('wlans.rrm_neighbor_report')"-->
<!--                                    id="rrm_neighbor_report"-->
<!--                                  />-->
<!--                                </div>-->
<!--                                <div-->
<!--                                  class="form-group col-sm-12 ml-0 pl-0"-->
<!--                                  v-if="updatedWlan.hasOwnProperty('rrm_beacon_report')"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                rrm beacon report&ndash;&gt;-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.rrm_beacon_report"-->
<!--                                    :disabled="isDisable"-->
<!--                                    :label="$t('wlans.rrm_beacon_report')"-->
<!--                                    id="rrm_beacon_report"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </transition>-->

<!--                            &lt;!&ndash;                            ieee80211v&ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('ieee80211v')">-->
<!--                              <div class="form-group col-sm-12" :class="{ 'mb-h': updatedWlan.ieee80211v === true }">-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.ieee80211v"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :label="$t('wlans.ieee80211v')"-->
<!--                                  id="ieee80211v"-->
<!--                                />-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <transition name="wsettings">-->
<!--                              <div-->
<!--                                class="row subswitchers-warpper-in-mobility-part"-->
<!--                                v-if="updatedWlan.hasOwnProperty('ieee80211v') && updatedWlan.ieee80211v === true"-->
<!--                              >-->
<!--                                &lt;!&ndash;                              wnm sleep mode&ndash;&gt;-->
<!--                                <div-->
<!--                                  class="form-group col-sm-12 ml-0 pl-0 mb-h"-->
<!--                                  v-if="updatedWlan.hasOwnProperty('wnm_sleep_mode')"-->
<!--                                >-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.wnm_sleep_mode"-->
<!--                                    :disabled="isDisable"-->
<!--                                    :label="$t('wlans.wnm_sleep_mode')"-->
<!--                                    id="rrm_neighbor_report"-->
<!--                                  />-->
<!--                                  <info class="info-tooltip-in-wlans-wmm" :content="$t('wlans.wnm_sleep_modeInfo')" />-->
<!--                                </div>-->
<!--                                <div-->
<!--                                  class="form-group col-sm-12 ml-0 pl-0"-->
<!--                                  v-if="updatedWlan.hasOwnProperty('bss_transition')"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                bss transition&ndash;&gt;-->
<!--                                  <Switch-component-->
<!--                                    v-model="updatedWlan.bss_transition"-->
<!--                                    :disabled="isDisable"-->
<!--                                    :label="$t('wlans.bss_transition')"-->
<!--                                    id="bss_transition"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </transition>-->
<!--                            &lt;!&ndash;                            RSSI threshold rssi_threshold&ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('rssi_threshold')">-->
<!--                              <div class="form-group col-sm-12">-->
<!--                                &lt;!&ndash;                                <Switch-component&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  v-model="updatedWlan.rssi_threshold"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  :disabled="isDisable"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  label="rssi_threshold"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  id="rssi_threshold"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                />&ndash;&gt;-->
<!--                                <label for="rssi_threshold">-->
<!--                                  {{ $t('wlans.rssiThreshold') }}-->
<!--                                  <info class="" :content="$t('wlans.rssiThresholdHintForInfo')" />-->
<!--                                  <small class="ml-q">({{ $t('wlans.rssiThresholdHintRange') }})</small>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="number"-->
<!--                                  id="rssi_threshold"-->
<!--                                  name="rssi_threshold"-->
<!--                                  :placeholder="$t('wlans.rssiThresholdPlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-validate="'required|integer|rssiThresholdValueForWlan'"-->
<!--                                  v-model.number="updatedWlan.rssi_threshold"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :data-vv-as="$t('wlans.rssiThreshold')"-->
<!--                                />-->
<!--                                <span v-show="errors.has('rssi_threshold')" class="help is-danger">-->
<!--                                  {{ errors.first('rssi_threshold') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                            Signal caption&ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_stay')">-->
<!--                              <div class="form-group col-sm-12 mb-q">-->
<!--                                <span>{{ $t('wlans.signalBlockCaption') }}:</span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                               Signal Stay  signal_stay &ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_stay')">-->
<!--                              <div class="form-group col-sm-12">-->
<!--                                <label for="signal_stay">-->
<!--                                  {{ $t('wlans.signalStay') }}-->
<!--                                  <info class="" :content="$t('wlans.signalStayForInfo')" />-->
<!--                                  <small class="ml-q">({{ $t('wlans.signalStayHint') }})</small>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="number"-->
<!--                                  id="signal_stay"-->
<!--                                  name="signal_stay"-->
<!--                                  :placeholder="$t('wlans.signalStayPlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-validate="'required|integer|signalStayValueForWlan'"-->
<!--                                  v-model.number="updatedWlan.signal_stay"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :data-vv-as="$t('wlans.signalStay')"-->
<!--                                />-->
<!--                                <span v-show="errors.has('signal_stay')" class="help is-danger">-->
<!--                                  {{ errors.first('signal_stay') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                               Signal Strikes signal_strikes &ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_strikes')">-->
<!--                              <div class="form-group col-sm-12">-->
<!--                                <label for="signal_strikes">-->
<!--                                  {{ $t('wlans.signalStrikes') }}-->
<!--                                  <info class="" :content="$t('wlans.signalStrikesForInfo')" />-->
<!--                                  <small class="ml-q">({{ $t('wlans.signalStrikesHint') }})</small>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="number"-->
<!--                                  id="signal_strikes"-->
<!--                                  name="signal_strikes"-->
<!--                                  :placeholder="$t('wlans.signalStrikesPlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-validate="'integer|signalStrikesValueForWlan'"-->
<!--                                  v-model.number="updatedWlan.signal_strikes"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :data-vv-as="$t('wlans.signalStrikes')"-->
<!--                                />-->
<!--                                <span v-show="errors.has('signal_strikes')" class="help is-danger">-->
<!--                                  {{ errors.first('signal_strikes') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                               signal Poll Time signal_poll_time &ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_strikes')">-->
<!--                              <div class="form-group col-sm-12">-->
<!--                                <label for="signal_poll_time">-->
<!--                                  {{ $t('wlans.signalPollTime') }}-->
<!--                                  &lt;!&ndash;                                  <info class=""&ndash;&gt;-->
<!--                                  &lt;!&ndash;                                        :content="$t('wlans.signalPollTimeForInfo')"/>&ndash;&gt;-->
<!--                                  <small class="ml-q">({{ $t('wlans.signalPollTimeHint') }})</small>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="number"-->
<!--                                  id="signal_poll_time"-->
<!--                                  name="signal_poll_time"-->
<!--                                  :placeholder="$t('wlans.signalPollTimePlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-validate="'integer|signalPollValueForWlan'"-->
<!--                                  v-model.number="updatedWlan.signal_poll_time"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :data-vv-as="$t('wlans.signalPollTime')"-->
<!--                                />-->
<!--                                <span v-show="errors.has('signal_poll_time')" class="help is-danger">-->
<!--                                  {{ errors.first('signal_poll_time') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                               signal Drop Reason signal_drop_reason &ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('signal_drop_reason')">-->
<!--                              <div class="form-group col-sm-12">-->
<!--                                <label for="signal_drop_reason">-->
<!--                                  {{ $t('wlans.signalDropReason') }}-->
<!--                                  <info-->
<!--                                    @clicked="showDropReasonModal"-->
<!--                                    class=""-->
<!--                                    :content="$t('wlans.signalDropReasonForInfo')"-->
<!--                                  />-->
<!--                                  <small class="ml-q">-->
<!--                                    ({{ $t('wlans.signalDropReasonHint') }}.-->
<!--                                    <span-->
<!--                                      style="text-decoration: underline;"-->
<!--                                      @click="showDropReasonModal"-->
<!--                                      role="button"-->
<!--                                    >-->
<!--                                      {{ $t('wlans.signalDropReasonMoreInfo') }})-->
<!--                                    </span>-->
<!--                                  </small>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="number"-->
<!--                                  id="signal_drop_reason"-->
<!--                                  name="signal_drop_reason"-->
<!--                                  :placeholder="$t('wlans.signalDropReasonPlaceholder')"-->
<!--                                  class="form-control"-->
<!--                                  v-validate="'integer|signalDropReasonForWlan'"-->
<!--                                  v-model.number="updatedWlan.signal_drop_reason"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :data-vv-as="$t('wlans.signalDropReason')"-->
<!--                                />-->
<!--                                <span v-show="errors.has('signal_drop_reason')" class="help is-danger">-->
<!--                                  {{ errors.first('signal_drop_reason') }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                            &lt;!&ndash;                     Band Steering band_steering&ndash;&gt;-->
<!--                            <div class="row" v-if="updatedWlan.hasOwnProperty('band_steering')">-->
<!--                              <div class="form-group col-sm-12 mt-h">-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.band_steering"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :label="$t('wlans.bandSteering')"-->
<!--                                  id="band_steering"-->
<!--                                />-->
<!--                              </div>-->
<!--                            </div>-->

<!--&lt;!&ndash;                            &lt;!&ndash; Load Balancing load_balancing &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                              <div class="form-group col-sm-12 mt-h">&ndash;&gt;-->
<!--&lt;!&ndash;                                <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                                  v-model="updatedWlan.load_balancing"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :disabled="isDisable"&ndash;&gt;-->
<!--&lt;!&ndash;                                  :label="$t('wlans.loadBalancing')"&ndash;&gt;-->
<!--&lt;!&ndash;                                  id="load_balancing"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                                <info class="ml-q" :content="$t('wlans.loadBalancingInfoMsg')" />&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                            &lt;!&ndash; Load Balancing load_balancing &ndash;&gt;-->
<!--                            <div class="row" v-show="isShowLoadBalancingSwitcher">-->
<!--                              <div class="form-group col-sm-12 mt-h">-->
<!--                                <Switch-component-->
<!--                                  v-model="updatedWlan.load_balancing"-->
<!--                                  :disabled="isDisable"-->
<!--                                  :label="$t('wlans.loadBalancing')"-->
<!--                                  id="load_balancing"-->
<!--                                />-->
<!--                                <info class="ml-q" :content="$t('wlans.loadBalancingInfoMsg')" />-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </transition>-->
<!--                      </div>-->

<!--                      <div class="wlan-settings-block-for-modal mb-h">-->
<!--                        <div-->
<!--                          class="wlan-settings-block__header"-->
<!--                          :class="{ 'mb-2': isWMMShow }"-->
<!--                          @click.prevent="showWMM"-->
<!--                        >-->
<!--                          <div>-->
<!--                            <span class="h6 mr-2 mb-0" :class="{ 'not-for-cisco': isCiscoModeEnabled }">-->
<!--                              {{ $t('wlans.WMM') }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              class="badge"-->
<!--                              :class="{-->
<!--                                'badge-default': wlanEditCurrent.wmm.disabled,-->
<!--                                'badge-success': !wlanEditCurrent.wmm.disabled-->
<!--                              }"-->
<!--                            >-->
<!--                              {{-->
<!--                                wlanEditCurrent.wmm.disabled ? `${$t('general.disabled')}` : `${$t('general.enabled')}`-->
<!--                              }}-->
<!--                            </span>-->
<!--                            <span class="badge badge-info" v-if="wlanEditCurrent.wmm.uapsd">-->
<!--                              {{ $t('wlans.UAPSD') }}-->
<!--                            </span>-->
<!--                          </div>-->

<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn" v-if="!isWMMShow">-->
<!--                            <i class="icon-arrow-left"></i>-->
<!--                          </button>-->
<!--                          <button class="btn btn-link float-right new-modern-style-btn-other-btn" v-if="isWMMShow">-->
<!--                            <i class="icon-arrow-down"></i>-->
<!--                          </button>-->
<!--                        </div>-->

<!--                        <transition name="wsettings">-->
<!--                          <div class="mb-1" v-if="isWMMShow">-->
<!--                            <WMM-->
<!--                              :updatedWlan="updatedWlan"-->
<!--                              :isDisable="isDisable"-->
<!--                              :wlanEditCurrent="wlanEditCurrent"-->
<!--                              :isBackgroundShow="isBackgroundShow"-->
<!--                              :isBestEffortShow="isBestEffortShow"-->
<!--                              :isVideoShow="isVideoShow"-->
<!--                              :isVoiceShow="isVoiceShow"-->
<!--                            ></WMM>-->
<!--                            <div class="mb-1">-->
<!--                              <info-->
<!--                                @clicked="showWMMInfoDetails"-->
<!--                                class="info-tooltip-in-wlans-wmm"-->
<!--                                :content="$t('wmm.infoTooltip')"-->
<!--                              />-->
<!--                              <span class="text-muted">{{ $t('wmm.infoTooltip') }}</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </transition>-->




<!--                </div>-->



                <div class="card-block pt-0">

                  <div class="row">
                    <div class="col-lg-12">











                      </div>

                      <small class="text-danger p-0" v-if="isCiscoModeEnabled">
                        * {{ $t('wlans.notAvailableForCisco') }}
                      </small>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
      <div slot="modal-footer" class="modal-footer">
                        <div class="actions-buttons d-flex justify-content-between w-100" :class="{ 'actions-buttons__operator': isDisable }">

                  <div>
                    <WButton success outline
                      customClass="mr-1"
                      v-if="!isDisable && !isNew"
                      @click="updateWlanFromModal"
                      :disabled="atLeastOneTabHasErrors || isSuitesValidation || !enableSaveChanges"
                    >
                      <span>{{ $t('general.save') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                    <WButton success outline
                      customClass="mr-1"
                      v-if="!isDisable && isNew"
                      @click="createWlanFromModal"
                      :disabled="atLeastOneTabHasErrors || isSuitesValidation"
                    >
                      <span>{{ $t('general.add') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                    <WButton info outline
                      v-if="!isDisable && !isNew"
                      @click="copyWlanFromModal"
                      :disabled="atLeastOneTabHasErrors"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                  </div>
                  <div>
                    <WButton danger outline
                      customClass="mr-1"
                      v-if="!isDisable && !isNew"
                      @click="openDeletingDialog(updatedWlan.id)"
                    >
                      <span>{{ $t('general.delete') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                    <WButton secondary outline
                      @click="closeWLANModal"
                    >
                      <span v-if="isDisable">{{ $t('general.close') }}</span>
                      <span v-else>{{ $t('general.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
      </div>
    </modal>



    <Modal
      v-if="deletingWlanModal"
      title="Delete WLAN"
      class="modal-danger"
      v-model="deletingWlanModal"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteWlan"
      @cancel="cancelDeleting"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('wlans.deleteWLAN') }}</h4>

      <div v-if="wlanObj(wlanIdToDelete)">
        <p>
          {{ $t('wlans.confirmDeleting') }}
          <span class="font-weight-bold">{{ wlanObj(wlanIdToDelete).ssid }}</span>
          ?
        </p>

        <WarningLine class="mb-0">
          <i18n path="wlans.warnStatistics" tag="span" for="wlans.warnStatisticsLoss">
            <span class="font-weight-bold text-danger">{{ $t('wlans.warnStatisticsLoss') }}</span>
          </i18n>
        </WarningLine>

        <WarningLine v-if="wlanObj(wlanIdToDelete).status === 'Active'" class="mt-q mb-0">
          <i18n path="wlans.warnService" tag="span" for="wlans.warnServiceLoss">
            <span class="font-weight-bold text-danger">{{ $t('wlans.warnServiceLoss') }}</span>
          </i18n>
        </WarningLine>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteWlan"
        >
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>
    <Modal
      title="Delete WLAN"
      v-model="deletingWlansModal"
      okText="Confirm"
      class="modal-danger"
      cancelText="Cancel"
      @ok="deleteWlans"
      @cancel="cancelDeleting"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('wlans.deleteWLANs') }}</h4>

      <p>{{ $t('wlans.confirmDeleting') }}?</p>

      <WarningLine class="mb-0">
        <i18n path="wlans.warnStatistics" tag="span" for="wlans.warnStatisticsLoss">
          <span class="font-weight-bold text-danger">{{ $t('wlans.warnStatisticsLoss') }}</span>
        </i18n>
      </WarningLine>

      <WarningLine v-if="hasActiveWlansInSelected" class="mt-q mb-0">
        <i18n path="wlans.warnService" tag="span" for="wlans.warnServiceLoss">
          <span class="font-weight-bold text-danger">{{ $t('wlans.warnServiceLoss') }}</span>
        </i18n>
      </WarningLine>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteWlans"
        >
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>

    <Modal title="Tags" v-model="wlanTagsModal" effect="fade/zoom" v-if="updatedWlan && wlanTagsModal">
      <h4 slot="modal-title" class="modal-title">{{ $t('general.tags') }}</h4>
      <div class="modal-tags">
        <p class="h5">{{ $t('tags.currentTags') }}:</p>
        <span
          v-for="(tag, index) in updatedWlan.tags"
          v-if="updatedWlan.tags && updatedWlan.tags.length"
          class="tag tag--item mr-q"
          @click.prevent="removeTag"
          :data-index="index"
        >
          <i class="fa fa-tag"></i>
          <i class="fa fa-remove"></i>
          {{ tag }}
        </span>
        <div class="input-group mt-2">
          <input
            type="text"
            name="tag"
            class="form-control input-tag"
            placeholder="Add tag"
            :disabled="isDisable"
            v-validate="'max:15'"
            data-vv-validate-on="input"
            v-model="newTag"
            :class="{ input: true, 'is-danger': errors.has('tag') }"
          />
          <span class="input-group-btn">
            <WButton success
              @click="addTag"
              :disabled="(this.newTag == '')"
            >
              {{ $t('general.add') }}
            </WButton>
          </span>
        </div>
        <span v-show="errors.has('tag')" class="help is-danger is-danger__block">{{ errors.first('tag') }}</span>
        <div class="tags__availabel mt-1">
          <p class="h6">{{ $t('tags.availlableTags') }}:</p>
          <span v-for="tag in wlansTagsCurrentList" class="tag tag--add mr-q" @click="addExistTag(tag)">
            <i class="fa fa-tag"></i>
            <i class="fa fa-plus"></i>
            {{ tag }}
          </span>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="wlanTagsModal = false"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>

    <Modal
      title="Delete Common Tags"
      class="modal-danger"
      v-model="commonDeletingTagsModal"
      @ok="deleteCommonTags"
      @cancel="cancelDeleteCommonTags"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('wlans.deleteTagsModalTitile') }}</h4>
      {{ $t('wlans.deleteTagsModalText') }}
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleteCommonTags"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteCommonTags"
        >
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>
    <Modal title="Show WMM Info" class="modal-info" v-model="showingWMMInfoDetailsModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('wmm.modalInfoTitle') }}</h4>
      <WMMInfo></WMMInfo>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="showingWMMInfoDetailsModal = false"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>

    <Modal title="About Drop Reason" class="modal-info" v-model="showingDropReasonModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('wlans.signalDropReason') }}</h4>
      <div>
        <div>
          <span>{{ $t('wlans.signalDropReasonMoreInfoForModal') }}:</span>
        </div>
        <div>
          <span>
            <a href="https://blogs.arubanetworks.com/industries/802-11-reason-codes-and-status-codes/">
              https://blogs.arubanetworks.com/industries/802-11-reason-codes-and-status-codes/
            </a>
          </span>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="showingDropReasonModal = false"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>





    <Change-location
      :type="'wlan'"
      :list="selectedWlansId"
      :isChangeLocationOpen="isChangeLocationOpen"
      :commonService="commonService"
      :massLocationId="massLocationId"
      :locationsList="locationsList"
      :changeLocations="changeLocations"
      :cancelChangeLocation="cancelChangeLocation"
    ></Change-location>
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { modifyWLANObjectFromAPIBeforeShowInUI, copyAndModifyWLANObjectBeforeSendToAPI } from '../helpers';
import Multiselect from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
import ChangeLocation from '@/components/general/modal/ChangeLocation.vue';
import commonService from '@/services/commonService';
import Filters from '@/filters';
import helpers from '@/helpers';
import hotspotService from '@/services/hotspotService';
import Modal from '@/components/Modal.vue';
import radiusService from '@/services/radiusService';
import redirectsService from '@/services/redirectsService';
import WarningLine from '@/components/warning-line.vue';
import wlanService from '@/services/wlanService';
import wlansPollingService from '@/services/wlansPollingService';
import WMM from '@/components/WLANs/WMM.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import PasswordGenerator from '@/components/PasswordGenerator.vue';
import Info from '@/components/Universal/info-icon.vue';
import WMMInfo from '@/components/WLANs/WMMInfo.vue';
import WlanQRCodeGenerator from '@/components/WLANs/WlanQRCodeGenerator.vue';
import Alert from '@/components/Universal/alert/alert.vue';
import locationService from '@/services/locationService';
import SelectComponentV2 from '@/components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '@/components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import Option82RidTypeSelector from '@/components/WLANs/Option82RidTypeSelector.vue';

import { WlansTable } from './components';

import { intervalUpdateModeMixin, debounceMixin } from '@/mixins';
import { ppskSwitcherHandlerMixin, wlanSettingsVisibilityMixin } from '../mixins';

import { VLANHelpers } from '@/helpers/VLAN';
import cpeService from "../../../services/cpeService";

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

// const WPA3_TYPES = ['wpa3personal', 'wpa3enterprise', 'wpa23personal', 'wpa23enterprise']
const OPTION_82_STATES = { ENABLE: true, DISABLE: false };

export default {
  name: 'Wlans',
  components: {
    WlanQRCodeGenerator,
    Multiselect,
    Modal,
    WMM,
    ChangeLocation,
    WarningLine,
    SwitchComponent,
    PasswordGenerator,
    Info,
    WMMInfo,
    Alert,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors,
    Option82RidTypeSelector,
    WlansTable
  },
  mixins: [intervalUpdateModeMixin, ppskSwitcherHandlerMixin, wlanSettingsVisibilityMixin, debounceMixin],
  data() {
    return {
      experimentalFTImprovements: false, // v-model для свитчера experimentalFTImprovement
      directRRBExchange: false, // v-model для свитчера directRRBExchange
      loadingDataForApsForDirectRRBExchange: false,
      apsForDirectRRBExchangeOptions: [], // список опций для выбора в мультиселекторе APForRRBExchange
      wmmTabInModalHasErrors: false,
      dynamicVlansString: '',
      stateWasRestored: false,
      dhcpOption82Enabled: false,
      // dhcpOption82CircuitId: '',
      // dhcpOption82InnerBlockShow: false,
      dhcpOption82BlockShow: false,
      isNew: false,
      dataTrafficManagementBlocksShow: {
        localSwitching: false,
        centralSwitching: false,
        tunneling: false
      },
      wlanModalShowing: false,
      modalTab: 'General', // General, Security, Advanced
      securityTab: 'L2',
      advancedTab: 'DataTrafficManagement',
      showLocationFilter: true,
      locationInFiltersData: [],
      showGoBackToWlansStatisticButton: false,
      showGoBackToClientsButton: false,
      showingDropReasonModal: false,
      typesOfProto: [
        {
          name: 'l2tpv3',
          value: 'l2tpv3'
        },
        {
          name: 'gretap',
          value: 'gretap'
        }
      ],
      typesOfProtoForTunnelingBlock: [
        {
          name: 'gretap',
          value: 'gretap'
        }
      ],
      showingWMMInfoDetailsModal: false,
      addTagsModal: false,
      allChecked: false,
      canLoadMore: true,
      commonDeletingTagsModal: false,
      delayTimer: null,
      deletingWlanModal: false,
      deletingWlansModal: false,
      enableSaveChanges: false,
      sortParams: { field: 'ssid', type: 'asc' },
      filters: {
        query: '',
        securityFilter: '',
        statusFilter: '',
        tag: 'All tags',
        location: 'All locations',
        with_childs: false,
        dataTrafficManagementType: null,
        sort_by: {
          order: 1,
          field: 'ssid'
        }

      },
      filterList: {
        blacklist: [],
        whitelist: []
      },
      filteredWlans: [],
      isAccessShow: false,
      isBackgroundShow: false,
      isBestEffortShow: false,
      isChangeLocationOpen: false,
      isEditVisible: false,
      isMobilityShow: false,
      isNatActive: false,
      isRadiusAccShow: false,
      isRadiusAVPsShow: false,
      isSecurityShow: false,
      isSpeedControlShow: false,
      isTunnelShow: false,
      isVideoShow: false,
      isVoiceShow: false,
      isWMMShow: false,
      logSettingsData: {
        radius_acct_interval: ''
      },
      massLocationId: '' || this.$store.state.userData.base_location,
      newMacAddress: '',
      newTag: '',
      newTags: [],
      radiusItems: {},
      searchSpinner: '',
      searchValue: '',
      selectedWlans: [],
      selectedWlansId: [],
      showColumnSwitcher: false,
      showWlanPass: false,
      tempWlanAuthRadius: {
        security: {
          data: []
        }
      },
      tempWlanRadius: [],
      totalResult: false,
      updatedWlan: false,
      updatedWlanRadiusAccounting: [],
      updatedWlanDefaultTunnel: '',
      updatedWlanRadiusAuthentication: [],
      updatedWlanRadiuses: [],
      updatedWlanRadiusMAC: [],
      updatedWlanApsForDirectRRBExchange: [], // список выбранных в мультиселекторе APForRRBExchange точек
      wlanIdToDelete: '',
      wlansForEdit: {},
      wlanTagsModal: false,
      // pagination
      offset: 0,
      limit: 10
    };
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters(['isCiscoModeEnabled', 'isExperimentalModeEnabled']),
    ...WLCProductMapGetters(['isWLCProduct']),
    /**
     * Услиовие для отображения (или же нет) поля для ввода  Dynamic VLAN .
     * "Параметр должен быть доступен для настройки, только в том случае, если ранее пользователь включил параметр AAA Override.
     * В противном случае, параметр должен быть скрыт"
     * @returns {boolean}
     */
    showDynamicVLANInputField() {
      return this.updatedWlan.aaa_override && this.showAAAOverrideSwitcher;
    },
    /**
     * Услиовие для отображения (или же нет) элементов в блоке Экспериментальные улучшения роуминга
     * Отображается если включен свитчер Экспериментальные улучшения роуминга
     * @returns {boolean}
     */
    isExperimentalFTImprovementsBlockShowing() {
      return this.experimentalFTImprovements;
    },
    /**
     * Услиовие для отображения (или же нет) поля для выбора ТД для обмен RRB между точками в зависмости от состояня свитчера Прямой обмен RRB между точками доступа
     * Отображается если включен свитчер Экспериментальные улучшения роуминга
     * @returns {boolean}
     */
    isAPForRRBExchangeShowing() {
      return this.directRRBExchange;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера AAAOverride.
     * "Параметр должен быть доступен для настройки, только в том случае, если ранее на вкладке Безопасность → Слой 2 пользователь
     * заполнил поле Radius или MAC Radius авторизация. В противном случае, параметр должен быть скрыт"
     * +
     * этот свитчер (и все остальные поля Dynamic VLANS)
     * не должен быть доступен в сборках ONE  https://wimark.kaiten.ru/space/327612/card/35643537

     * @returns {boolean}
     */
    showAAAOverrideSwitcher() {
      if (!this.isWLCProduct) {
        // если это не WLC то значит это ONE, тогда сразу не показываем этот свитчер
        return false;
      }
      if ((Array.isArray(this.updatedWlanRadiusMAC) && this.updatedWlanRadiusMAC.length) ||
        (Array.isArray(this.updatedWlanRadiusAuthentication) && this.updatedWlanRadiusAuthentication.length)) {
        return true;
      }
      return false;
    },
    /**
     * Услиовие для отображения (или же нет) поля для выбора RADIUS серверов для ppsk. Только когда свитчер ppsk включен
     * @returns {boolean}
     */
    showPPSKRADIUSSelector() {
      if (this.showPPSKSettingSwitcher && this.isPPSKEnabled) {
        return true;
      }
      return false;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера ppsk. Только для wlc сборки (ну и для experemental), для сетей personal.
     * @returns {boolean}
     */
    showPPSKSettingSwitcher() {
      if ((this.isWLCProduct || this.isExperimentalModeEnabled) &&
        this.updatedWlan && this.updatedWlan.security && this.updatedWlan.security.type.indexOf('personal') !== -1) {
        return true;
      }
      return false;
    },
    /**
     * Правила для валидации VLAN поля для ввода
     * Зависит от сборки (WLC или нет) и включения или нет опции aaa_override
     * @returns {Object}
     */
    vlanFieldValidtaionRules() {
      if (this.isWLCProduct && this.updatedWlan.aaa_override) {
        return {
          required: true,
          numeric: true,
          between: [VLANHelpers.getVLANBoundariesForWLCWithAAAOverrideOn().min, VLANHelpers.getVLANBoundariesForWLCWithAAAOverrideOn().max]
        };
      }
      return {
        numeric: true,
        between: [VLANHelpers.getVLANBoundaries().min, VLANHelpers.getVLANBoundaries().max]
      };
    },
    isPPSKEnabled() {
      if (this.updatedWlan && this.updatedWlan.ppsk === true) {
        return true;
      }
      return false;
    },
    option82States() {
      return OPTION_82_STATES;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Показывает есть ли ошибки валидации хотя бы в одном табе
     *
     * @return {boolean}
     */
    atLeastOneTabHasErrors() {
      return this.generalTabHasErrors || this.securityTabHasErrors || this.advancedTabHasErrors;
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Основное
     *
     * @return {boolean}
     */
    generalTabHasErrors() {
      return this.errors.has('ssid');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Безопасность
     *
     * @return {boolean}
     */
    securityTabHasErrors() {
      return this.securityL2TabHasErrors || this.securityL3TabHasErrors || this.securityAAATabHasErrors;
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Безопасность - Слой 2
     *
     * @return {boolean}
     */
    securityL2TabHasErrors() {
      return this.errors.has('psk_password') || this.errors.has('RADIUS Authentication') ||
        this.errors.has('suites');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Безопасность - Слой 3
     *
     * @return {boolean}
     */
    securityL3TabHasErrors() {
      return this.errors.has('mac');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Безопасность - AAA
     *
     * @return {boolean}
     */
    securityAAATabHasErrors() {
      return this.errors.has('RSNA');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Дополнительно
     *
     * @return {boolean}
     */
    advancedTabHasErrors() {
      return this.advancedDataTrafficManagementTabHasErrors || this.advancedDHCPOption82TabHasErrors
        || this.advancedSpeedControlTabHasErrors || this.advancedMobilitySettingsHasErrors
        || this.advancedWMMHasErrors;
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Дополнительно - Управление трафиком данных
     *
     * @return {boolean}
     */
    advancedDataTrafficManagementTabHasErrors() {
      return this.errors.has('nat_network_ipaddr') || this.errors.has('nat_network_netmask')
        || this.errors.has('vlan') || this.errors.has('DynamicVLAN')
        || this.errors.has('Interface') || this.errors.has('proto') || this.errors.has('peerAddress');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Дополнительно - DHCP Option 82
     *
     * @return {boolean}
     */
    advancedDHCPOption82TabHasErrors() {
      // на вкладке нет никаких полей с валидаторами, поэтому пока всегда false
      return false;
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Дополнительно - Ограничение скорости
     *
     * @return {boolean}
     */
    advancedSpeedControlTabHasErrors() {
      return this.errors.has('speed_download') || this.errors.has('speed_upload');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Дополнительно - Настройки роуминга
     *
     * @return {boolean}
     */
    advancedMobilitySettingsHasErrors() {
      return this.errors.has('rssi_threshold') || this.errors.has('signal_stay')
        || this.errors.has('signal_strikes') || this.errors.has('signal_poll_time')
        || this.errors.has('signal_drop_reason') || this.errors.has('APForRRBExchange');
    },
    /**
     * Показывает есть ли ошибки валидации во вкладке Дополнительно - WMM
     *
     * @return {boolean}
     */
    advancedWMMHasErrors() {
      return this.wmmTabInModalHasErrors;
    },
    currentPageNumber() {
      return this.offset >= this.totalResult
        ? 1
        : Number.parseInt(this.offset / this.limit, 10) + 1;
    },

    redirectOperations() {
      return this.$store.state.redirectOperations;
    },
    radiusOperations() {
      return this.$store.state.radiusOperations;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    wpa3Types() {
      // return WPA3_TYPES;
      return this.$store.getters.getWPA3Types;
    },
    interfacesWithDetailsFromAllHostsAsArray() {
      return Object.values(this.createInterfacesWithDetailsFromAllHostsList(this.hostsList));
    },
    interfacesWithDetailsFromAllHostsAsObjectWithIdKeys() {
      return this.createInterfacesWithDetailsFromAllHostsList(this.hostsList);
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
        this.filters.query !== '' || this.filters.securityFilter !== '' ||
          this.filters.statusFilter !== '' || this.filters.tag !== 'All tags' || this.searchValue !== '' ||
          this.filters.dataTrafficManagementType !== null
      );
    },
    isShowLoadBalancingSwitcher() {
      return this.$store.getters.isShowLoadBalancingSwitcher;
    },
    updatedWlanIdsInOperations() {
      return this.$store.state.updatedWlanIdsInOperations;
    },
    isCurrentCpeUpdating() {
      try {
        // console.log(this.updatedWlanIdsInOperations);
        // console.log(this.$store.state.editWlanId)
        if (
          this.updatedWlanIdsInOperations.length > 0 &&
          this.updatedWlanIdsInOperations.includes(this.$store.state.editWlanId)
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isBssTransitionBadge() {
      if (
        Object.prototype.hasOwnProperty.call(this.wlanEditCurrent, 'bss_transition') &&
        this.wlanEditCurrent.bss_transition === true
      ) {
        return true;
      }
      return false;
    },
    isWnmSleepModeForBadge() {
      if (
        Object.prototype.hasOwnProperty.call(this.wlanEditCurrent, 'wnm_sleep_mode') &&
        this.wlanEditCurrent.wnm_sleep_mode === true
      ) {
        return true;
      }
      return false;
    },
    isShowIeee80211vBadge() {
      if (
        Object.prototype.hasOwnProperty.call(this.wlanEditCurrent, 'ieee80211v') &&
        this.wlanEditCurrent.ieee80211v === true
      ) {
        return true;
      }
      return false;
    },

    isRrmBeaconReportEnabledForBadge() {
      if (
        Object.prototype.hasOwnProperty.call(this.wlanEditCurrent, 'rrm_beacon_report') &&
        this.wlanEditCurrent.rrm_beacon_report === true
      ) {
        return true;
      }
      return false;
    },
    isRrmNeighborReportEnabledForBadge() {
      if (
        Object.prototype.hasOwnProperty.call(this.wlanEditCurrent, 'rrm_neighbor_report') &&
        this.wlanEditCurrent.rrm_neighbor_report === true
      ) {
        return true;
      }
      return false;
    },
    isShowIeee80211kBadge() {
      if (
        Object.prototype.hasOwnProperty.call(this.wlanEditCurrent, 'ieee80211k') &&
        this.wlanEditCurrent.ieee80211k === true
      ) {
        return true;
      }
      return false;
    },
    window() {
      return window;
    },
    commonService() {
      return commonService;
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },
    // editVisible() {
    //   if (this.selectedWlansId.length === 0) {
    //     return typeof this.$store.state.editWlanIndex === 'string' && this.$store.state.editWlanIndex.length;
    //   }
    //   return true;
    // },

    editVisible() {
      return false;
    },

    isWlansOperations() {
      const { wlanOperations } = this.$store.state;

      return Boolean(wlanOperations.length);
    },
    isWlansLoading() {
      return this.$store.state.loadingWlans;
    },
    isLoadingAll() {
      return this.isWlansLoading || this.$store.state.loadingRadius ||
        this.$store.state.loadingHotspots || this.$store.state.loadingRedirects;
    },
    isSuitesValidation() {
      return false;
    },
    editWlanId() {
      if (!this.selectedWlansId.length) {
        return this.$store.state.editWlanId;
      }
    },
    muteEdit() {
      return this.$store.state.muteWlanEdit;
    },
    wlansList() {
      const list = this.$store.state.wlansList;

      if (!list) {
        return [];
      }

      return list;
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    },
    radiusList() {
      return this.$store.state.radiusList;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    hotspotsList() {
      return this.$store.state.hotspotsList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    hideCommonEdit() {
      return this.addTagsModal;
    },
    wlansTagsCurrentList() {
      return this.$store.state.wlansTagsList;
    },
    wlansMultipleTagsList() {
      const wlansTagsList = this.$store.state.wlansTagsList.filter((tag) => {
        if (this.newTags.includes(tag)) {
          return false;
        }
        return true;
      });
      return wlansTagsList;
    },
    // wlanEditCurrent() {
    //   let sameWlan = false;
    //
    //   const { wlansList } = this.$store.state;
    //   let currentWlan;
    //   wlansList.forEach((wlan) => {
    //     if (wlan.id == this.$store.state.editWlanId) {
    //       currentWlan = wlan;
    //     }
    //   });
    //   if (!currentWlan) {
    //     return;
    //   }
    //
    //   if (this.updatedWlan && this.updatedWlan.id === this.editWlanId) {
    //     sameWlan = true;
    //   }
    //
    //   // чтобы свитчер load_balancing корректно работал в интерфейсе и не сыпались ошибки в консоль,
    //   // пока поле load_balancing не начало прилетать с бэка
    //   if (!Object.prototype.hasOwnProperty.call(currentWlan, 'load_balancing')) {
    //     // console.log('no field load_balancing');
    //     // currentWlan.load_balancing = false;
    //     this.$set(currentWlan, 'load_balancing', false);
    //   }
    //
    //   // // Возможно эта проверка избыточна
    //   // if (!sameWlan) {
    //   //   this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
    //   // }
    //   this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
    //
    //   // if (this.updatedWlan.vlan === 0) {
    //   //   this.updatedWlan.vlan = '';
    //   // }
    //   this.filterList.whitelist = JSON.parse(JSON.stringify(this.updatedWlan.whitelist));
    //   this.filterList.blacklist = JSON.parse(JSON.stringify(this.updatedWlan.blacklist));
    //   if (Object.keys(this.updatedWlan).length) {
    //     if (!this.updatedWlan.security.data) {
    //       this.updatedWlan.security.data = {
    //         suites: ['aes'],
    //         psk: '',
    //         radiusauthentication: []
    //       };
    //     } else if (this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
    //       const newRadiusArray = [];
    //
    //       this.updatedWlan.security.data.radiusauthentication.forEach((radiusId) => {
    //         this.radiusList.forEach((radiusObject) => {
    //           if (radiusObject.id === radiusId) {
    //             newRadiusArray.push(radiusObject);
    //           }
    //         });
    //       });
    //       this.updatedWlanRadiuses = newRadiusArray;
    //     }
    //
    //     this.updatedWlanRadiusAccounting = helpers.combineObjectsArrayFromIdsArray(
    //       this.updatedWlan.radius_acct_servers,
    //       this.radiusList
    //     );
    //     this.updatedWlanRadiusMAC = helpers.combineObjectsArrayFromIdsArray(
    //       this.updatedWlan.guest_control.mac_radius_auth_servers,
    //       this.radiusList
    //     );
    //     if (this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
    //       this.updatedWlanRadiusAuthentication = helpers.combineObjectsArrayFromIdsArray(
    //         this.updatedWlan.security.data.radiusauthentication,
    //         this.radiusList
    //       );
    //     }
    //
    //     if (this.updatedWlan.wmm.categories) {
    //       for (const category in this.updatedWlan.wmm.categories) {
    //         for (const config in this.updatedWlan.wmm.categories[category]) {
    //           if (!this.updatedWlan.wmm.categories[category][config] && config !== 'cli_acm') {
    //             this.updatedWlan.wmm.categories[category][config] = '';
    //           }
    //         }
    //       }
    //     }
    //   }
    //
    //   return currentWlan;
    // },
    // wlanEditCurrent() {
    //   let sameWlan = false;
    //
    //   const { wlansList } = this.$store.state;
    //   let currentWlan;
    //   wlansList.forEach((wlan) => {
    //     if (wlan.id == this.$store.state.editWlanId) {
    //       currentWlan = wlan;
    //     }
    //   });
    //   if (!currentWlan) {
    //     return;
    //   }
    //
    //   if (this.updatedWlan && this.updatedWlan.id === this.editWlanId) {
    //     sameWlan = true;
    //   }
    //
    //   // чтобы свитчер load_balancing корректно работал в интерфейсе и не сыпались ошибки в консоль,
    //   // пока поле load_balancing не начало прилетать с бэка
    //   if (!Object.prototype.hasOwnProperty.call(currentWlan, 'load_balancing')) {
    //     // console.log('no field load_balancing');
    //     // currentWlan.load_balancing = false;
    //     this.$set(currentWlan, 'load_balancing', false);
    //   }
    //
    //   // // Возможно эта проверка избыточна
    //   // if (!sameWlan) {
    //   //   this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
    //   // }
    //
    //   // this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
    //
    //   if (
    //     (currentWlan && !this.updatedWlan) ||
    //     (currentWlan && this.updatedWlan && this.updatedWlan.id !== currentWlan.id)
    //   ) {
    //     // не переопределеяем this.updatedWlan если мы находимся в модалки WLAN после завершения выполенния операции изменения другой модалке
    //     // без этого после завершения операции все  изменнеия, внесеннные в модалку wlan сбрасывалаись
    //     this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
    //     this.setUpdatedWlanDataTrafficManagementType(this.updatedWlan);
    //     this.dhcpOption82BlockShow = false;
    //     this.securityTab = 'L2';
    //   }
    //
    //
    //   if (!Object.prototype.hasOwnProperty.call(this.updatedWlan, 'option82state')) {
    //     this.$set(this.updatedWlan, 'option82state', this.option82States.DISABLE)
    //   }
    //   if (this.updatedWlan.option82state === this.option82States.DISABLE) {
    //     this.dhcpOption82Enabled = false;
    //   } else if (this.updatedWlan.option82state === this.option82States.ENABLE) {
    //     this.dhcpOption82Enabled = true;
    //   } else {
    //     this.dhcpOption82Enabled = false;
    //   }
    //
    //   // if (this.updatedWlan.vlan === 0) {
    //   //   this.updatedWlan.vlan = '';
    //   // }
    //   this.filterList.whitelist = JSON.parse(JSON.stringify(this.updatedWlan.whitelist));
    //   this.filterList.blacklist = JSON.parse(JSON.stringify(this.updatedWlan.blacklist));
    //   if (Object.keys(this.updatedWlan).length) {
    //     if (!this.updatedWlan.security.data) {
    //       this.updatedWlan.security.data = {
    //         suites: ['aes'],
    //         psk: '',
    //         radiusauthentication: []
    //       };
    //     } else if (this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
    //       const newRadiusArray = [];
    //
    //       this.updatedWlan.security.data.radiusauthentication.forEach((radiusId) => {
    //         this.radiusList.forEach((radiusObject) => {
    //           if (radiusObject.id === radiusId) {
    //             newRadiusArray.push(radiusObject);
    //           }
    //         });
    //       });
    //       this.updatedWlanRadiuses = newRadiusArray;
    //     }
    //
    //     this.updatedWlanRadiusAccounting = helpers.combineObjectsArrayFromIdsArray(
    //       this.updatedWlan.radius_acct_servers,
    //       this.radiusList
    //     );
    //
    //     const currentDefaultTunnel = this.updatedWlan.default_tunnel;
    //     // this.updatedWlanDefaultTunnel = {};
    //     this.$set(this, 'updatedWlanDefaultTunnel', '');
    //     if (currentDefaultTunnel && currentDefaultTunnel !== '') {
    //       const currentDefaultTunnelAsObject = this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[currentDefaultTunnel];
    //       if (currentDefaultTunnelAsObject) {
    //         this.updatedWlanDefaultTunnel = JSON.parse(JSON.stringify(currentDefaultTunnelAsObject));
    //       } else {
    //         this.updatedWlanDefaultTunnel = {
    //           name: this.updatedWlan.default_tunnel,
    //           nameWithType: this.updatedWlan.default_tunnel
    //         }
    //       }
    //     }
    //
    //
    //     this.updatedWlanRadiusMAC = helpers.combineObjectsArrayFromIdsArray(
    //       this.updatedWlan.guest_control.mac_radius_auth_servers,
    //       this.radiusList
    //     );
    //     if (this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
    //       this.updatedWlanRadiusAuthentication = helpers.combineObjectsArrayFromIdsArray(
    //         this.updatedWlan.security.data.radiusauthentication,
    //         this.radiusList
    //       );
    //     }
    //
    //     // if (this.updatedWlan.wmm.categories) {
    //     //   for (const category in this.updatedWlan.wmm.categories) {
    //     //     for (const config in this.updatedWlan.wmm.categories[category]) {
    //     //       if (!this.updatedWlan.wmm.categories[category][config] && config !== 'cli_acm') {
    //     //         this.updatedWlan.wmm.categories[category][config] = '';
    //     //       }
    //     //     }
    //     //   }
    //     // }
    //     setTimeout(() => {
    //       // Хак для фикса бага из задачи WNE-2377
    //       // За счет вызова setTimeout 0 выполняем данное действие в очереди макрозадач.
    //       // Поэтому это действие выполняется позже основного "тика" и все работет.
    //       if (this.updatedWlan.wmm.categories) {
    //         for (const category in this.updatedWlan.wmm.categories) {
    //           for (const config in this.updatedWlan.wmm.categories[category]) {
    //             if (!this.updatedWlan.wmm.categories[category][config] && config !== 'cli_acm') {
    //               this.updatedWlan.wmm.categories[category][config] = '';
    //             }
    //           }
    //         }
    //       }
    //     }, 0);
    //   }
    //
    //   return currentWlan;
    // },

    wlanEditCurrent() {
      let sameWlan = false;

      const { wlansList } = this.$store.state;
      let currentWlan;
      if (!this.isNew) {
        wlansList.forEach((wlan) => {
          if (wlan.id == this.$store.state.editWlanId) {
            currentWlan = wlan;
          }
        });
      } else {
        if (!this.updatedWlan){
          currentWlan = this.getClearNewWlanDataObject();
        }else {
          currentWlan = JSON.parse(JSON.stringify(this.updatedWlan))
        }

      }
      if (!currentWlan) {
        return;
      }

      if (this.updatedWlan && this.updatedWlan.id === this.editWlanId) {
        sameWlan = true;
      }

      // чтобы свитчер load_balancing корректно работал в интерфейсе и не сыпались ошибки в консоль,
      // пока поле load_balancing не начало прилетать с бэка
      if (!Object.prototype.hasOwnProperty.call(currentWlan, 'load_balancing')) {
        // console.log('no field load_balancing');
        // currentWlan.load_balancing = false;
        this.$set(currentWlan, 'load_balancing', false);
      }

      // // Возможно эта проверка избыточна
      // if (!sameWlan) {
      //   this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
      // }

      // this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));

      if (
        (currentWlan && !this.updatedWlan) ||
        (currentWlan && this.updatedWlan && this.updatedWlan.id !== currentWlan.id)
      ) {
        // не переопределеяем this.updatedWlan если мы находимся в модалки WLAN после завершения выполенния операции изменения другой модалке
        // без этого после завершения операции все  изменнеия, внесеннные в модалку wlan сбрасывалаись
        this.updatedWlan = JSON.parse(JSON.stringify(currentWlan));
        this.setUpdatedWlanDataTrafficManagementType(this.updatedWlan);
        this.dhcpOption82BlockShow = false;
        if (!this.securityTab){
          this.securityTab = 'L2';
        }

      }


      if (!Object.prototype.hasOwnProperty.call(this.updatedWlan, 'option82state')) {
        this.$set(this.updatedWlan, 'option82state', this.option82States.DISABLE)
      }
      if (this.updatedWlan.option82state === this.option82States.DISABLE) {
        this.dhcpOption82Enabled = false;
      } else if (this.updatedWlan.option82state === this.option82States.ENABLE) {
        this.dhcpOption82Enabled = true;
      } else {
        this.dhcpOption82Enabled = false;
      }

      // TODO удалить закоменченую старую реализацию если не появится багов (28.03.24)
      // // Из-за этого самым непредсказуемым образом  мутирует объект this.whitelist и this.blacklist
      // // и иногда очищается или наоборот не очищется в самое неожиданное время
      // // Вынес эту логику  в функцию initDataFieldsForModal, вызываемую при открытии модалки
      // this.$set(this, 'filterList', {});
      // this.$set(this.filterList, 'whitelist', JSON.parse(JSON.stringify(this.updatedWlan.whitelist)));
      // this.$set(this.filterList, 'blacklist', JSON.parse(JSON.stringify(this.updatedWlan.blacklist)));
      if (Object.keys(this.updatedWlan).length) {
        if (!this.updatedWlan.security.data) {
          this.updatedWlan.security.data = {
            suites: ['aes'],
            psk: '',
            radiusauthentication: []
          };
        } else if (this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
          const newRadiusArray = [];

          this.updatedWlan.security.data.radiusauthentication.forEach((radiusId) => {
            this.radiusList.forEach((radiusObject) => {
              if (radiusObject.id === radiusId) {
                newRadiusArray.push(radiusObject);
              }
            });
          });
          this.updatedWlanRadiuses = newRadiusArray;
        }

        // TODO удалить закоменченую старую реализацию если не появится багов (28.03.24)
        // // Из-за этого самым непредсказуемым образом  мутирует объект this.updatedWlanRadiusAccounting
        // // и иногда очищается или наоборот не очищется в самое неожиданное время
        // // Вынес эту логику  в функцию initDataFieldsForModal, вызываемую при открытии модалки
        // this.updatedWlanRadiusAccounting = helpers.combineObjectsArrayFromIdsArray(
        //   this.updatedWlan.radius_acct_servers,
        //   this.radiusList
        // );

        const currentDefaultTunnel = this.updatedWlan.default_tunnel;
        // this.updatedWlanDefaultTunnel = {};
        this.$set(this, 'updatedWlanDefaultTunnel', '');
        if (currentDefaultTunnel && currentDefaultTunnel !== '') {
          const currentDefaultTunnelAsObject = this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[currentDefaultTunnel];
          if (currentDefaultTunnelAsObject) {
            this.updatedWlanDefaultTunnel = JSON.parse(JSON.stringify(currentDefaultTunnelAsObject));
          } else {
            this.updatedWlanDefaultTunnel = {
              name: this.updatedWlan.default_tunnel,
              nameWithType: this.updatedWlan.default_tunnel
            }
          }
        }

        // // Из-за этого самым непредсказуемым образом  мутирует объект this.updatedWlanRadiusMAC
        // // и иногда очищается или наоборот не очищется в самое неожиданное время
        // // Вынес эту логику  в функцию initDataFieldsForModal, вызываемую при открытии модалки
        // this.updatedWlanRadiusMAC = helpers.combineObjectsArrayFromIdsArray(
        //   this.updatedWlan.guest_control.mac_radius_auth_servers,
        //   this.radiusList
        // );

        // // Из-за этого самым непредсказуемым образом  мутирует объект this.updatedWlanRadiusAuthentication
        // // и иногда очищается или наоборот не очищется в самое неожиданное время
        // // Вынес эту логику  в функцию initDataFieldsForModal, вызываемую при открытии модалки
        // if (this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
        //   this.updatedWlanRadiusAuthentication = helpers.combineObjectsArrayFromIdsArray(
        //     this.updatedWlan.security.data.radiusauthentication,
        //     this.radiusList
        //   );
        // }


        // if (this.updatedWlan.wmm.categories) {
        //   for (const category in this.updatedWlan.wmm.categories) {
        //     for (const config in this.updatedWlan.wmm.categories[category]) {
        //       if (!this.updatedWlan.wmm.categories[category][config] && config !== 'cli_acm') {
        //         this.updatedWlan.wmm.categories[category][config] = '';
        //       }
        //     }
        //   }
        // }
        // TODO удалить закоменченую старую реализацию очисиски WMM если не появится багов (17.07.24)
        // Удалено в процессе задачи https://wimark.kaiten.ru/space/327612/card/35693179
        // setTimeout(() => {
        //   // Хак для фикса бага из задачи WNE-2377
        //   // За счет вызова setTimeout 0 выполняем данное действие в очереди макрозадач.
        //   // Поэтому это действие выполняется позже основного "тика" и все работет.
        //   if (this.updatedWlan.wmm.categories) {
        //     for (const category in this.updatedWlan.wmm.categories) {
        //       for (const config in this.updatedWlan.wmm.categories[category]) {
        //         if (!this.updatedWlan.wmm.categories[category][config] && config !== 'cli_acm') {
        //           this.updatedWlan.wmm.categories[category][config] = '';
        //         }
        //       }
        //     }
        //   }
        // }, 0);
      }

      return currentWlan;
    },


    // qrGeneratorSettingsObject() {
    //   // тут формируем объект чтобы передать его в компонент QR кодов
    //   const wlanQRObject = {
    //     ssid: '',
    //     password: '',
    //     encryption: '',
    //     hiddenSSID: ''
    //   };
    //   const wlan = this.wlanEditCurrent;
    //
    //   // ssid
    //   if (wlan.hasOwnProperty('ssid') && wlan.ssid !== '') {
    //     wlanQRObject.ssid = wlan.ssid;
    //   }
    //
    //   // password
    //   // console.log(wlan)
    //   if (wlan.hasOwnProperty('security') &&
    //     wlan.security.hasOwnProperty('data') &&
    //     wlan.security.data !== null &&
    //     wlan.security.data !== undefined &&
    //     wlan.security.data.hasOwnProperty('psk')) {
    //     wlanQRObject.password = wlan.security.data.psk;
    //   }
    //
    //   // hidden
    //   // console.log(wlan)
    //   if (wlan.hasOwnProperty('hidden')) {
    //     wlanQRObject.hiddenSSID = wlan.hidden;
    //   }
    //
    //   // encryption
    //   if (wlan.hasOwnProperty('security') &&
    //     wlan.security.hasOwnProperty('type')) {
    //     const wlanEncryptionType = String(wlan.security.type);
    //     if (wlanEncryptionType === 'open'){
    //       wlanQRObject.encryption = 'None';
    //     }
    //     if (wlanEncryptionType.includes('wpa2')) {
    //       wlanQRObject.encryption = 'WPA';
    //     }
    //   }
    //   // console.log(wlanQRObject);
    //   return wlanQRObject;
    // },
    wlanEditCurrentId() {
      return this.$store.state.editWlanIndex;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    //            userDataLocation() {
    //                this.filters.location = this.$store.state.userData.base_location;
    //                return this.$store.state.userData.base_location;
    //            },
    firewallsList() {
      return this.$store.state.firewallsList;
    },
    hasActiveWlansInSelected() {
      if (!this.selectedWlansId || this.selectedWlansId.length === 0) {
        return false;
      }

      for (const id of this.selectedWlansId) {
        const wlan = commonService.wlanObjbyId(id);
        if (wlan && wlan.status === 'Active') {
          return true;
        }
      }

      return false;
    }
  },
  watch: {
    // todo этот вотчер сам по себе фиксит проблему хаотичной мутации https://wimark.kaiten.ru/34119510
    // причина неизвестна
    // todo требуется глубокий рефакторинг всей логики и компонентной структуры модалки
    updatedWlanRadiusAuthentication(newVal, oldVal) {
    },
    // todo этот вотчер сам по себе фиксит проблему хаотичной мутации https://wimark.kaiten.ru/34119510
    // причина неизвестна
    // todo требуется глубокий рефакторинг всей логики и компонентной структуры модалки
    updatedWlanRadiusMAC(newVal, oldVal) {
    },
    // todo судя по всему тут такая же проблема хаотичной мутации, как и в друих местах (https://wimark.kaiten.ru/34119510)
    // этот вотчер сам по себе фиксит проблему хаотичной мутации
    // причина неизвестна
    // todo требуется глубокий рефакторинг всей логики и компонентной структуры модалки
    updatedWlanApsForDirectRRBExchange(newVal, oldVal) {
    },
    redirectOperations(val, oldVal) {
      try {
        // console.log('val', val)
        // console.log('oldVal', oldVal)
        if (oldVal.length > val.length) {
          console.log('refresh redirect list')
          // если завершилось создание RedirectsRule - перезапрашиваем их список
          // console.log('refresh list')
          redirectsService.getRedirectsRule(this);
        }
      } catch (e) {
        console.log(e);
      }
    },
    radiusOperations(val, oldVal) {
      try {
        // console.log('val', val)
        // console.log('oldVal', oldVal)
        if (oldVal.length > val.length) {
          // если завершилось создание RADIUS - перезапрашиваем их список
          // console.log('refresh list')
          radiusService.getRadius(this);
        }
      } catch (e) {
        console.log(e);
      }
    },
    wlansList() {
      wlansPollingService.startPolling(this);
    },
    selectedWlansId() {
      this.wlansForEdit = {};
      for (const id in this.selectedWlansId) {
        const elem = { [this.selectedWlansId[id]]: {} };
        this.wlansForEdit = Object.assign(this.wlansForEdit, elem);
      }
    },
    // updatedWlan: {
    //   handler(val, oldVal) {
    //     if (!oldVal) {
    //       this.enableSaveChanges = false;
    //     } else if (val && oldVal && val.id !== oldVal.id) {
    //       this.enableSaveChanges = false;
    //     } else if (!val) {
    //       this.enableSaveChanges = false;
    //     } else {
    //       this.enableSaveChanges = true;
    //     }
    //   },
    //   deep: true
    // },
    // updatedWlan: {
    //   handler(val, oldVal) {
    //     // console.log('val', val)
    //     // console.log('oldVal', oldVal)
    //     if (this.stateWasRestored) {
    //       this.enableSaveChanges = true;
    //       return;
    //     }
    //     if (!oldVal) {
    //       this.enableSaveChanges = false;
    //     } else if (val && oldVal && val.id !== oldVal.id) {
    //       this.enableSaveChanges = false;
    //     } else if (!val) {
    //       this.enableSaveChanges = false;
    //     } else {
    //       this.enableSaveChanges = true;
    //     }
    //   },
    //   deep: true
    // },
    updatedWlan: {
      handler(val, oldVal) {
        // console.log('val', val)
        // console.log('oldVal', oldVal)
        if (this.stateWasRestored && val) {
          return;
        }
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    },
    isLoadingAll: {
      handler(val, oldVal) {
        try {
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('wlanIdFormWlanStatisic')) {
            // после загрузки списка WLAN если ранее  перешили в раздел WLAN из статистики по WLAN с передачей определной сети для октрытия открываем ее в панели редактироваания
            if (Array.isArray(this.wlansList) && this.wlansList.length > 0 && this.wlansList.find(wlan => {
              return wlan.id === this.$route.params.wlanIdFormWlanStatisic;
            })) {
              this.$nextTick(() => {
                this.$store.commit('setWlanEditIndex', '0');
                this.$store.commit('setWlansEditId', this.$route.params.wlanIdFormWlanStatisic ?? '');
                 this.openEditWLANModal();
                // и удаляем чтобы этот вотчер больше не отрабатывал если список wlans обновляется (и isLoadingAll меняет состояние)
                delete this.$route.params.wlanIdFormWlanStatisic;
              });
            }
          }

          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('wlanIdFormClients')) {
            // после загрузки списка WLAN если ранее  перешили в раздел WLAN из статистики по WLAN с передачей определной сети для октрытия открываем ее в панели редактироваания
            if (Array.isArray(this.wlansList) && this.wlansList.length > 0 && this.wlansList.find(wlan => {
              return wlan.id === this.$route.params.wlanIdFormClients;
            })) {
              this.$nextTick(() => {
                this.$store.commit('setWlanEditIndex', '0');
                this.$store.commit('setWlansEditId', this.$route.params.wlanIdFormClients ?? '');
                 this.openEditWLANModal();
                // и удаляем чтобы этот вотчер больше не отрабатывал если список wlans обновляется (и isLoadingAll меняет состояние)
                delete this.$route.params.wlanIdFormClients;
              });
            }
          }

        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  methods: {
    setWmmTabInModalHasErrors() {
      this.wmmTabInModalHasErrors = true;
    },
    clearWmmTabInModalHasErrors() {
      this.wmmTabInModalHasErrors = false;
    },
    /**
     * Хендлер вызывается при событии  в компоненет WMM
     *
     */
    wmmValidationErrorsChangedHandler(hasErrors) {
      // console.log(hasErrors);
      if (hasErrors) {
        this.setWmmTabInModalHasErrors()
      } else {
        this.clearWmmTabInModalHasErrors()
      }
    },
    /**
     * Хендлер вызываеймый при вводе чего либо в мультиселекторе точек APForRRBExchange
     *
     *
     */
    async searchCpeWhenSearchChangeHandler(inputed) {
      this.debounce(() => {
        this.searchCpeForApsForDirectRRBExchange(inputed);
      }, 1000)

      //
    },
    /**
     * Хендлер вызываеймый при открытии мультиселектора точек APForRRBExchange
     *
     *
     */
    async searchCpeWhenOpenSelectorListHandler() {
      await this.searchCpeForApsForDirectRRBExchange("");

    },
    /**
     * Функция для поиска точек для использования в качестве опций  в мультиселекторе APForRRBExchange для настроек DirectRRBExchange
     */
    async searchCpeForApsForDirectRRBExchange(query) {
      try {
        this.loadingDataForApsForDirectRRBExchange = true
        const response = await cpeService.requestCompactCpesWithSearch(query,undefined,undefined,100)
        this.apsForDirectRRBExchangeOptions = response.itemslist ?? []
      } finally {
        this.loadingDataForApsForDirectRRBExchange = false
      }
    },
    /**
     * Наполняет поле UpdatedWlanApsForDirectRRBExchange, которое является v-model для селектора ТД APForRRBExchange для настроек DirectRRBExchange
     * На основе ID точек из updatedWlan.r0kh
     *
     */
    async fillUpdatedWlanApsForDirectRRBExchangeWhenModalOpen() {
      const cpeIds = [...this.updatedWlan.r0kh];
      if (!cpeIds.length) return;
      const cpeObjects = await cpeService.asyncGetCompactCpesWithDataRemainingByIds(this, cpeIds);
      const cpeObjectsAsObjWithKeyId = {};
      cpeObjects.forEach((cpe) => {cpeObjectsAsObjWithKeyId[cpe.id] = cpe});
      cpeIds.forEach((cpeId) => {
        // тут проверяе  - если с бэка в ответ на запрос инфы о точках по их ID прилители данные по этому ID
        // то достаем оттуда имя точки и добавляем к списку выбраных точек
        if (Object.prototype.hasOwnProperty.call(cpeObjectsAsObjWithKeyId, cpeId)) {
          this.updatedWlanApsForDirectRRBExchange.push({
            id: cpeId,
            name: cpeObjectsAsObjWithKeyId[cpeId].name
          });
        } else {
          // а если по этому ID ничего не прилетело в ответ (точку удалили), то отображаем вметсо имени ID
          // чтобы юзер видел что что-то выбрано, пусть и без имени
          this.updatedWlanApsForDirectRRBExchange.push({id: cpeId, name: cpeId});
        }
      });
    },
    /**
     * Функция для очистки updatedWlanApsForDirectRRBExchange которое является v-model для селектора ТД для настроек DirectRRBExchange
     */
    clearUpdatedWlanApsForDirectRRBExchange() {
      this.updatedWlanApsForDirectRRBExchange = [];
    },
    /**
     * Выключает все переключатели в блоке ExperimentalFTImprovements
     */
    turnOffAllExperimentalFTImprovementsSubSwitchers() {
      this.updatedWlan.rrb_tunnelling = false;
      this.updatedWlan.rrb_use_seq = false;
      this.directRRBExchange = false;
      this.clearUpdatedWlanApsForDirectRRBExchange();
    },
    /**
     * Если все переключатели в блоке ExperimentalFTImprovements выключены выключает сам переключатель ExperimentalFTImprovements
     */
    checkSubswitchesAndTurnOffExperimentalFTImprovementsSwitcherIfAllSubswitchersDisabled() {
      this.$nextTick(() => {
        if (!this.updatedWlan.rrb_tunnelling && !this.updatedWlan.rrb_use_seq && !this.directRRBExchange ) {
          this.experimentalFTImprovements = false
        }
      })
    },
    /**
     * Хендлер для свитчера RRBSeqcheck
     */
    rrbSeqcheckHandler() {
      this.checkSubswitchesAndTurnOffExperimentalFTImprovementsSwitcherIfAllSubswitchersDisabled()
    },
    /**
     * Хендлер для свитчера RRBTunneling
     */
    rrbTunnelingHandler() {
      this.checkSubswitchesAndTurnOffExperimentalFTImprovementsSwitcherIfAllSubswitchersDisabled()
    },
    /**
     * Хендлер для свитчера  directRRBExchange
     *
     * @param {boolean} value - значение выбраное в свитчере
     */
    directRRBExchangeHandler(value) {
      if (value === false) {
        this.clearUpdatedWlanApsForDirectRRBExchange()
      }
      this.checkSubswitchesAndTurnOffExperimentalFTImprovementsSwitcherIfAllSubswitchersDisabled()
    },
    /**
     * Хендлер для свитчера  experimentalFTImprovements
     *
     * @param {boolean} value - значение выбраное в свитчере
     */
    experimentalFTImprovementsChangeHandler(value) {
      if (value === false) {
        this.turnOffAllExperimentalFTImprovementsSubSwitchers()
      }
      if (value === true) {
        this.updatedWlan.rrb_use_seq = true;
      }
    },
    selectOption82RidTypeHandler(ridType){
      // console.log(ridType)
      // this.updatedWlan.option82rid_type = ridType;
      this.$set(this.updatedWlan, 'option82rid_type', ridType);
    },

    selectOption82CidTypeHandler(cidType) {
      this.$set(this.updatedWlan, 'option82cid_type', cidType);
    },

    vlanForLocalSwitchingInputHandler() {
      if (this.errors.has('vlan')) {
        this.errors.remove('vlan');
      }
    },
    showWLANModal() {
      this.wlanModalShowing = true;
    },
    hideWLANModal() {
      this.wlanModalShowing = false;
    },
    // closeWlanModal(){
    //   this.isNew = false;
    //   this.hideWLANModal();
    // },
    getClearNewWlanDataObject(){
      this.$store.commit('clearNewWlanData');
      const newWlanDataObject = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
      if (!newWlanDataObject.base_location) {
        newWlanDataObject.base_location = this.$store.state.userData.base_location;
      }
      if (!newWlanDataObject.security.type && newWlanDataObject.security.type === '') {
        newWlanDataObject.security.type = 'open';
      }
      // console.log(newWlanDataObject)
      return newWlanDataObject;
    },
    openEditWLANModal() {
      this.modalTab = 'General';
      this.advancedTab = 'DataTrafficManagement'
      // this.updatedWlan = JSON.parse(JSON.stringify(wlanObject))
      this.isNew = false;
      this.initDataFieldsForModal();
      this.showWLANModal();
    },
    openCreateWLANModal() {
      // this.updatedWlan = this.getClearNewWlanDataObject();

      this.modalTab = 'General';
      this.advancedTab = 'DataTrafficManagement'
      this.isNew = true;
      this.initDataFieldsForModal();
      this.showWLANModal();
    },
    clearFieldsBeforeModalOpen() {
      this.clearWmmTabInModalHasErrors();
      this.updatedWlanRadiusAuthentication = [];
      this.updatedWlanRadiusMAC = [];
      this.updatedWlanRadiusAccounting = [];
      this.updatedWlanApsForDirectRRBExchange = []
      this.experimentalFTImprovements = false;
      this.directRRBExchange = false;
      this.filterList = { whitelist: [], blacklist: [] };
      this.dynamicVlansString = '';
    },
    /**
     * Инициализация полей, нужных для работы модалки
     *
     */
    initDataFieldsForModal() {
      this.clearFieldsBeforeModalOpen();
      this.$nextTick(() => {
        // Тут преобразуются некоторые значения из формата, который прилетает с бэка в формат который
        // следует отобразить в UI
        // Например во вкладке Дополнительно - Ограничение скорости конвертим нули, прилетающий с бэка в пустую строку
        // Чтобы если на бэке дефолтное значение 0 в соотвествующих полях модалки отображалась пустая строка

        // мутируем данные внутри объекта, по ссылочке, не переопеределяя this.updatedWlan
        // чтобы лишний раз не перезапускался непредсказуемый, мутирующиий всё хаотично компьютед wlanEditCurrent
        // который зависит от this.updatedWlan и мутирует this.updatedWlan
        modifyWLANObjectFromAPIBeforeShowInUI(this.updatedWlan);

        // Ператащил сюда некоторые вещи из computed'a wlanEditCurrent где оно мутировало самым непредсказуемым образом, неожиданно обнуляясь
        if (this.updatedWlan && this.updatedWlan.guest_control && this.updatedWlan.guest_control.mac_radius_auth_servers) {
          this.updatedWlanRadiusMAC = helpers.combineObjectsArrayFromIdsArray(
            this.updatedWlan.guest_control.mac_radius_auth_servers,
            this.radiusList
          );
        }

        if (this.updatedWlan && this.updatedWlan.security && this.updatedWlan.security.data && this.updatedWlan.security.data.radiusauthentication) {
          this.updatedWlanRadiusAuthentication = helpers.combineObjectsArrayFromIdsArray(
            this.updatedWlan.security.data.radiusauthentication,
            this.radiusList
          );
        }

        if (this.updatedWlan && this.updatedWlan.radius_acct_servers) {
          this.updatedWlanRadiusAccounting = helpers.combineObjectsArrayFromIdsArray(
            this.updatedWlan.radius_acct_servers,
            this.radiusList
          );
        }

        if (this.updatedWlan && this.updatedWlan.whitelist) {
          this.$set(this.filterList, 'whitelist', JSON.parse(JSON.stringify(this.updatedWlan.whitelist)));
        }
        if (this.updatedWlan && this.updatedWlan.blacklist) {
          this.$set(this.filterList, 'blacklist', JSON.parse(JSON.stringify(this.updatedWlan.blacklist)));
        }

        // зполняем поле dynamicVlansString - т.к  прилетает в виде массива, а отображаем в виде строки
        if (this.updatedWlan && this.updatedWlan.vlans && Array.isArray(this.updatedWlan.vlans)) {
          this.dynamicVlansString = VLANHelpers.convertDynamicVlanArrayToStringFormat(this.updatedWlan.vlans);
        }

        // TODO перетащил эту логику с хотспотом в  src/views/WLANS/helpers/wlanObjectModifiers/securityModifiers.js. Удалить этот закомменченный блок отсюда, если ничего неожиданно не сломается (15.07.2024)
        // // hotspot
        // // изначальное значение пустое. Если не поставить, то не будет отображаться 'Нет' в инпуте Hotspot 2.0 профиль
        // // https://wimark.kaiten.ru/34134096
        // if (this.updatedWlan && !this.updatedWlan.security.data.hotspot20_profile) {
        //   this.updatedWlan.security.data.hotspot20_profile = '';
        // }

        // Ставим свитчер directRRBExchange в положение вкл, и заполняем updatedWlanApsForDirectRRBExchange если в объекте WLAN есть точки в updatedWlan.r0kh
        if ((this.isWLCProduct && this.updatedWlan && this.updatedWlan.r0kh && Array.isArray(this.updatedWlan.r0kh) && this.updatedWlan.r0kh.length > 0) ){
          this.directRRBExchange = true;
          this.fillUpdatedWlanApsForDirectRRBExchangeWhenModalOpen();
        }

        // Ставим свитчер experimentalFTImprovements в положение вкл, если вбырана хотя бы одна из подопций за отобрадение которых он отвечает
        if (this.isWLCProduct && this.updatedWlan &&
          (this.updatedWlan.rrb_tunnelling || this.updatedWlan.rrb_use_seq || (Array.isArray(this.updatedWlan.r0kh) && this.updatedWlan.r0kh.length > 0 )))
        {
          this.experimentalFTImprovements = true;
        }
      })
    },
    // setUpdatedWlanDataTrafficManagementType(wlanObject) {
    //   // console.log(wlanObject)
    //   this.dataTrafficManagementBlocksShow.localSwitching = false;
    //   this.dataTrafficManagementBlocksShow.centralSwitching = false;
    //   this.dataTrafficManagementBlocksShow.tunneling = false;
    //   const type = this.getWlanDataTrafficManagementTypeFromWlanObject(wlanObject);
    //   if (type) {
    //     if (type === 'localSwitching') {
    //       this.dataTrafficManagementBlocksShow.localSwitching = true;
    //     }
    //     if (type === 'centralSwitching') {
    //       this.dataTrafficManagementBlocksShow.centralSwitching = true;
    //     }
    //     if (type === 'tunneling') {
    //       this.dataTrafficManagementBlocksShow.tunneling = true;
    //     }
    //   }
    //
    // },

    setUpdatedWlanDataTrafficManagementType(wlanObject) {
      // console.log(wlanObject)
      this.dataTrafficManagementBlocksShow.localSwitching = false;
      this.dataTrafficManagementBlocksShow.centralSwitching = false;
      this.dataTrafficManagementBlocksShow.tunneling = false;
      const type = this.getWlanDataTrafficManagementTypeFromWlanObject(wlanObject);
      if (type) {
        if (type === 'localSwitching') {
          if (wlanObject.nat) {
            this.isNatActive = true;
          } else {
            this.isNatActive = false;
          }
          this.dataTrafficManagementBlocksShow.localSwitching = true;
        }
        if (type === 'centralSwitching') {
          this.dataTrafficManagementBlocksShow.centralSwitching = true;
        }
        if (type === 'tunneling') {
          this.dataTrafficManagementBlocksShow.tunneling = true;
        }
      }

    },

    // getWlanDataTrafficManagementTypeFromWlanObject(wlanObject) {
    //   if (wlanObject) {
    //     if (wlanObject.nat || (wlanObject.vlan  && !wlanObject.tunneling)) {
    //       return 'localSwitching'
    //     }
    //     if (wlanObject.tunneling && wlanObject.proto === 'l2tpv3') {
    //       return 'centralSwitching'
    //     }
    //     if (wlanObject.tunneling && wlanObject.proto !== 'l2tpv3') {
    //       return 'tunneling'
    //     }
    //   }
    //   return undefined
    // },


    getWlanDataTrafficManagementTypeFromWlanObject(wlanObject) {
      if (wlanObject) {
        if (!wlanObject.tunneling) {
          return 'localSwitching'
        }
        if (wlanObject.tunneling && wlanObject.proto === 'l2tpv3') {
          return 'centralSwitching'
        }
        if (wlanObject.tunneling && wlanObject.proto !== 'l2tpv3') {
          return 'tunneling'
        }
      }
      return undefined
    },

    setSecurityTabTabTo(tabName){
      this.securityTab = tabName;
    },
    setModalTabTo(tabName){
      this.modalTab = tabName
    },
    setAdvancedTabTo(tabName) {
      this.advancedTab = tabName
    },
    clearSelectedWlans(){
      this.selectedWlans = [];
      this.selectedWlansId = [];
      this.allChecked = false;
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      // legacy
      this.clearSelectedWlans();
      // legacy
      this.disableEditMode();

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalResult) {
        this.offset = 0;
      }

      this.getPaginatedWlans();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      // legacy
      this.clearSelectedWlans();
      // legacy
      this.disableEditMode();

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalResult) {
        this.offset = 0;
      }

      this.getPaginatedWlans();
    },
    dhcpOption82EnableSwitcherHandler(switcherState) {
      // console.log(switcherState)
      if (switcherState === true) {
        this.updatedWlan.option82state = this.option82States.ENABLE;
      } else {
        this.updatedWlan.option82state = this.option82States.DISABLE;
      }
    },
    dataTrafficManagementSwitchersHandler(event) {
      // this.updatedWlan.vlan = '';
      this.updatedWlan.vlan = 0;
      this.updatedWlan.peer_address = ''
      this.checkNat();
      this.changeTunnelingSelector();
      // console.log(event);
      const eventValue = event.target.value;
      // console.log(eventValue)
      const switcherId = event.target.id;
      // console.log(switcherId);

      if (eventValue === 'false') {

        if (switcherId === 'localSwitching-switcher') {
          this.updatedWlan.nat = false
          this.dataTrafficManagementBlocksShow.localSwitching = false;
          this.updatedWlan.proto = 'l2tpv3'
          // console.log('here')
        }
        if (switcherId === 'centralSwitching-switcher') {
          this.updatedWlan.tunneling = false;
          this.updatedWlan.proto = 'l2tpv3'
          this.dataTrafficManagementBlocksShow.centralSwitching = false;

        }
        if (switcherId === 'tunneling-switcher') {
          this.updatedWlan.tunneling = false;
          this.updatedWlan.proto = 'l2tpv3'
          this.dataTrafficManagementBlocksShow.centralSwitching = false;
        }
      } else {
        if (switcherId === 'localSwitching-switcher') {
          this.updatedWlan.tunneling = false;
          this.updatedWlan.proto = 'l2tpv3'
          this.dataTrafficManagementBlocksShow.centralSwitching = false;
          this.dataTrafficManagementBlocksShow.tunneling = false;
          // console.log('here')
        }
        if (switcherId === 'centralSwitching-switcher') {
          this.updatedWlan.tunneling = true;
          this.updatedWlan.proto = 'l2tpv3'
          this.updatedWlan.nat = false
          this.dataTrafficManagementBlocksShow.localSwitching = false;
          this.dataTrafficManagementBlocksShow.tunneling = false;

        }
        if (switcherId === 'tunneling-switcher') {
          this.updatedWlan.nat = false;
          this.updatedWlan.tunneling = true;
          this.updatedWlan.proto = 'gretap'
          this.dataTrafficManagementBlocksShow.localSwitching = false;
          this.dataTrafficManagementBlocksShow.centralSwitching = false;
        }
      }

    },
    goToRedirectCreate(){
      if (this.isDisable){
        return
      }
      this.saveComponentStateDataForRestore();
      this.$store.commit('setReturnToEditWlan', true);
      this.$router.push({name: 'GuestControl-wizard'});
    },
    goToRadiusCreate(){
      if (this.isDisable){
        return
      }
      this.saveComponentStateDataForRestore();
      this.$store.commit('setReturnToEditWlan', true);
      this.$router.push({name: 'radius-wizard'});
    },

    goToRadiusEdit(radiusName) {
      if (this.isDisable) {
        return
      }
      this.saveComponentStateDataForRestore();
      this.$store.commit('setReturnToEditWlan', true);
      this.$router.push({name: 'Radius', params: {radiusName: radiusName} });
    },

    saveComponentStateDataForRestore() {
      const elementWithScrollBar = document.getElementById('container-fluid');
      const scrollValue = elementWithScrollBar.scrollTop;
      // из стора
      const wlansList = JSON.parse(JSON.stringify(this.$store.state.wlansList));
      const editWlanIndex = this.$store.state.editWlanIndex;
      const editWlanId = this.$store.state.editWlanId;
      const hostsList = JSON.parse(JSON.stringify(this.$store.state.hostsList))


      // из стейта компонента
      const filters = JSON.parse(JSON.stringify(this.filters));
      const searchQuery = this.searchValue;
      const totalResult = this.totalResult;
      const searchValue = this.searchValue;
      const limit = this.limit;
      const offset = this.offset;
      const canLoadMore = this.canLoadMore;
      const locationInFiltersData = JSON.parse(JSON.stringify(this.locationInFiltersData));
      const filterList = JSON.parse(JSON.stringify(this.filterList));


      const isSecurityShow =   this.isSecurityShow
      const isRadiusAccShow =this.isRadiusAccShow
      const isRadiusAVPsShow = this.isRadiusAVPsShow
      const isSpeedControlShow = this.isSpeedControlShow
      const isAccessShow =this.isAccessShow
      const isMobilityShow =this.isMobilityShow
      const isWMMShow =this.isWMMShow
      const isTunnelShow =this.isTunnelShow

      if (this.updatedWlan !== false) {
        this.updatedWlan.whitelist = JSON.parse(JSON.stringify(this.filterList.whitelist));
        this.updatedWlan.blacklist = JSON.parse(JSON.stringify(this.filterList.blacklist));
        this.updatedWlan.radius_acct_servers = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
        this.updatedWlan.guest_control.mac_radius_auth_servers = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusMAC);
        if(this.isWLCProduct) {
          this.updatedWlan.r0kh = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanApsForDirectRRBExchange);
        }
        if (
          this.updatedWlan.security &&
          (this.updatedWlan.security.type === 'wpa2enterprise' || this.updatedWlan.security.type === 'wpaenterprise' ||
            this.updatedWlan.security.type === 'wpa3enterprise' || this.updatedWlan.security.type === 'wpa23enterprise' ||
          (this.updatedWlan.security.type.indexOf('personal') !== -1) && this.isPPSKEnabled)
        ) {
          this.updatedWlan.security.data.radiusauthentication = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
        }
      }

      const updatedWlan = (this.updatedWlan === false) ? false : JSON.parse(JSON.stringify(this.updatedWlan));
      const updatedWlanRadiusAccounting = JSON.parse(JSON.stringify(this.updatedWlanRadiusAccounting));
      const updatedWlanDefaultTunnel = this.updatedWlanDefaultTunnel;
      const updatedWlanRadiusAuthentication = JSON.parse(JSON.stringify(this.updatedWlanRadiusAuthentication));
      const updatedWlanRadiuses = JSON.parse(JSON.stringify(this.updatedWlanRadiuses));
      const updatedWlanRadiusMAC = JSON.parse(JSON.stringify(this.updatedWlanRadiusMAC));
      const updatedWlanApsForDirectRRBExchange = JSON.parse(JSON.stringify(this.updatedWlanApsForDirectRRBExchange));


      const dataTrafficManagementBlocksShow = JSON.parse(JSON.stringify(this.dataTrafficManagementBlocksShow));

      const securityTab = this.securityTab;
      const modalTab = this.modalTab
      const advancedTab = this.advancedTab
      const isNew = this.isNew;
      const wlanModalShowing = this.wlanModalShowing;
      const enableSaveChanges = this.enableSaveChanges;
      const experimentalFTImprovements = this.experimentalFTImprovements;
      const directRRBExchange = this.directRRBExchange;



      const wlansDataForRestoreInCpePage = {
        wlansList,
        editWlanIndex,
        editWlanId,
        filters,
        searchQuery,
        totalResult,
        searchValue,
        limit,
        offset,
        canLoadMore,
        locationInFiltersData,
        isSecurityShow,
        isRadiusAccShow,
        isRadiusAVPsShow,
        isSpeedControlShow,
        isAccessShow,
        isMobilityShow,
        isWMMShow,
        isTunnelShow,
        updatedWlan,
        updatedWlanRadiusAccounting,
        updatedWlanDefaultTunnel,
        updatedWlanRadiusAuthentication,
        updatedWlanRadiuses,
        updatedWlanRadiusMAC,
        scrollValue,
        hostsList,
        dataTrafficManagementBlocksShow,
        securityTab,
        modalTab,
        advancedTab,
        isNew,
        wlanModalShowing,
        filterList,
        enableSaveChanges,
        updatedWlanApsForDirectRRBExchange,
        experimentalFTImprovements,
        directRRBExchange

      }
      localStorage.setItem('wlansComponentStateDataForRestoreInCpePage', JSON.stringify(wlansDataForRestoreInCpePage));
    },
    loadComponentStateDataForRestoreAndRestore() {
      const wlansComponentStateDataForRestoreInCpePage = JSON.parse(localStorage.getItem('wlansComponentStateDataForRestoreInCpePage'));
      // console.log(wlansComponentStateDataForRestoreInCpePage)
      if (wlansComponentStateDataForRestoreInCpePage) {
        this.stateWasRestored = true;
        this.enableSaveChanges = wlansComponentStateDataForRestoreInCpePage.enableSaveChanges ?? true
        // восстанавливаем в стор
        this.$store.commit('setWlansList', wlansComponentStateDataForRestoreInCpePage.wlansList ?? []);
        this.$store.commit('setHostsList', wlansComponentStateDataForRestoreInCpePage.hostsList ?? []);

        // восстанавливаем в стейт компонента
        if (!!wlansComponentStateDataForRestoreInCpePage.filters){
          this.$set(this, 'filters', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.filters)) )
        }
        if (!!wlansComponentStateDataForRestoreInCpePage.locationInFiltersData){
          this.$set(this, 'locationInFiltersData', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.locationInFiltersData)))
        }

        if (!!wlansComponentStateDataForRestoreInCpePage.dataTrafficManagementBlocksShow){
          this.$set(this, 'dataTrafficManagementBlocksShow', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.dataTrafficManagementBlocksShow)))
        }
        if (!!wlansComponentStateDataForRestoreInCpePage.filterList){
          this.$set(this, 'filterList', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.filterList)) )
        }



        //  this.filters = this. JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.filters))
        this.searchQuery = wlansComponentStateDataForRestoreInCpePage.searchQuery ?? '';
        this.totalResult = wlansComponentStateDataForRestoreInCpePage.totalResult ?? false;
        this.searchValue = wlansComponentStateDataForRestoreInCpePage.searchValue ?? '';
        this.limit = wlansComponentStateDataForRestoreInCpePage.limit ?? 30;
        this.offset = wlansComponentStateDataForRestoreInCpePage.offset ?? 0;
        this.canLoadMore = wlansComponentStateDataForRestoreInCpePage.canLoadMore ?? true;
        this.$nextTick(() => {
          this.$store.commit('setWlanEditIndex', wlansComponentStateDataForRestoreInCpePage.editWlanIndex ?? false );
          this.$store.commit('setWlansEditId', wlansComponentStateDataForRestoreInCpePage.editWlanId ?? '' );

          if (!!wlansComponentStateDataForRestoreInCpePage.updatedWlan) {
            this.$set(this, 'updatedWlan', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.updatedWlan)))
          } else {
            this.updatedWlan = false;
          }
          this.$set(this, 'updatedWlanRadiusAccounting', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.updatedWlanRadiusAccounting)))
          this.updatedWlanDefaultTunnel = wlansComponentStateDataForRestoreInCpePage.updatedWlanDefaultTunnel;
          this.$set(this, 'updatedWlanRadiusAuthentication', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.updatedWlanRadiusAuthentication)))
          this.$set(this, 'updatedWlanRadiuses', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.updatedWlanRadiuses)))
          this.$set(this, 'updatedWlanRadiusMAC', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.updatedWlanRadiusMAC)))
          this.$set(this, 'updatedWlanApsForDirectRRBExchange', JSON.parse(JSON.stringify(wlansComponentStateDataForRestoreInCpePage.updatedWlanApsForDirectRRBExchange)))

          this.$nextTick(() => {
            this.isSecurityShow = wlansComponentStateDataForRestoreInCpePage.isSecurityShow ?? false
            this.isRadiusAccShow = wlansComponentStateDataForRestoreInCpePage.isRadiusAccShow ?? false
            this.isRadiusAVPsShow = wlansComponentStateDataForRestoreInCpePage.isRadiusAVPsShow ?? false
            this.isSpeedControlShow = wlansComponentStateDataForRestoreInCpePage.isSpeedControlShow ?? false
            this.isAccessShow = wlansComponentStateDataForRestoreInCpePage.isAccessShow ?? false
            this.isMobilityShow = wlansComponentStateDataForRestoreInCpePage.isMobilityShow ?? false
            this.isWMMShow = wlansComponentStateDataForRestoreInCpePage.isWMMShow ?? false
            this.isTunnelShow = wlansComponentStateDataForRestoreInCpePage.isTunnelShow ?? false
            // console.log(wlansComponentStateDataForRestoreInCpePage.securityTab)
            this.securityTab = wlansComponentStateDataForRestoreInCpePage.securityTab ?? 'L2'

            this.modalTab = wlansComponentStateDataForRestoreInCpePage.modalTab ?? 'General'
            this.advancedTab = wlansComponentStateDataForRestoreInCpePage.advancedTab ?? 'DataTrafficManagement'
            this.isNew = wlansComponentStateDataForRestoreInCpePage.isNew ?? false
            this.wlanModalShowing = wlansComponentStateDataForRestoreInCpePage.wlanModalShowing ?? false

            this.directRRBExchange = wlansComponentStateDataForRestoreInCpePage.directRRBExchange ?? false;
            this.experimentalFTImprovements = wlansComponentStateDataForRestoreInCpePage.experimentalFTImprovements ?? false;

            this.$nextTick(()=>{
              if (wlansComponentStateDataForRestoreInCpePage.scrollValue){
                const elementWithScrollBar = document.getElementById('container-fluid');
                if (elementWithScrollBar){
                  elementWithScrollBar.scrollTop = wlansComponentStateDataForRestoreInCpePage.scrollValue
                }
              }
            })

          })
        })
      }
    },
    removeComponentStateDataForRestore() {
      localStorage.removeItem('wlansComponentStateDataForRestoreInCpePage');
    },
    checkComponentStateDataForRestore(){
      return !!localStorage.getItem('wlansComponentStateDataForRestoreInCpePage');
    },
    checkWPA3Type(type) {
      if (this.wpa3Types.includes(type)) {
        return true;
      }
      return false
    },
    goBackToWlanStatistic() {
      this.$router.push({ name: 'Statwlans', params: { goBackFromWlans: true } })
    },
    goBackToClients(){
      this.$router.push({ name: 'Clients', params: { goBackFromWlans: true } })
    },
    customLabelForTunnelMiltiselect(tunnelName) {
      try {
        return this.createTunnelCaptionByName(tunnelName)
      } catch (e) {
        return tunnelName;
      }
    },
    labelForSelectedInTunnelMiltiselect() {
      const tunnelName = this.updatedWlan.default_tunnel
      return this.createTunnelCaptionByName(tunnelName);
    },
    createTunnelCaptionByName(tunnelName) {
      try {
        return `${tunnelName} (${this.$t('general.type').toLowerCase()} ${this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[tunnelName].type})`
      } catch (e) {
        return tunnelName
      }
    },
    changeTunnelingSelector(){
      this.updatedWlan.default_tunnel = '';
      // this.updatedWlanDefaultTunnel = {};
      this.$set(this, 'updatedWlanDefaultTunnel', '');
    },
    // clearDefaultTunnel() {
    //   this.updatedWlan.default_tunnel = '';
    // },
    clearDefaultTunnel() {
      this.updatedWlan.default_tunnel = '';
      // this.updatedWlanDefaultTunnel = {};
      this.$set(this, 'updatedWlanDefaultTunnel', '');
    },
    // resetAllFilters() {
    //   this.disableEditMode();
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     securityFilter: '',
    //     statusFilter: '',
    //     tag: 'All tags',
    //     location: 'All locations',
    //     with_childs: false,
    //     dataTrafficManagementType: null,
    //   };
    //   this.searchValue = '';
    //   this.refreshWlansListQuery()
    // },
    resetAllFilters() {
      this.disableEditMode();
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        securityFilter: '',
        statusFilter: '',
        tag: 'All tags',
        // location: 'All locations',
        // with_childs: false,
        dataTrafficManagementType: null,
        sort_by: {
          order: 1,
          field: 'ssid'
        }
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.searchValue = '';
      this.refreshWlansListQuery()
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshWlansListQuery()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    selectWlanDefaultTunnel(payload) {
      // console.log('payload', payload)
      this.updatedWlan.default_tunnel = payload.name
    },
    createInterfacesWithDetailsFromAllHostsList(hosts) {
      // return helpers.createInterfacesWithDetailsFromAllHostsList(hosts)
      return helpers.createInterfacesWithDetailsAndNameWithTypeFromAllHostsList(this, hosts)
      // try {
      //   const result = {}
      //   for (const host of hosts) {
      //     if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
      //       Array.isArray(host.interfaces_details) &&
      //       host.interfaces_details.length > 0) {
      //       for (const interfaceWithDetails of host.interfaces_details) {
      //         if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
      //           interfaceWithDetails.name !== '' &&
      //           !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
      //           result[interfaceWithDetails.name] = interfaceWithDetails;
      //         }
      //       }
      //     }
      //   }
      //   return result;
      // } catch (e) {
      //   console.log(e)
      //   return {}
      // }
    },

    openWlanModalProgrammatically(index, id) {
      // console.log('openWlanModalProgrammatically')
      this.$validator.reset();
      this.hideSettings();
      this.newMacAddress = '';
      this.radiusItems = helpers.transformArrayToObject(this.$store.state.radiusList);
      this.$store.commit('setWlanEditIndex', index);
      this.$store.commit('setWlansEditId', id);
    },
    showDropReasonModal() {
      // console.log('clicked')
      this.showingDropReasonModal = true;
    },
    roam80211rSwitcherChange(newVal) {
      if (newVal === true) {
        // устанавливаем ft_over_ds во включеное состояние (в случае не WLC) или в выключенное (в случае WLC)
        // во время переключения roam80211r в true
        if (Object.prototype.hasOwnProperty.call(this.updatedWlan.hasOwnProperty, 'ft_over_ds')) {
          if (this.isWLCProduct) {
            this.updatedWlan.ft_over_ds = false;
          } else {
            this.updatedWlan.ft_over_ds = true;
          }
        } else {
          if (this.isWLCProduct) {
            this.$set(this.updatedWlan, 'ft_over_ds', false);
          } else {
            this.$set(this.updatedWlan, 'ft_over_ds', true);
          }

        }

        // устанавливаем nas_generate во включеное состояние
        // во время переключения roam80211r в true
        if (Object.prototype.hasOwnProperty.call(this.updatedWlan.hasOwnProperty, 'nas_generate')) {
          this.updatedWlan.nas_generate = true;
        } else {
          this.$set(this.updatedWlan, 'nas_generate', true);
        }
      }
    },
    ieee80211kSwitcherChange(newVal) {
      if (newVal === true) {
        // устанавливаем rrm_neighbor_report во включеное состояние
        // во время переключения ieee80211k в true
        if (Object.prototype.hasOwnProperty.call(this.updatedWlan.hasOwnProperty, 'rrm_neighbor_report')) {
          this.updatedWlan.rrm_neighbor_report = true;
        } else {
          this.$set(this.updatedWlan, 'rrm_neighbor_report', true);
        }
      }
    },
    changeProtoInSelector() {
      // если выбриаем proto = gretap то сбрасываем тунель
      if (this.updatedWlan.hasOwnProperty('proto') && this.updatedWlan.proto === 'gretap') {
        // console.log('clear tunnel')
        this.updatedWlan.default_tunnel = '';
        // this.updatedWlanDefaultTunnel = {};
        this.$set(this, 'updatedWlanDefaultTunnel', '');
      }
    },
    changeProtoInSelectorInTunnelingBlock() {

    },
    hasWlanRedirect(wlanObj) {
      if (
        wlanObj.hasOwnProperty('guest_control') &&
        wlanObj.guest_control.hasOwnProperty('captive_redirect') &&
        wlanObj.guest_control.captive_redirect !== ''
      ) {
        return true;
      }
      return false;
    },
    showWMMInfoDetails() {
      // console.log('clickeed');
      this.showingWMMInfoDetailsModal = true;
    },
    passwordGeneratedForEditedWlan(generatedPassword) {
      this.showWlanPass = false;
      this.$nextTick(() => {
        this.showWlanPass = true;
        // console.log(generatedPassword);
        this.updatedWlan.security.data.psk = generatedPassword;
      })
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };

      // ! legacy
      this.disableEditMode();

      if (this.sortParams.type === 'asc') {
        this.filters.sort_by.order = 1;
      }

      if (this.sortParams.type === 'desc') {
        this.filters.sort_by.order = -1;
      }

      if (this.sortParams.type === 'none') {
        this.filters.sort_by.order = 0;
      }

      this.filters.sort_by.field = this.sortParams.field;

      this.refreshWlansListQuery();
    },
    handleCellClick(rowData) {
      const { originalIndex, nativeData: { id } } = rowData;

      // legacy. todo refactor
      this.$validator.reset();
      this.hideSettings();
      this.newMacAddress = '';
      this.radiusItems = helpers.transformArrayToObject(this.$store.state.radiusList);
      this.$store.commit('setWlanEditIndex', originalIndex);
      this.$store.commit('setWlansEditId', id);
      this.openEditWLANModal();
    },
    searchInputHandler(value) {
      this.showGoBackToWlansStatisticButton = false;
      this.showGoBackToClientsButton = false;
      this.cancelCommonEdit();
      this.searchValue = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        wlanService.refreshWlansV2(this);
      }, 1000);
    },
    enableSave() {
      this.enableSaveChanges = true;
    },
    removeMac(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.filterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.filterList.blacklist.splice(index, 1);
      }
    },
    disableEditMode() {
      this.$store.commit('setWlanEditIndex', null);
      this.$store.commit('setWlansEditId', null);
      this.updatedWlan = false;
    },
    closeWLANModal(){
      this.isNew = false;
      this.stateWasRestored = false;
      // this.$store.commit('setWlanEditIndex', null);
      // this.$store.commit('setWlansEditId', null);
      this.disableEditMode();
      this.hideWLANModal();
    },

    // disableEditModeFromStartPollingFunc(updatedWlanIds) {
    //   if (updatedWlanIds !== undefined && Array.isArray(updatedWlanIds)) {
    //     // console.log(updatedWlanIds)
    //     // console.log(this.$store.state.editWlanId)
    //     if (!updatedWlanIds.includes(this.$store.state.editWlanId)) {
    //       // console.log('not close modal');
    //     } else {
    //       this.$store.commit('setWlanEditIndex', null);
    //       this.$store.commit('setWlansEditId', null);
    //     }
    //   } else {
    //     this.$store.commit('setWlanEditIndex', null);
    //     this.$store.commit('setWlansEditId', null);
    //   }
    //
    // },

    disableEditModeFromStartPollingFunc(updatedWlanIds) {
      // console.log('disableEditModeFromStartPollingFunc')
      if (updatedWlanIds !== undefined && Array.isArray(updatedWlanIds)) {
        // console.log(updatedWlanIds)
        // console.log(this.$store.state.editWlanId)
        if (!updatedWlanIds.includes(this.$store.state.editWlanId)) {
          // тут ничего не делаем, так как открыта wlan которая не обновлялась
        } else {
          // если открыли для редактирования wlan которая обновилась перерисовываем окно
          // this.$store.commit('setWlanEditIndex', null);
          // this.$store.commit('setWlansEditId', null);
          const index = this.$store.state.editWlanIndex;
          const id = this.$store.state.editWlanId;
          // console.log('index', index);
          // console.log('id', id);
          this.$store.commit('setWlanEditIndex', null);
          this.$store.commit('setWlansEditId', null);
          this.openWlanModalProgrammatically(index, id);
        }
      } else {
        this.$store.commit('setWlanEditIndex', null);
        this.$store.commit('setWlansEditId', null);
      }
    },

    showSecurity() {
      this.isSecurityShow = !this.isSecurityShow;
    },
    showRadiusAcc() {
      this.isRadiusAccShow = !this.isRadiusAccShow;
    },
    showRadiusAVPs() {
      this.isRadiusAVPsShow = !this.isRadiusAVPsShow;
    },
    showSpeedControl() {
      this.isSpeedControlShow = !this.isSpeedControlShow;
    },
    showAccess() {
      this.isAccessShow = !this.isAccessShow;
    },
    showMobility() {
      this.isMobilityShow = !this.isMobilityShow;
    },
    showWMM() {
      this.isWMMShow = !this.isWMMShow;
    },
    showTunnel() {
      this.isTunnelShow = !this.isTunnelShow;
    },
    showDhcpOption82Block(){
      this.dhcpOption82BlockShow = !this.dhcpOption82BlockShow
    },
    hideSettings() {
      this.isSecurityShow = false;
      this.isRadiusAccShow = false;
      this.isRadiusAVPsShow = false;
      this.isSpeedControlShow = false;
      this.isAccessShow = false;
      this.isMobilityShow = false;
      this.isWMMShow = false;
      this.isTunnelShow = false;
      this.isBackgroundShow = false;
      this.isBestEffortShow = false;
      this.isVideoShow = false;
      this.isVoiceShow = false;
    },
    openWlanWizard() {
      this.$router.push('wlanWizardV2');
    },
    updateWlan() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.updatedWlan.filtermode === 'WhiteList' && this.newMacAddress) {
              if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
                this.filterList.whitelist.push(this.newMacAddress);
                this.newMacAddress = '';
                return;
              }
              return;
            }
            if (this.updatedWlan.filtermode === 'BlackList' && this.newMacAddress) {
              if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
                this.filterList.blacklist.push(this.newMacAddress);
                this.newMacAddress = '';
                return;
              }
              return;
            }

            // ставим значнеия по умолчанию для полей signal_ если они не заданы WNE-2400
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_strikes') &&
              this.updatedWlan.signal_strikes === ''
            ) {
              this.updatedWlan.signal_strikes = 3;
            }
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_poll_time') &&
              this.updatedWlan.signal_poll_time === ''
            ) {
              this.updatedWlan.signal_poll_time = 5;
            }
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_drop_reason') &&
              this.updatedWlan.signal_drop_reason === ''
            ) {
              this.updatedWlan.signal_drop_reason = 3;
            }

            this.$store.state.editWlanIndex = '';
            wlanService.updateWlanV2(this);
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    updateWlanFromModal() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.updatedWlan.filtermode === 'WhiteList' && this.newMacAddress) {
              if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
                this.filterList.whitelist.push(this.newMacAddress);
                this.newMacAddress = '';
                return;
              }
              return;
            }
            if (this.updatedWlan.filtermode === 'BlackList' && this.newMacAddress) {
              if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
                this.filterList.blacklist.push(this.newMacAddress);
                this.newMacAddress = '';
                return;
              }
              return;
            }

            // ставим значнеия по умолчанию для полей signal_ если они не заданы WNE-2400
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_strikes') &&
              this.updatedWlan.signal_strikes === ''
            ) {
              this.updatedWlan.signal_strikes = 3;
            }
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_poll_time') &&
              this.updatedWlan.signal_poll_time === ''
            ) {
              this.updatedWlan.signal_poll_time = 5;
            }
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_drop_reason') &&
              this.updatedWlan.signal_drop_reason === ''
            ) {
              this.updatedWlan.signal_drop_reason = 3;
            }

            this.$store.state.editWlanIndex = '';
            wlanService.updateWlanV2(this);
            this.closeWLANModal();
          }

        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    createWlanFromModal() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.updatedWlan.filtermode === 'WhiteList' && this.newMacAddress) {
              if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
                this.filterList.whitelist.push(this.newMacAddress);
                this.newMacAddress = '';
                return;
              }
              return;
            }
            if (this.updatedWlan.filtermode === 'BlackList' && this.newMacAddress) {
              if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
                this.filterList.blacklist.push(this.newMacAddress);
                this.newMacAddress = '';
                return;
              }
              return;
            }

            // ставим значнеия по умолчанию для полей signal_ если они не заданы WNE-2400
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_strikes') &&
              this.updatedWlan.signal_strikes === ''
            ) {
              this.updatedWlan.signal_strikes = 3;
            }
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_poll_time') &&
              this.updatedWlan.signal_poll_time === ''
            ) {
              this.updatedWlan.signal_poll_time = 5;
            }
            if (
              Object.prototype.hasOwnProperty.call(this.updatedWlan, 'signal_drop_reason') &&
              this.updatedWlan.signal_drop_reason === ''
            ) {
              this.updatedWlan.signal_drop_reason = 3;
            }

            this.$store.state.editWlanIndex = '';

            wlanService.createWlanFromModalV2(this);
            this.closeWLANModal();
          }

        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    copyWlan() {
      this.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
      // const copy = { ...this.updatedWlan };
      const copy = copyAndModifyWLANObjectBeforeSendToAPI(this.updatedWlan);

      this.axios.post(`${API_URL || ''}/api/wlans`, { action: 'C', items: { 0: copy } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewWlanData');
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          wlanService.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    copyWlanFromModal() {
      this.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
      // const copy = { ...this.updatedWlan };
      const copy = copyAndModifyWLANObjectBeforeSendToAPI(this.updatedWlan);

      this.axios.post(`${API_URL || ''}/api/wlans`, { action: 'C', items: { 0: copy } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewWlanData');
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          wlanService.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
          this.closeWLANModal();
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    refreshWlansList() {
      this.cancelCommonEdit();
      wlanService.refreshWlansV2(this);
    },

    refreshWlansListFromStartPollingFunc(updatedWlanIds) {
      // console.log(wlanIds)
      this.cancelCommonEditFromStartPollingFunc(updatedWlanIds);
      wlanService.refreshWlansV2(this);
    },

    refreshWlansTags() {
      wlanService.getWlansTags(this);
    },
    deleteCommonTags() {
      wlanService.deleteCommonTags(this);
    },
    openDeletingDialog(id) {
      this.wlanIdToDelete = id;
      this.deletingWlanModal = true;
    },
    openDeletingDialogforWlans() {
      this.selectedWlansId.length == 0;
      this.deletingWlansModal = true;
    },
    openAddTagsDialog() {
      this.addTagsModal = true;
    },
    radiusName(id) {
      return commonService.radiusNamebyId(id);
    },
    wlanObj(id) {
      const wlanObj = commonService.wlanObjbyId(id);
      return wlanObj;
    },
    openCommonDeletingTagsDialog() {
      this.commonDeletingTagsModal = true;
    },
    cancelDeleteCommonTags() {
      this.commonDeletingTagsModal = false;
    },
    searchTag(tag) {
      this.filters.tag = tag;
      this.refreshWlansListQuery();
    },
    removeTag(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.updatedWlan.tags.splice(index, 1);
    },
    addTag() {
      this.$validator
        .validateAll({
          tag: this.newTag
        })
        .then((result) => {
          if (result) {
            if (this.updatedWlan.tags.indexOf(this.newTag) == -1) {
              this.updatedWlan.tags.push(this.newTag);
            } else {
              this.newTag = '';
              return;
            }
            this.newTag = '';
          }
        });
    },
    addTagMultiple() {
      this.$validator
        .validateAll({
          tags: this.newTag
        })
        .then((result) => {
          if (result) {
            this.newTags.push(this.newTag);
            this.newTag = '';
          }
        });
    },
    addExistTag(tag) {
      if (this.updatedWlan.tags.indexOf(tag) == -1) {
        this.updatedWlan.tags.push(tag);
      } else {
      }
    },
    addExistTagMultiple(tag) {
      this.newTags.push(tag);
    },
    removeTagMultiple(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.newTags.splice(index, 1);
    },
    assignTags() {
      wlanService.assignTags(this);
    },
    deleteWlan() {
      wlanService.deleteWlanV2(this);
      this.wlanIdToDelete = '';
    },
    deleteWlans() {
      for (const id in this.selectedWlansId) {
        const elem = { [this.selectedWlansId[id]]: {} };
        this.wlansForEdit = Object.assign(this.wlansForEdit, elem);
      }
      wlanService.deleteWlansV2(this);
    },
    updateWlans() {
      wlanService.updateWlansV2(this);
    },
    cancelDeleting() {
      this.deletingWlanModal = false;
      this.deletingWlansModal = false;
      this.wlanIdToDelete = '';
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.updatedWlan.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.filterList.whitelist.push(this.newMacAddress);
              this.newMacAddress = '';
              return;
            }
            return;
          }
          if (this.updatedWlan.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.filterList.blacklist.push(this.newMacAddress);
              this.newMacAddress = '';
              return;
            }
            return;
          }
          this.enableSaveChanges = true;
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    setFilter(filterName, value) {
      this.selectedWlans = [];
      this.selectedWlansId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else {
        this.filters[filterName] = null;
      }
      this.refreshWlansListQuery();
    },
    checkNat() {
      if (!this.updatedWlan) {
        return;
      }

      if (this.updatedWlan.nat) {
        this.updatedWlan.tunneling = false;
        this.updatedWlan.vlan = '';
        this.isNatActive = true;
      } else {
        this.updatedWlan.vlan = 0;
        this.updatedWlan.firewall.nat_access = false;
        this.isNatActive = false;
      }
    },
    refreshWlansListQuery() {
      this.canLoadMore = true;
      this.offset = 0;
      this.$store.commit('setWlansList', false);
      wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
    },
    getPaginatedWlans() {
      this.$store.commit('setWlansList', false);
      wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
    },
    loadMore() {
      this.offset++;
      wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
    },
    handleSelectWlanRow(selectedRowsWlansData) {
      const selectedWlansIds = selectedRowsWlansData.map(wlanRowData =>  wlanRowData.nativeData.id);

      this.selectedWlansId = selectedWlansIds;
    },
    // setSecurityType(type) {
    //   if (type !== 'open' && type.indexOf('2') === -1) {
    //     const isAesSelected = this.updatedWlan.security.data.suites.indexOf('aes');
    //     if (isAesSelected !== -1) {
    //       this.updatedWlan.security.data.suites = ['tkip'];
    //     }
    //   }
    //   if (type !== 'open' && type.indexOf('2') != -1) {
    //     this.updatedWlan.security.data.suites = ['aes'];
    //   }
    //
    //   if (type === 'open') {
    //     this.updatedWlan.roam80211r = false;
    //   }
    //
    //   if (type !== 'wpaenterprise' || type !== 'wpa2enterprise') {
    //     this.updatedWlan.pmkcaching = false;
    //   }
    // },
    setSecurityType(type) {
      if (type !== 'open' && type.indexOf('2') === -1) {
        const isAesSelected = this.updatedWlan.security.data.suites.indexOf('aes');
        if (isAesSelected !== -1) {
          this.updatedWlan.security.data.suites = ['tkip'];
        }
      }
      if (type !== 'open' && (type.indexOf('2') != -1 || type.indexOf('3') != -1)) {
        // console.log('here')
        this.updatedWlan.security.data.suites = ['aes'];
      }

      if (type === 'open') {
        this.updatedWlan.roam80211r = false;
      }

      if (type !== 'wpaenterprise' || type !== 'wpa2enterprise' || type !== 'wpa3enterprise' || type !== 'wpa23enterprise') {
        this.updatedWlan.pmkcaching = false;
      }
      // ft_psk_generate_local только для wpa2personal безопасности, поэтому выключаем ее если выбран другой тип безопасности (функция есть только в WLC)
      if (type !== 'wpa2personal' && this.isWLCProduct) {
        this.$set(this.updatedWlan, 'ft_psk_generate_local', false);
      }

      // ft_psk_generate_local при выборе wpa2personal должна становится активной по умолчанию (функция ft_psk_generate_local есть только в WLC)
      if (type === 'wpa2personal' && this.isWLCProduct) {
        this.$set(this.updatedWlan, 'ft_psk_generate_local', true);
      }
    },

    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    cpeObj(id) {
      const cpeObj = commonService.cpeObjbyId(id);
      return cpeObj;
    },
    firewallName(id) {
      const showFirewallFromId = commonService.firewallNamebyId(id);
      return showFirewallFromId;
    },
    redirectName(id) {
      const redirectName = commonService.redirectNamebyId(id);
      return redirectName;
    },
    cancelCommonEdit() {
      this.selectedWlan = [];
      this.selectedWlansId = [];
      this.allChecked = false;
      this.addTagsModal = false;
      this.commonDeletingTagsModal = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableEditMode();
      this.closeWLANModal();
    },

    cancelCommonEditFromStartPollingFunc(updatedWlansIds) {
      this.selectedWlan = [];
      this.selectedWlansId = [];
      this.allChecked = false;
      this.addTagsModal = false;
      this.commonDeletingTagsModal = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }

      // if (updatedWlansIds !== undefined && Array.isArray(updatedWlansIds) && updatedWlansIds.includes(this.$store.state.editWlanId)) {
      //   this.disableEditMode();
      // }
      this.disableEditModeFromStartPollingFunc(updatedWlansIds);
      this.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для отмены спинера поверх обновляемой WLAN WNE-2408
    },

    isNoData() {
      return this.filteredWlans.length === 0;
    },
    changeLocations(list, id) {
      wlanService.changeWlansLocations(this, list, id);
    },
    cancelChangeLocation() {
      this.isChangeLocationOpen = false;
    }
  },
  // created() {
  //   this.disableEditMode;
  //
  //   this.$store.commit('setWlansList', false);
  //   wlanService.getPaginatedWlans(this, this.limit, this.offset);
  //   wlanService.getHosts(this);
  //   wlanService.getWlansTags(this);
  //   radiusService.getRadius(this);
  //   hotspotService.getHotspots(this);
  //   this.disableEditMode();
  //
  //   redirectsService.getRedirectsRule(this);
  //
  //   document.body.addEventListener('keyup', (event) => {
  //     event.preventDefault();
  //     if (event.keyCode == 13 && this.wlanTagsModal === true && this.newTag != '') {
  //       this.addTag();
  //     } else if (event.keyCode == 13 && this.addTagsModal === true && this.newTag != '') {
  //       this.addTagMultiple();
  //     }
  //   });
  // }
  // created() {
  //   this.disableEditMode;
  //
  //   if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('wlanIdFormWlanStatisic')) {
  //     // console.log('here')
  //     // если переходим cюда из комопнента Ststistic - CPE  после выбора точки,
  //     // срзау делаем поиск по id этой точке
  //     this.searchValue = this.$route.params.wlanIdFormWlanStatisic;
  //     console.log(this.searchValue)
  //     this.showGoBackToWlansStatisticButton = true;
  //     // а далее логика  в вотчере isLoadingAll
  //   }
  //
  //   this.$store.commit('setWlansList', false);
  //   wlanService.getPaginatedWlans(this, this.limit, this.offset);
  //   wlanService.getHosts(this);
  //   wlanService.getWlansTags(this);
  //   radiusService.getRadius(this);
  //   hotspotService.getHotspots(this);
  //   this.disableEditMode();
  //
  //   redirectsService.getRedirectsRule(this);
  //
  //   document.body.addEventListener('keyup', (event) => {
  //     event.preventDefault();
  //     if (event.keyCode == 13 && this.wlanTagsModal === true && this.newTag != '') {
  //       this.addTag();
  //     } else if (event.keyCode == 13 && this.addTagsModal === true && this.newTag != '') {
  //       this.addTagMultiple();
  //     }
  //   });
  // }
  // created() {
  //   let inHeaderLocationSelectedApplying = true;
  //   this.disableEditMode;
  //
  //   if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('wlanIdFormWlanStatisic')) {
  //     inHeaderLocationSelectedApplying = false;
  //     // console.log('here')
  //     // если переходим cюда из комопнента Ststistic - CPE  после выбора точки,
  //     // срзау делаем поиск по id этой точке
  //     this.searchValue = this.$route.params.wlanIdFormWlanStatisic;
  //     console.log(this.searchValue)
  //     this.showGoBackToWlansStatisticButton = true;
  //     // а далее логика  в вотчере isLoadingAll
  //   }
  //
  //
  //   if (this.$store.state.inHeaderLocationSelectModeEnabled &&
  //     inHeaderLocationSelectedApplying) {
  //     let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
  //     let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
  //     if (!locationForFiltering || locationForFiltering === '') {
  //       // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
  //       locationForFiltering = 'All locations';
  //     }
  //
  //     if (locationForFiltering === 'All locations') {
  //       this.locationInFiltersData = [];
  //     } else {
  //       this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
  //     }
  //
  //     this.filters.location = locationForFiltering;
  //     this.filters.with_childs = withChildsForFiltering;
  //   }
  //
  //   this.$store.commit('setWlansList', false);
  //   wlanService.getPaginatedWlans(this, this.limit, this.offset);
  //   wlanService.getHosts(this);
  //   wlanService.getWlansTags(this);
  //   radiusService.getRadius(this);
  //   hotspotService.getHotspots(this);
  //   this.disableEditMode();
  //
  //   redirectsService.getRedirectsRule(this);
  //
  //   document.body.addEventListener('keyup', (event) => {
  //     event.preventDefault();
  //     if (event.keyCode == 13 && this.wlanTagsModal === true && this.newTag != '') {
  //       this.addTag();
  //     } else if (event.keyCode == 13 && this.addTagsModal === true && this.newTag != '') {
  //       this.addTagMultiple();
  //     }
  //   });
  // }

  created() {
    this.$store.commit('clearReturnToEditWlan')
    const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
    const needRestoreState = hasLocalStorageDataForRestoring;
    if (needRestoreState) {
      this.loadComponentStateDataForRestoreAndRestore();
      this.removeComponentStateDataForRestore();
    }
    if (!needRestoreState && hasLocalStorageDataForRestoring) {
      this.removeComponentStateDataForRestore();
    }
    let inHeaderLocationSelectedApplying = true;
    this.disableEditMode;

    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('wlanIdFormWlanStatisic')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // console.log('here')
      // если переходим cюда из комопнента Ststistic - CPE  после выбора точки,
      // срзау делаем поиск по id этой точке
      this.searchValue = this.$route.params.wlanIdFormWlanStatisic;
      console.log(this.searchValue)
      this.showGoBackToWlansStatisticButton = true;
      // а далее логика  в вотчере isLoadingAll
    }

    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('wlanIdFormClients')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // console.log('here')
      // если переходим cюда из комопнента Clients  после клика по WLAN сети,
      // срзау делаем поиск по id этой WLAN
      this.searchValue = this.$route.params.wlanIdFormClients;
      // console.log(this.searchValue)
      this.showGoBackToClientsButton = true;
      // а далее логика  в вотчере isLoadingAll
    }


    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying && !needRestoreState) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    if (!needRestoreState){
      this.$store.commit('setWlansList', false);
    wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
    wlanService.getHosts(this);
    wlanService.getWlansTags(this);
    radiusService.getRadius(this);
    hotspotService.getHotspots(this);
    }
    // this.$store.commit('setWlansList', false);
    // wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
    // wlanService.getHosts(this);
    // wlanService.getWlansTags(this);
    // radiusService.getRadius(this);
    // hotspotService.getHotspots(this);
    this.disableEditMode();

    redirectsService.getRedirectsRule(this);

    document.body.addEventListener('keyup', (event) => {
      event.preventDefault();
      if (event.keyCode == 13 && this.wlanTagsModal === true && this.newTag != '') {
        this.addTag();
      } else if (event.keyCode == 13 && this.addTagsModal === true && this.newTag != '') {
        this.addTagMultiple();
      }
    });
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      wlanService.getPaginatedWlansV2(this, this.limit, this.offset);
      wlanService.getHosts(this);
      wlanService.getWlansTags(this);
      radiusService.getRadius(this);
      hotspotService.getHotspots(this);

      redirectsService.getRedirectsRule(this);
    });

  }


};
</script>
<style lang="scss">
.wlans {
  display: flex;
}

.wlans-page {
  min-width: 900px;
}

.wlans-list,
.wlans-edit {
  transition: width 0.2s;
}

.wlans-list {
  display: inline-block;
  width: 100%;
}

.wlans-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.wlan-filtermode {
}

.wlan-status {
  position: absolute;
  bottom: -17px;
  left: -17px;
}

.wlan-filtermode__title {
  margin-bottom: 45px !important;
}
.edit-visible .wlans-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 45%;
  min-width: 300px;
}

.edit-visible .wlans-edit {
  transition: width 0.2s;
  width: 55%;
  min-width: 500px;
}

.edit-visible.multiedit-visible .wlans-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 70%;
}
.edit-visible.multiedit-visible .wlans-edit {
  transition: width 0.2s;
  width: 30%;
}
.wlans-edit .card-header {
  border: 0px;
}

.wlan-row {
  transition: background 0.15s;
  cursor: pointer;
}

.wlan-row.selected {
  background: #d9e3ec;
}

.wlan-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.wlan-row-nodata {
  text-align: center;
}
.wlan-settings-block {
  border-radius: 10px;
  padding: 15px;
  background: rgba(236, 236, 236, 0.45);
}
.wlan-settings-block-for-modal {
  border-radius: 10px;
  padding: 15px;
}

.wlan-settings-block .btn-link {
  padding: 2px 15px;
}

.wlan-settings-block__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.no-data-row {
  text-align: center;
}

.wsettings-enter-active {
  transition: all 0.7s;
}

.wsettings-leave-active {
  transition: all 0.5s;
}

.wsettings-enter, .wsettings-leave-to /* .list-leave-active для <2.1.8 */ {
  opacity: 0;
  transform: translateY(-25px);
}
/*Style for filters*/
.btn.btn-outline-success.active {
  background-color: #4dbd74;
}

select.input.is-danger {
  border-color: #ff3860 !important;
}

.hovered {
  cursor: pointer;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.btn-wb {
  display: block;
  width: 100%;
}

.not-for-cisco {
  position: relative;

  &::after {
    content: '*';

    color: #f86c6b;

    position: absolute;
    right: -9px;
    top: -3px;
  }
}
</style>

<style lang="scss">
      .input-group-for-location-select-in-wlans--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-wlans--filters .select__list-container {
    width: 400px;
  }
  .infoMessageForWPA3-enter-active,
.infoMessageForWPA3-leave-active {
  transition: opacity 0.3s;
}

.infoMessageForWPA3-enter,
.infoMessageForWPA3-leave-to {
  opacity: 0;
}
.info-tooltip-in-wlans-wmm {
  margin-left: 0.2em;
  margin-right: 0.2em;
}
.loader-backdrop-wlans:after {
  position: absolute;
  top: 30px;
}
</style>

<style scoped>
.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}

  .custom-tabs-wrapper-in-wlans .nav.nav-tabs.row{
    border-radius: 0px 0px 0px 0px !important;
  }
  .tab-has-error{
    border: 0.5px solid #f86c6b !important;
  }
.edit-header-buttons-in-wlans {
  width: 100% !important;
}
.subswitchers-warpper-in-mobility-part {
  margin-left: 1em !important;
}
.cpe-multiselect-warpper-in-mobility-part {
  margin-left: 4em !important;
}
  .pagination-limit-selector{
    max-height: 1.2rem !important;
    font-size: 0.8rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
</style>
