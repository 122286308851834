import { BRANDING, BRANDS } from '@/config';
import { LocaleMaster } from '@/utils';

export default {
  transformArrayToObject(targetArray) {
    const objectFromArray = {};
    if (!targetArray) {
      return {};
    }

    targetArray.forEach((item) => {
      objectFromArray[item.id] = {};
      const pureItem = {};

      Object.assign(pureItem, item);
      delete pureItem.id;
      objectFromArray[item.id] = pureItem;
    });

    return objectFromArray;
  },
  transformArrayToObjectByKey(targetArray, key) {
    const objectFromArray = {};
    if (!targetArray) {
      return {};
    }

    targetArray.forEach((item) => {
      objectFromArray[item[key]] = {};
      const pureItem = {};

      Object.assign(pureItem, item);
      objectFromArray[item[key]] = pureItem;
    });

    return objectFromArray;
  },
  transformObjectToArrayByKey(targetObject, key) {
    const arrayFromObject = [];
    const keys = Object.keys(targetObject);

    keys.forEach((key) => {
      const newObj = { ...targetObject[key] };
      newObj[key] = key;
      arrayFromObject.push(newObj);
    });

    return arrayFromObject;
  },
  transformObjectToArray(targetObject) {
    const arrayFromObject = [];
    const keys = Object.keys(targetObject);

    keys.forEach((key) => {
      const newObj = { ...targetObject[key] };
      newObj.id = key;
      arrayFromObject.push(newObj);
    });

    return arrayFromObject;
  },
  combineIdsArrayFromObjectsArray(objectsArray) {
    if (objectsArray) {
      return objectsArray.map((obj) => obj.id);
    }
  },
  combineMacArrayFromObjectsArray(objectsArray) {
    if (objectsArray) {
      return objectsArray.map((obj) => obj.mac);
    }
  },
  combineModelIdsArrayFromObjectsArray(objectsArray) {
    if (objectsArray) {
      return objectsArray.map((obj) => obj.model_id);
    }
  },
  combineOperationIdsArrayFromObjects(objectsArray) {
    let resultsArray = [];
    if (objectsArray.length) {
      objectsArray.forEach((obj) => {
        resultsArray = resultsArray.concat(obj.model_ids);
      });
    }

    return resultsArray;
  },
  combineCpeIdsArrayFromObjectsArray(objectsArray) {
    if (objectsArray) {
      return objectsArray.map((obj) => obj.cpe_id);
    }
  },
  combineObjectsArrayFromIdsArray(arrayOfIds, sourceList) {
    const arrayOfObjects = [];

    if (arrayOfIds) {
      arrayOfIds.forEach((id) => {
        sourceList.forEach((sourceObject) => {
          if (sourceObject.id === id) {
            arrayOfObjects.push(sourceObject);
          }
        });
      });
    }

    return arrayOfObjects;
  },
  createEmptyObjectsFromIdsArray(arrayOfIds) {
    let objectsIds;
    arrayOfIds.forEach((id) => {
      objectsIds[id] = {};
    });

    return objectsIds;
  },

  getFormatMac(mac) {
    if (mac.length === 12) {
      return mac
        .replace(/(.{2})/g, '$1:')
        .slice(0, -1)
        .toUpperCase();
    }
    if (mac) {
      return mac.toUpperCase();
    }
    return '';
  },
  formatMACCutColon(mac) {
    const macCutColon = mac.replace(/:/g, ''); // str = 'asd';
    return macCutColon.toUpperCase();
  },

  compareModel(a, b) {
    const modelA = a.model.toLowerCase().replace('model:', '');
    const modelB = b.model.toLowerCase().replace('model:', '');
    if (modelA > modelB) {
      return 1;
    }
    if (modelA < modelB) {
      return -1;
    }
  },

  compareDbm(a, b) {
    return a.dbm - b.dbm;
  },

  /**
   * Removes ID from passed object
   * Returns a copy
   * @param {object} object
   */
  removeID(object) {
    const copy = { ...object };
    delete copy.id;
    return copy;
  },

  /**
   * Used to track any user changes to a form data
   * To be paired with a watcher on object with data
   * @class
   */
  ChangesWatcher: class {
    /**
     * @param {object} [props]
     * @param {boolean} [props.onFirstChange]
     * If true, _isEdited is set to true on first edit; if false - on second edit.
     * Default is false, since watcher is usually triggered once when data is
     * first loaded (overwritten) to data object (i.e. from null to empty object)
     */
    constructor(props = {}) {
      this._count = 0;
      this._isEdited = false;
      this._onFirstChange = props.onFirstChange;
    }

    _setAsEdited() {
      this._isEdited = true;
      this._count = 0;
    }

    tick() {
      if (this._onFirstChange) {
        this._setAsEdited();
      } else if (this._count > 0) {
        this._setAsEdited();
      }
      this._count += 1;
    }

    reset() {
      this._count = 0;
      this._isEdited = false;
    }

    get isChanged() {
      return this._isEdited;
    }
  },

  /**
   * Coerces value to integer and checks for minimum value
   * @param value
   * @param [min]
   * @return {number}
   */
  validatePagination(value, min = 0) {
    const index = parseInt(value, 10) || 0;
    if (index <= min) {
      return min;
    }
    return index;
  },

  /**
   * Get a timestamp formatted as a 'dd-mm-yyyy' string
   * @return {string}
   */
  getTimestamp() {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth()}`.padStart(2, '0');
    const day = `${date.getDay()}`.padStart(2, '0');

    return `${year}_${month}_${day}`;
  },

  /**
   * Capitalize first letter of a String
   * @param string
   * @return {string}
   */
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  /**
   * Fixes scroll problem for when you have two modals opened and close only one
   * of them.
   * Added since any modal close removes .modal-open from body, which the other
   * main modal from scrolling.
   */
  handleSecondModalHiding() {
    setTimeout(() => {
      document.body.classList.add('modal-open');
    }, 200);
  },

  /**
   * A dirty fix for a dirty problem.
   * Cancels effects of the previous fix. Useful for cases when you close both modals
   * and one of them adds timeout adding .modal-open on body.
   */
  handleBothModalsHiding() {
    setTimeout(() => {
      document.body.classList.remove('modal-open');
    }, 400);
  },

  /**
   * Converts file to a base64 string
   * @param file
   * @return {Promise}
   */
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  clearCustomLocationCss() {
    if (document.getElementById('customCssVarsForUserLocation') !== null) {
      const customCssVarsForUserLocation = document.getElementById('customCssVarsForUserLocation');
      // console.log(customCssForLocation);
      customCssVarsForUserLocation.parentNode.removeChild(customCssVarsForUserLocation);
      if (document.getElementById('customStylesForLocationRewriteCSS') !== null) {
        const customStylesForLocationRewriteCSS = document.getElementById('customStylesForLocationRewriteCSS');
        // console.log(customCssForLocation);
        customStylesForLocationRewriteCSS.parentNode.removeChild(customStylesForLocationRewriteCSS);
      }
    }
  },
  setBirthdaysCalendarLocale() {
    window.VueCalendarLang = null;
    window.VueCalendarLang = function (lang) {
      if(LocaleMaster.isCachedLocaleRU()) {
        return {
          daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          limit: 'Limit reached ({{limit}} items max).',
          loading: 'Loading...',
          minLength: 'Min. Length',
          months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь'
          ],
          notSelected: 'Nothing Selected',
          required: 'Required',
          search: 'Search'
        };
      }
      return {
        daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        limit: 'Limit reached ({{limit}} items max).',
        loading: 'Loading...',
        minLength: 'Min. Length',
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        notSelected: 'Nothing Selected',
        required: 'Required',
        search: 'Search'
      };
    };
  },
  getContactEmail() {
    try {
      if (BRANDING === BRANDS.beeline) {
        return 'customercare@beeline.ru';
      }
      return 'info@wimark.com';
    } catch (e) {
      return 'info@wimark.com';
    }
  },
  sortLocationsList(locationsList) {
    try {
      const locationsForSort = JSON.parse(JSON.stringify(locationsList));
      const result = locationsForSort.sort((curr, prev) => {
        if (curr.name < prev.name) {
          return -1;
        }
        if (curr.name > prev.name) {
          return 1;
        }
        return 0;
      });
      return result;
    } catch (e) {
      // console.log(e);
      return JSON.parse(JSON.stringify(locationsList));
    }
  },
  isTimeoutError(errorDescription) {
    try {
      // ошибки таймаута в дашборде имеют description вида "read tcp 10.0.2.90:39896->10.0.2.40:27017: i/o timeout"
      const errorDescriptionAsWordArray = errorDescription.split(' ');
      for (const originWord of errorDescriptionAsWordArray) {
        // на всякий случай приводим слово к маленьким буквам,
        // вдруг текст ошибки придет немного другим
        const word = originWord.toLowerCase();
        if (word === 'timeout') {
          return true;
        }
      }
      return false;
    } catch (e) {
      // console.log(e);
      return false;
    }
  },
  sortCompanysList(list) {
    try {
      const listForSort = JSON.parse(JSON.stringify(list));
      const result = listForSort.sort((curr, prev) => {
        if (curr.name < prev.name) {
          return -1;
        }
        if (curr.name > prev.name) {
          return 1;
        }
        return 0;
      });
      return result;
    } catch (e) {
      // console.log(e);
      return JSON.parse(JSON.stringify(list));
    }
  },
  sortUsersList(list) {
    try {
      // console.log(list)
      const listForSort = JSON.parse(JSON.stringify(list));
      const result = listForSort.sort((curr, prev) => {
        if (curr.username.toLowerCase() < prev.username.toLowerCase()) {
          return -1;
        }
        if (curr.username.toLowerCase() > prev.username.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      return result;
    } catch (e) {
      // console.log(e);
      return JSON.parse(JSON.stringify(list));
    }
  },
  addCompanyNamesToLocationNames(locationsList) {
    // проходимся по списку локаций и добавляем к названию локации название компании, если оно присутсвует
    try {
      if (locationsList === false) {
        return [];
      }
      const resultLocationsList = JSON.parse(JSON.stringify(locationsList));
      for (const location of resultLocationsList) {
        if (location.hasOwnProperty('party') && location.party.hasOwnProperty('name') && location.party.name !== '') {
          // console.log(location)
          location.name = `${location.name} - ${location.party.name}`;
        }
      }
      return resultLocationsList;
    } catch (e) {
      console.log(e);
      return locationsList;
    }
  },
  componentsWithCPEListLoadingList() {
    // сейчас не используется, нашлось более изящное решение

    // список нужен для задачки WNE-2306
    // на хуке beforeDestroy компонента точек (Aps.vue)
    // висит запрос на загрузку точек (с лимитом 30) из /api/cpes
    // Во всех компонентах ниже есть запрос на загрузку компактного списка точек из api/cpes/compact (или не коммпактного из /api/cpes)
    // Оба запроса потом используют одно и тоже место для хранения списка точек - $store.state.cpesList
    // И иногда случается что после перехода из Точек в например Портальные профили запрос в /api/cpes,
    // который выполяентся после выхода из Точек выполняется долше чем запрос в api/cpes/compact, который
    // выполняется при входе в Портальные профили. И перезатиравет результаты. Таким образом вместо компактного списка
    // из api/cpes/compact в списке точек оказывается всего 30 из /api/cpes что ломает всю логику.
    // А в других компонентах типа RRM запрос тоже может идти в  /api/cpes, но с соотвествущими филльтрами. Возникает такая же проблема гонки запросов.
    // Теперь в компоненте CPE (Aps.vue) стоит проверка c с помощью функции makeGetCpesRequestAfterCpesComponentDestroy:
    // если переход на компоенент из этого списка- не выполнять запрос в /api/cpes при закрытии компонента
    return [
      'PortalProfilesNew',
      'Dashboard',
      'maps',
      'Marketing',
      'Radar',
      'Events',
      'Rules',
      'Sessions',
      'PortalSessions',
      'Statexport',
      'UsersDemoWizardStep4',
      'EasyInstallWizardstep5',
      'EasyInstallWizardstep3',
      'Statsystem',
      'GuestControl',
      'System',
      'RRM',
      'WifiAnalyzer',
      'ClientDistance',
      'SNMPMonitoringConfig',
      'Login'
    ];
  },
  createColumnWidthsForPdfFromDownloadExtractV2InputData(data) {
    try {
      if (data === undefined || data === null) {
        return undefined;
      }
      if (Array.isArray(data) && data.length &&
        typeof data[0] === 'object' && data[0] !== null &&
        data[0]) {
        let result = [];
        const columnsCount = Object.keys(data[0]).length;
        // const oneColumnWidth = Math.floor(100 / columnsCount);
        const oneColumnWidth = (100 / columnsCount).toFixed(1);
        result = [...Array(columnsCount)].map(() => `${oneColumnWidth}%`);
        // console.log('columnsCount', columnsCount);
        // console.log('oneColumnWidth', oneColumnWidth);
        // console.log('result', result);
        return result;
      }
      return undefined;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  },
  makeGetCpesRequestAfterCpesComponentDestroy(routeName) {
    // сейчас не используется, нашлось более изящное решение
    try {
      if (this.componentsWithCPEListLoadingList().includes(routeName)) {
        return false;
      }
      return true;
    } catch (e) {
      return true;
    }
  },
  convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, inputNameForExcelSheet) {
    // используется для конвертации из входного формата для старого модуля создания xls документов
    // в входной формат нового модуля для создания xls документов
    let nameForExcelSheet = 'Sheet';
    if (inputNameForExcelSheet !== '' && inputNameForExcelSheet !== undefined && inputNameForExcelSheet !== null) {
      nameForExcelSheet = inputNameForExcelSheet;
      // боремся с ошибкой вида "Error: Sheet name cannot contain : \ / ? * [ ]"
      nameForExcelSheet = nameForExcelSheet.replace('\\', ' ');
      nameForExcelSheet = nameForExcelSheet.replace('/', ' ');
      nameForExcelSheet = nameForExcelSheet.replace('?', ' ');
      nameForExcelSheet = nameForExcelSheet.replace('*', ' ');
      nameForExcelSheet = nameForExcelSheet.replace('[', ' ');
      nameForExcelSheet = nameForExcelSheet.replace(']', ' ');
      // боремся с ошибкой вида Sheet names cannot exceed 31 chars
      if (nameForExcelSheet.length > 30){
        nameForExcelSheet = nameForExcelSheet.slice(0,30);
      }
    }
    const result = {};
    result[nameForExcelSheet] = [];
    try {
      const dataForOldExcelModule = JSON.parse(JSON.stringify(inputDataForOldExcelModule));
      if (dataForOldExcelModule.length === 0) {
        return result;
      }
      // вытаскиваем имена колонок таблиы
      const columnNames = Object.keys(dataForOldExcelModule[0]);
      // эта штука требует на вход построчно сформированные массивы, то есть
      // [['имя колонки 1', 'имя колонки 2'],['значение колонки 1', 'значение колонки 2'],['значение колонки 1', 'значение колонки 2']...]
      // вставляем в итоговый набор массив с именами колонок
      result[nameForExcelSheet].push(columnNames);
      for (const dataItem of dataForOldExcelModule) {
        // а теперь вставляем массивы со значениями колонок
        const tmpArrForItem = [];
        for (const key of columnNames) {
          tmpArrForItem.push(dataItem[key]);
        }
        result[nameForExcelSheet].push(tmpArrForItem);
      }
      // console.log(result);
      return result;
    } catch (e) {
      console.log(e);
      return result;
    }
  },
  getFileNameForDownloadFiles(pageName) {
    // используется для создания имен для скачиваемых файлов, удаляет из имени пробелы и добавляет
    // к имени дату и время
    try {
     // console.log(pageName)
      const nowDate = new Date();
      const year = nowDate.getFullYear();
      let month = nowDate.getMonth();
      let date = nowDate.getDate();
      let hours = nowDate.getHours();
      let minutes = nowDate.getMinutes();
      // приводолм к в виду с ведущим нулем, если необходимо. Иначе будет 5 минут, вместо 05 минут
      month = ("0" + (month + 1)).slice(-2);
      date = ("0" + date).slice(-2);
      hours = ("0" + hours).slice(-2);
      minutes = ("0" + minutes).slice(-2);
      // заменяем пробелы из имени файла, если найдутся
      const pageNameCleanedSpaces = pageName.replace(' ', '_').replace(/ /g,"_");
      // console.log(pageNameCleanedSpaces)
      return `${pageNameCleanedSpaces}-${year}-${month}-${date}_${hours}_${minutes}`;
    } catch (e) {
      console.log(e);
      return pageName;
    }
  },

  createInterfacesWithDetailsAndNameWithTypeFromAllHostsList(ctx, hosts) {
    // используется для создания списка интерфейсов c nameWithType из масиива хостов с интерфейсами
    try {
      const result = {}
      for (const host of hosts) {
        if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
          Array.isArray(host.interfaces_details) &&
          host.interfaces_details.length > 0) {
          for (const interfaceWithDetails of host.interfaces_details) {
            if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
              interfaceWithDetails.name !== '' &&
              !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
              interfaceWithDetails.nameWithType = `${interfaceWithDetails.name} (${ctx.$t('general.type').toLowerCase()}  ${interfaceWithDetails.type})`
              result[interfaceWithDetails.name] = interfaceWithDetails;
            }
          }
        }
      }
      return result;
    } catch (e) {
      console.log(e)
      return {}
    }
  },

  createInterfacesWithDetailsFromAllHostsList(hosts) {
    // используется для создания списка интерфейсов из масиива хостов с интерфейсами
    try {
      const result = {}
      for (const host of hosts) {
        if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
          Array.isArray(host.interfaces_details) &&
          host.interfaces_details.length > 0) {
          for (const interfaceWithDetails of host.interfaces_details) {
            if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
              interfaceWithDetails.name !== '' &&
              !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
              result[interfaceWithDetails.name] = interfaceWithDetails;
            }
          }
        }
      }
      return result;
    } catch (e) {
      console.log(e)
      return {}
    }
  },
  scrollTopToZero() {
    const elementWithScrollBar = document.getElementById('container-fluid');
    if (elementWithScrollBar) {
      elementWithScrollBar.scrollTop = 0;
    }
  },
  /**
   * Возвращает строку делая первую букву заглавной
   *
   * @param {string} string
   * @return {string}
   * */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  cutWordInAllCasesFromString(inputString, wordForCut) {
    try {
      let result = inputString
      const lowerCaseWordForCut = wordForCut.toLowerCase();
      const upperCaseWordForCut = wordForCut.toUpperCase();
      const firstLetterUpperCaseWordForCut = this.capitalizeFirstLetter(lowerCaseWordForCut);
      for (const wordForCutVariant of [lowerCaseWordForCut, upperCaseWordForCut, firstLetterUpperCaseWordForCut]) {
        result = result.replace(wordForCutVariant, '')
      }
      return result;
    } catch (e) {
      console.log(e);
      return inputString;

    }
  },
  cutUnwantedWordsFromAddressStringFromOpenstreetmapResponse(nameFromResponse) {
    try {
      // удаляем из результата "Украина", чтобы при выдаче оно не отображалось
      // защита от "неудобного" вывода при поиске по новым территориям
      let nameForDisplay = nameFromResponse;
      for (const unwantedWord of ['Украина', 'Ukraine']) {
        nameForDisplay = this.cutWordInAllCasesFromString(nameForDisplay, unwantedWord)
      }
      nameForDisplay = nameForDisplay.trim();
      // отразем последнюю запятую, если осталась
      if (nameForDisplay[nameForDisplay.length - 1] === ',') {
        nameForDisplay = nameForDisplay.slice(0, nameForDisplay.length - 1);
      }
      return nameForDisplay;
    } catch (e) {
      console.log(e);
      return nameFromResponse;
    }
  }


};
